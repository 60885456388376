import React, { useEffect } from "react";
import LayoutNavbar from "./LayoutNavbar";
import LayoutSidenav from "./LayoutSidenav";
import layoutHelpers from "./helpers";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { ProgressBar } from "react-bootstrap";
import "assets/scss/policies.scss";

const Layout1 = (props) => {
  const closeSidenav = (e) => {
    e.preventDefault();
    layoutHelpers.setCollapsed(true);
  }

  useEffect(() => {
    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);
    layoutHelpers.setCollapsed(false);

    return () => { layoutHelpers.destroy(); }
  }, [] );

  const getDisplayMessage = () => {
    if (props.publishingAction === "Publish") {
      return "GENERAL.PUBLISHING_CHANGES";
    }
    if (props.publishingAction === "VerifyPolicy") {
      return "GENERAL.VERIFYING_CHANGES";
    }
    if (props.publishingAction === "Install") {
      return "GENERAL.INSTALLING_CHANGES";
    }
    if (props.publishingAction === "Batch") {
      return "GENERAL.BATCH_PROCESSING";
    }
    if (props.publishingAction === "Revert") {
      return "GENERAL.REVERT_CHANGES";
    }
    return "GENERAL.DISCARDING_CHANGES";
  }

  return (
    <div className="layout-wrapper layout-1">
      <div className="layout-inner">
        {props.isPublishing && (
          <div className="d-flex justify-content-center">
            <div
              className="d-table vh-100 vw-100 position-fixed"
              style={{ zIndex: 99999, backdropFilter: "blur(8px)" }}
            >
              <div className="d-table-cell align-middle select-device-card-container text-center">
                <div className="loader-wrapper">
                  <strong className="blur-text text-center text-xlarge">
                    <FormattedMessage id={getDisplayMessage()} />
                  </strong>
                  {(props.publishingAction === "Install" ||
                    props.publishingAction === "VerifyPolicy") && (
                    <ProgressBar
                      className="mx-auto mt-5 policy-progress-bar"
                      now={props.installationProgress || 0.5}
                      striped
                      animated
                      variant="success"
                      label={`${props.installationProgress || " "}%`}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <LayoutNavbar {...props} />

        <div className="layout-container">
          <LayoutSidenav {...props} />

          <div className="layout-content">
            <div className="container-fluid flex-grow-1 container-p-y">
              {props.children}
            </div>
          </div>
        </div>
      </div>
      <div className="layout-overlay" onClick={closeSidenav}></div>
    </div>
  );
};

export default connect(
  (store) => ({
    isPublishing: store.policy.isPublishing,
    publishingAction: store.policy.publishingAction,
    installationProgress: store.policy.installationProgress,
  }),
  null
)(Layout1);
