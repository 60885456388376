import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TableLoader from "components/common/TableLoader";
import { ListGroup } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import { objectDatas, isUserReadOnly } from "utils/utils";
import Pagination from "components/common/Pagination";
import BeSafeButton from "../../common/BeSafeButton";
import "assets/scss/policies.scss";

const ObjectExplorerItemList = (props) => {
  const intl = useIntl();

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
    }),
    shallowEqual
  );

  const isButtonShown = (item) => {
    if (isReadOnlyUser) {
      return false;
    }
    if (props.itemType.includes("service")) {
      return item.creator !== "System"
    }

    if (props.itemType.includes("application-site")) {
      return item.creator !== "System"
    }
    return !item.hideImplementation;
  };

  const getNetworkType = (item) => {
    if (item["nat-settings"]?.["hide-behind"] === "gateway") {
      return `${
        ", " +
        intl.formatMessage({ id: "NETWORK_GROUPS.TYPE" }) +
        ": LAN"
      }`;
    }

    return `${
      ", " +
      intl.formatMessage({ id: "NETWORK_GROUPS.TYPE" }) +
      ": No-LAN"
    }`;
  };

  const getNetworkGroupType = (item) => {
    const groupType = item["comments"] ? item["comments"].split(";")[0] : item["comments"];
    if (groupType === "LanGroup") {
      return `${
        ", " +
        intl.formatMessage({ id: "NETWORK_GROUPS.TYPE" }) +
        ": LAN"
      }`;
    }

    if (groupType === "NoLanGroup") {
      return `${
        ", " +
        intl.formatMessage({ id: "NETWORK_GROUPS.TYPE" }) +
        ": No-LAN"
      }`;
    }

    return `${
      ", " +
      intl.formatMessage({ id: "NETWORK_GROUPS.TYPE" }) +
      ": " + groupType
    }`;
  };

  return (
    <div className="flex-grow-1 overflow-auto">
      <TableLoader visible={props.loading} /> 
      {props.itemsCount <= 0 && !props.loading? (
        <span className="d-flex justify-content-center m-2 mt-4">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      ) : (
        <div className="d-flex flex-column h-100">
          <ListGroup className="flex-grow-1 overflow-auto">
            {props.items.map((item) => (
              <ListGroup.Item
                className={`rounded-0 d-flex justify-content-between ${item.uid === props.lastAdded ? "highlighted" : ""}`}
                active={item.uid === props.selectedItem.uid}
                key={item.uid}
                action
                onClick={() => props.selectItem(item)}
              >
                <div>
                  <i className={`${objectDatas[props.itemType].icon} mr-2`}></i>
                  {item.name}
                  {props.itemType === "application-site-category" && item.name === "Very Low Risk" ? <div className="badge badge-success ml-2">1</div> : null}
                  {props.itemType === "application-site-category" && item.name === "Low Risk" ? <div className="badge badge-warning ml-2">2</div> : null}
                  {props.itemType === "application-site-category" && item.name === "Medium Risk" ? <div className="badge badge-medium ml-2">3</div> : null}
                  {props.itemType === "application-site-category" && item.name === "High Risk" ? <div className="badge badge-danger ml-2">4</div> : null}
                  {props.itemType === "application-site-category" && item.name === "Critical Risk" ? <div className="badge badge-very-high ml-2">5</div> : null}
                  {props.itemType === "network" && getNetworkType(item)}
                  {props.itemType === "group" && getNetworkGroupType(item)}
                </div>
                <div>
                  <BeSafeButton
                    className="mr-3 p-0"
                    variant="transparent"
                    onClick={ () => props.editFunction(item) }
                    disabled={ props.buttonsDisabled }
                    icon="fas fa-pencil-alt"
                    tooltip="GENERAL.EDIT_SMALL"
                    visible={ props.editFunction !== undefined && isButtonShown(item) }
                  />

                  <BeSafeButton
                    className="p-0"
                    variant="transparent"
                    onClick={ () => props.deleteFunction(item) }
                    disabled={ props.buttonsDisabled }
                    icon="fas fa-trash"
                    tooltip="GENERAL.DELETE_SMALL"
                    visible={ isButtonShown(item)}
                  />
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <div className="mt-2 pr-3 pl-3 d-flex justify-content-center align-items-center">
            <div className="w-40">
              <span className="text-primary">
                <b>
                  <FormattedMessage id="GENERAL.TOTAL" />:{" "}
                </b>
                {props.itemsCount}
              </span>
            </div>
            <div className="w-60">
              <Pagination
                totalItems={props.itemsCount}
                handlePageChange={props.handlePageChange}
                pageNumber={props.pageNumber}
                pageRangeDisplayed={3}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ObjectExplorerItemList;