import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Col } from "react-bootstrap";
import { isValidIPSubnet,  getAccountType, accountTypes } from "utils/utils";
import axios from "axios";
import { Netmask } from "netmask";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { successToast } from "utils/ToastHelper";
import cogoToast from "cogo-toast";
import { CircularProgress } from "@material-ui/core";

const NetworkFormModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onHide = () => {
    props.onHide();
  };

  const { accountType } = useSelector(
    ({ auth }) => ({
      accountType: getAccountType(auth),
    }),
    shallowEqual
  );

  const getLanInitialValue = () => {
    if (props.initialLanValue) {
      return props.initialLanValue;
    }

    if (props?.selectedNetwork?.["nat-settings"])
      if (props?.selectedNetwork?.["nat-settings"]?.["hide-behind"] === "gateway") {
        return "lan";
      } else {
        return "noLan";
      }

    return "lan"
  };

  const isLanSwitchDisabled = () => {
    if (props.lanDisabled) {
      return true;
    }

    if (props.editMode) {
      if (props.usedList.length > 0) {
        return true;
      }
    }
    return false;
  }

  const initialValues = {
    ip_address: props.selectedNetwork?.name || "",
    original_ip_address: props.selectedNetwork?.comments || "",
    lan: getLanInitialValue()
  };

  const validate = (values) => {
    const errors = {};
    if (!isValidIPSubnet(values.ip_address, values.lan)) {
      errors.ip_address = intl.formatMessage({
        id: "NETWORK_GROUPS.INVALID_NETWORK",
      });
    }

    if ((accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) && !isValidIPSubnet(values.original_ip_address, values.lan)) {
      errors.original_ip_address = intl.formatMessage({
        id: "NETWORK_GROUPS.INVALID_NETWORK",
      });
    }

    return errors;
  };

  const onSubmit = async (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    var block = new Netmask(values.ip_address);
    if (props.editMode) {
      let nt = {
        uid: props.selectedNetwork?.uid,
        "new-name": values.ip_address,
        subnet: block.base,
        "mask-length": block.bitmask,
        comments: (accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) ? values.original_ip_address : null
      };
      axios
        .patch(`/networks/${props.selectedNetwork.uid}`, {
          network: nt,
        })
        .then((response) => {
          let val = response.data.network;
          dispatch(SetPolicyChanges(val.count_of_changes));
          props.submitHandler(val);
          successToast({
            body: "NETWORK_GROUPS.NETWORK_OBJECT_UPDATED_SUCCESS",
            intl: intl,
          });
          setSubmitting(false);
          onHide();
          formik.resetForm();
        })
        .catch((err) => {
          cogoToast.error(err.response.data?.error, {
            heading: intl.formatMessage(
              { id: "NETWORK_GROUPS.NETWORK_ERROR" },
              { network_name: nt.name }
            ),
            bar: { size: "10px" },
            position: "top-right",
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      let nt = {
        name: values.ip_address,
        subnet: block.base,
        "mask-length": block.bitmask,
        comments: (accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) ? values.original_ip_address : null
      };
      if (values.lan === "lan") {
        nt["nat-settings"] = {
          "hide-behind": "gateway",
          method: "hide",
          "auto-rule": true,
        };
      }
      axios
        .post("/networks", { network: nt })
        .then((response) => {
          let val = response.data.network;
          dispatch(SetPolicyChanges(val.count_of_changes));
          props.submitHandler(val);
          successToast({
            body: "NETWORK_GROUPS.NETWORK_OBJECT_CREATED_SUCCESS",
            intl: intl,
          });
          setSubmitting(false);
          onHide();
          formik.resetForm();
        })
        .catch((err) => {
          cogoToast.error(err.response.data?.error, {
            heading: intl.formatMessage(
              { id: "NETWORK_GROUPS.NETWORK_ERROR" },
              { network_name: nt.name }
            ),
            bar: { size: "10px" },
            position: "top-right",
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal show={props.showModal} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
        {props.editMode ? <FormattedMessage id={"NETWORK_GROUPS.EDIT_NETWORK_OBJECT"} /> : <FormattedMessage id={"NETWORK_GROUPS.ADD_NETWORK_OBJECT"} />}
        </Modal.Title>
      </Modal.Header>
      <Form noValidate={true} autoComplete="off" onSubmit={formik.handleSubmit}>
        <Modal.Body className="overflow-auto">
        { props.loading ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <CircularProgress />
            </div>
          ) : (
          <>
          <div className="pb-3">
            <span className="font-weight-bold">
              {(accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) ?  <FormattedMessage id="NETWORK_GROUPS.NATED_IP" /> : <FormattedMessage id="NETWORK_GROUPS.NETWORK_IP_ADDRESS" /> }
            </span>
            <Form.Control
              type="text"
              name="ip_address"
              className="border-secondary"
              value={formik.values.ip_address}
              onChange={formik.handleChange}
              isInvalid={formik.touched.ip_address && formik.errors.ip_address}
            />
            <Form.Control.Feedback
              type="invalid"
            >
              <FormattedMessage id={"NETWORK_GROUPS.INVALID_NETWORK"} />
            </Form.Control.Feedback>
            {(accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) && (
              <div className="mt-3">
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_GROUPS.ORIGINAL_IP" />
                </span>
                <Form.Control
                  type="text"
                  name="original_ip_address"
                  className="border-secondary"
                  value={formik.values.original_ip_address}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.original_ip_address && formik.errors.original_ip_address}
                />
                <Form.Control.Feedback
                  type="invalid"
                >
                  <FormattedMessage id={"NETWORK_GROUPS.INVALID_NETWORK"} />
                </Form.Control.Feedback>
              </div>
            )}
            <Col lg="4" className="text-center my-3">
              <div className="d-flex">
                <Form.Check
                  id="lan"
                  type="radio"
                  label="LAN"
                  name="lan"
                  className="checkbox-cursor mr-2"
                  value="lan"
                  checked={formik.values.lan === "lan"}
                  onChange={formik.handleChange}
                  disabled={isLanSwitchDisabled()}
                  custom
                />
                <Form.Check
                  id="noLan"
                  type="radio"
                  label="No LAN"
                  name="lan"
                  className="checkbox-cursor mr-2"
                  value="noLan"
                  checked={formik.values.lan === "noLan"}
                  onChange={formik.handleChange}
                  disabled={isLanSwitchDisabled()}
                  custom
                />
              </div>
            </Col>
          </div>
          </>
        )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" onClick={onHide}>
            <FormattedMessage id="OBJECT_MODAL.CANCEL" />
          </Button>
          <Button variant="primary" disabled={formik.isSubmitting || props.loading} type="submit">
            <i className="fas fa-check mr-2" />
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NetworkFormModal;
