import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
} from "@material-ui/core";
import { render } from "react-dom";
import "assets/scss/confirm_dialog.scss";
import { Button } from "react-bootstrap";
import { renderTooltip } from "utils/utils";

const Transition3 = React.forwardRef(function Transition5(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ConfirmDialog(props) {
  const [showDialog, setDialog] = useState(true);

  const okAction = () => {
    setDialog(false);
    props.okAction();
  };

  const closeAction = () => {
    setDialog(false);
    props.closeAction();
  };

  const confirmAction = () => {
    setDialog(false);
    props.confirmAction();
  };

  const fourthAction = () => {
    setDialog(false);
    props.fourthAction();
  };
  return (
    <div className="react-confirm-popup">
      <Dialog
        classes={{ root: "confirm-popup-container" }}
        open={showDialog}
        TransitionComponent={Transition3}
        keepMounted
        onClose={closeAction}
        maxWidth={props.maxWidth}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        {props.description && (
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {props.description}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          {props.cancelLabel && (
            <Button
              onClick={closeAction}
              variant="outline-danger"
              size="sm"
              className="mb-1"
            >
              {props.cancelLabel}
            </Button>
          )}
          {props.fourthLabel && (
            <Button
              onClick={fourthAction}
              variant="primary"
              size="sm"
              className="mb-1"
            >
              {props.fourthLabel}
            </Button>
          )}
          {props.confirmLabel &&
            renderTooltip(
              <Button
                onClick={confirmAction}
                variant="outline-primary"
                size="sm"
                className="mb-1"
              >
                {props.confirmLabel}
              </Button>,
              props.confirmTitle
            )}
          {renderTooltip(
            <Button
              onClick={okAction}
              variant="outline-primary"
              size="sm"
              className="mb-1 mr-2"
            >
              {props.okLabel}
            </Button>,
            props.okTitle
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmDialog.defaultProps = {
  title: "Title",
  okLabel: "Agree",
  okTitle: "",
  closeAction: () => null,
  okAction: () => null,
  confirmAction: () => null,
  fourthAction: () => null,
  maxWidth: "xs",
};

export function ConfirmationPopup(props) {
  let divTarget = document.getElementById("react-confirm-popup");
  if (!divTarget) {
    // Mount the ReactConfirmAlert component
    document.body.children[0].classList.add("react-confirm-popup-blur");
    divTarget = document.createElement("div");
    divTarget.id = "react-confirm-popup";
    document.body.appendChild(divTarget);
  }
  render(<ConfirmDialog key={new Date()} {...props} />, divTarget);
}
