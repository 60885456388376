import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionTypes = {
  SetShowBanner: "[Set Show Banner] Action",
};

const initialState = {
  showBanner: true,
};

export const reducer = persistReducer(
  { storage, key: "maintenance" },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetShowBanner:
        return { ...state, showBanner: action.payload.showBanner };

      default:
        return state;
    }
  }
);

export const SetShowBanner = (showBanner) => ({
  type: actionTypes.SetShowBanner,
  payload: { showBanner },
});
