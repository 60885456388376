import React,{ useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { successToast, errorToast } from "utils/ToastHelper";
import axios from "axios";
import { useDispatch } from "react-redux";
import { SetPolicyChanges, SetPublishingStatus, SetPublishingAction } from "store/ducks/policyChange.duck";
import BulkUserFileHelpModal from "components/object_explorer/network_users/BulkUserFileHelpModal";

const BulkUserModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showUserFileHelp, setShowUserFileHelp] = useState(false);

  

  const initialValues = {
    userCsvFile: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.userCsvFile || !values.userCsvFile.name) {
      errors.userCsvFile = intl.formatMessage({
        id: "GENERAL.REQUIRED_FIELD",
      });
    }
    return errors;
  };

  const onSubmit = async (values, { setStatus, setSubmitting }) => {
    dispatch(SetPublishingStatus(true));
    dispatch(SetPublishingAction("Batch"));
    props.onHide();
    const formData = new FormData();
    formData.append('userCsvFile', values.userCsvFile);

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
      }
    }
    axios
      .post("/network_users/batch_create", formData, config)
      .then((response) => {
        successToast({
          body: "NETWORK_USERS.USERS_BATCH_SUCCESS",
          intl: intl,
        });
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        props.submitHandler(response);
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
      })
      .finally(() => {
        dispatch(SetPublishingStatus(false));
        dispatch(SetPublishingAction(""));
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div>
      <Modal show={props.showModal} onHide={props.onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="NETWORK_USERS.UPLOAD_USERS" />
          </Modal.Title>
        </Modal.Header>
        <Form
          noValidate={true}
          autoComplete="off"
          onSubmit={(values) => {
            formik.handleSubmit(values);
            formik.setFieldTouched("userCsvFile", true);
          }}
        >
          <Modal.Body>
            <Form.Group as={Row} controlId="user-csv">
              <Form.Label column sm={3} className="pl-4">
                <FormattedMessage id="NETWORK_USERS.FILE" />
              </Form.Label>
              <Col sm={8}>
                <Form.File
                  className="border-secondary"
                  custom
                  name="userCsvFile"
                  id="validationFormik107"
                >
                  <Form.File.Input
                    as="input"
                    onChange={(e) => {
                      if (!!e.target.files)
                      formik.setFieldValue("userCsvFile", e.target.files[0]);
                    }}
                    isInvalid={formik.touched.userCsvFile && formik.errors.userCsvFile}
                    accept=".csv"
                  />
                  <Form.File.Label
                    className="border-secondary"
                    data-browse={intl.formatMessage({
                      id: "GENERAL.BROWSE",
                    })}
                  >
                    {!!formik.values.userCsvFile
                      ? formik.values.userCsvFile.name
                      : intl.formatMessage({
                          id: "NETWORK_USERS.SELECT_FILE",
                        })}
                  </Form.File.Label>
                </Form.File>
                {formik.touched.userCsvFile && formik.errors.userCsvFile && (
                  <div className="invalid-feedback d-block">
                    {formik.errors.userCsvFile}
                  </div>
                )}
              </Col>
              <Col sm={1}>
                <Button
                  className="ml-2 px-2 btn btn-outline-primary"
                  onClick={() => setShowUserFileHelp(true)}
                >
                  <i className="fas fa-info"></i>
                </Button>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
              <i className="fas fa-upload mr-2" />
              <FormattedMessage id={"NETWORK_USERS.UPLOAD"} />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <BulkUserFileHelpModal
        showModal={showUserFileHelp}
        onHide={() => setShowUserFileHelp(false)}
      />
    </div>
  );
};

export default BulkUserModal;
