import React, { useState } from 'react'
import { Card, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../../vendor/styles/pages/authentication.scss'
import { login, readOnlyLogin, checkLoggedIn } from '../../app/crud/auth.crud'
import * as auth from '../../store/ducks/auth.duck'
import { connect } from 'react-redux'
import { useFormik } from 'formik';
import { isValidEmail } from "utils/utils"
import { useIntl } from "react-intl";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import {
  SetPolicyChanges,
  SetPublishingStatus,
  SetPublishingAction,
  SetTaskID,
  SetInstallationProgress
} from "store/ducks/policyChange.duck";
import { SetShowBanner } from "store/ducks/maintenance.duck";

const Login = (props) => {
  const [rememberMe, setRememberMe] = useState(false)
  const intl = useIntl();
  const NOT_FOUND_ERROR = { response: { data: { error: "session not found" } } }

  const initialValues = {
    email: '',
    password: ''
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email || !values.email.trim()) {
      errors.email = "Required field"
    }else if (!isValidEmail(values.email)){
      errors.email = "Invalid Email Address"
    }
    if (!values.password || !values.password.trim()) {
      errors.password = "Required field"
    }
    return errors;
  };

  const resetState = (user) => {
    props.SetPolicyChanges(0);
    props.SetPublishingStatus(false);
    props.SetPublishingAction("");
    props.SetTaskID("");
    props.SetInstallationProgress(0);
    props.SetShowBanner(true)
    props.login(user)
  };

  const handleLoginError = (errorMessage) => {
    if (errorMessage === "authentication failed") {
      return "Invalid Credentials";
    }

    if (errorMessage.includes("The license on the Security Management Server has expired")) {
      return "Expired license";
    }

    return "Server error";
  };

  const performLogin = (loginFunction, mail, password, setStatus, setSubmitting) => {
    loginFunction(mail, password).then(response => {
      resetState(response.data);
    }).catch((e) => {
      setStatus(handleLoginError(e.response.data.error));
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const onSubmit = (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    checkLoggedIn(values.email, values.password).then(loggedInResponse => {
      if (!loggedInResponse.data.logged_in) throw NOT_FOUND_ERROR;

      ConfirmationPopup({
        title: intl.formatMessage({
          id: "GENERAL.WARNING",
        }),
        description: intl.formatMessage({
          id: "GENERAL.CONFIRM_LOGIN",
        }),
        okLabel: intl.formatMessage({
          id: "GENERAL.TAKE_OVER",
        }),
        confirmLabel: intl.formatMessage({
          id: "GENERAL.READ_ONLY_MODE",
        }),
        cancelLabel: intl.formatMessage({
          id: "GENERAL.CANCEL",
        }),
        okAction: () => {
          performLogin(login, values.email, values.password, setStatus, setSubmitting);
        },
        confirmAction: () => {
          performLogin(readOnlyLogin, values.email, values.password, setStatus, setSubmitting);
        },
        closeAction: () => {
          setSubmitting(false);
        }
      });
    }).catch((e) => {
      if (e.response.data.error.includes("not found")) {
        performLogin(login, values.email, values.password, setStatus, setSubmitting);
        return;
      }
      setStatus(handleLoginError(e.response.data.error));
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/login_bg.jpg')` }}>
      <div className="ui-bg-overlay bg-dark opacity-25"></div>

      <div className="authentication-inner py-5">
        <Card>
          <div className="p-4 p-sm-5">
            <div className="d-flex justify-content-center align-items-center pb-2 mb-4">
              <div className="ui-w-60">
                <div className="w-100 position-relative" style={{ paddingBottom: '54%' }}>
                  <img className="w-100 position-absolute" src={`${process.env.PUBLIC_URL}/images/besafe_logo.png`} alt="logo" />
                </div>
              </div>
            </div>
            <h5 className="text-center text-muted font-weight-normal mb-4">Login to Your Account</h5>
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              {formik.status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{formik.status}</div>
                </div>
              )}
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.email && formik.errors.email}
                  className="border-secondary"
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label className="d-flex justify-content-between align-items-end">
                  <div>Password</div>
                </Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.password && formik.errors.password}
                  className="border-secondary"
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="mb-2">
                <Link to="/forgot-password">Forgot password?</Link>
              </div>
              <div className="mb-2">
                <Link to="/reset-network-password">Reset network user password</Link>
              </div>

              <div className="d-flex justify-content-between align-items-center m-0">
                <Form.Check type="checkbox" custom checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} label="Remember me" className="m-0" id="login-remember-me" />
                <Button variant="primary" type="submit" disabled={formik.isSubmitting}>Sign In</Button>
              </div>
            </form>
          </div>
          <Card.Footer className="py-3 px-4 px-sm-5">
            <span className="font-weight-bold d-inline-block text-center w-100">v{process.env.REACT_APP_VERSION}</span>
          </Card.Footer>
        </Card>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  SetPolicyChanges,
  SetPublishingStatus,
  SetPublishingAction,
  SetTaskID,
  SetInstallationProgress,
  SetShowBanner,
  ...auth.actions
}

export default connect(null,mapDispatchToProps)(Login)
