import React, { useEffect, useState } from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { renderTooltip } from "../../utils/utils";
import axios from "axios";
import { successToast, errorToast } from "utils/ToastHelper";
import ObjectModal from "../object_explorer/ObjectModal";

const PolicyModal = (props) => {
  const [targets, setTargets] = useState([]);
  const [showObjectModal, setShowObjectModal] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (props.editMode && props.selectedPolicy["installation-targets"] !== "all")
      setTargets(props.selectedPolicy["installation-targets"]);
  }, [props.selectedPolicy, props.editMode]);

  const deleteTarget = (target) => {
    const newTargets = targets.filter((item) => item.name !== target.name);
    setTargets(newTargets);
  };

  const createPolicy = (values, setSubmitting) => {
    let params = {
      policy: {
        name: values.name,
        "installation-targets": values.allTargets === false && targets.length > 0 ? targets.map((target) => target.name) : "all",
        access: true,
        "threat-prevention": true,
        qos: true
      }
    };
    axios
      .post("/policies", params)
      .then(() => {
        publishChanges("POLICIES.CREATE_SUCCESS", setSubmitting);
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const publishChanges = (message, setSubmitting) => {
    axios
      .post(`/miscs/publish`)
      .then((response) => {
        successToast({
          body: message,
          intl: intl,
        });
        props.submitHandler();
        props.onHide();
        setSubmitting(false);
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  }

  const editPolicy = (values, setSubmitting) => {
    let params = {
      policy: {
        name: props.selectedPolicy?.name,
        "new-name": values.name,
        "installation-targets": values.allTargets === false && targets.length > 0 ? targets.map((target) => target.name) : "all",
      },
    };
    axios
      .patch(`/policies/${props.selectedPolicy.uid}`, params)
      .then((response) => {
        publishChanges("POLICIES.UPDATE_SUCCESS", setSubmitting);
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const onHide = () => {
    setTargets([]);
    props.onHide();
  };

  const initialValues = {
    name: props.editMode ? props.selectedPolicy.name : "",
    allTargets: props.editMode ? props.selectedPolicy["installation-targets"] === "all" : false
  };

  const validate = (values) => {
    const errors = {};

    return errors;
  };

  const onSubmit = (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    props.editMode
      ? editPolicy(values, setSubmitting)
      : createPolicy(values, setSubmitting);
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal show={props.showModal} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id={
              props.editMode
                ? "POLICIES.UPDATE_POLICY"
                : "POLICIES.ADD_NEW_POLICY"
            }
          />
        </Modal.Title>
      </Modal.Header>
      <Form noValidate={true} autoComplete="off" onSubmit={formik.handleSubmit}>
        <Modal.Body className="obj-cus-h-35 overflow-auto">
          <div className="pb-3">
            <span className="font-weight-bold">
              <FormattedMessage id="POLICIES.NAME" />
            </span>

            <Form.Control
              type="text"
              name="name"
              className="border-secondary"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.name}
            </Form.Control.Feedback>
          </div>
          <div className="d-flex justify-content-between font-weight-bold">
            <FormattedMessage id="POLICIES.TARGETS" />
            <div className="d-flex">
              <Form.Group className="mb-0" controlId="allCheckbox">
                <div className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    name="allTargets"
                    value="allTargets"
                    className="border-secondary checkbox-cursor mb-1"
                    checked={formik.values.allTargets === true}
                    onChange={formik.handleChange}
                    label={intl.formatMessage({ id: "GENERAL.ALL" })}
                  />
                </div>
              </Form.Group>
              <Button
                variant="transparent"
                className="py-0"
                onClick={() => setShowObjectModal(true)}
                disabled={formik.values.allTargets === true}
              >
                <i className="fas fa-plus" />
              </Button>
            </div>
          </div>

          <hr className="mb-3 mt-1 border-primary" />

          {(targets?.length > 0 && formik.values.allTargets === false) && (
            <ListGroup className="mt-3">
              {targets?.map((target, index) => (
                <ListGroup.Item
                  key={`${target.uid}`}
                  className="d-flex justify-content-between"
                >
                  <>
                    <span className="w-75">{target.name}</span>
                    <div className="my-auto">
                      {renderTooltip(
                        <Button
                          variant="transparent"
                          className="px-1 py-0"
                          onClick={() => deleteTarget(target)}
                        >
                          <i className="fas fa-trash" />
                        </Button>,
                        <FormattedMessage id="GENERAL.DELETE_SMALL" />
                      )}
                    </div>
                  </>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={formik.isSubmitting} type="submit">
            <i className="fas fa-check mr-2" />
            {props.editMode ? (
              <FormattedMessage id="GENERAL.UPDATE" />
            ) : (
              <FormattedMessage id="GENERAL.SAVE" />
            )}
          </Button>
        </Modal.Footer>
        <ObjectModal
          showModal={showObjectModal}
          onHide={() => setShowObjectModal(false)}
          allowedObjects={["simple-gateway"]}
          selectedItems={targets}
          changeItems={(value) => setTargets(value)}
        />
      </Form>

    </Modal>
  );
};

export default PolicyModal;
