import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useIntl } from "react-intl";
import { whereUsed, objectDatas } from "utils/utils";
import { useDispatch } from "react-redux";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { errorToast } from "utils/ToastHelper";
import { WaitTime } from "utils/SearchWaitTime";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";

const Categories = () => {
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedApplicationCategory, setSelectedApplicationCategory] =
    useState({ name: "" });
  const [applicationCategories, setApplicationCategories] = useState([]);
  const [usedList, setUsedList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [categoriesCount, setCategoriesCount] = useState(0);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [sort, setSort] = useState("ASC");
  const intl = useIntl();
  const dispatch = useDispatch();

  const selectApplicationCategory = useCallback(async (applicationCategory) => {
    setSelectedApplicationCategory(applicationCategory);
    setPreviewLoading(true);
    let val = await whereUsed(applicationCategory.uid);
    setUsedList(val);
    setPreviewLoading(false);
  }, []);

  const fetchApplicationCategories = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/application_categories", {
        params: {
          search_query: searchQuery,
          page_num: pageNumber,
          sort: sort,
        },
      })
      .then((response) => {
        setApplicationCategories(response.data.applications);
        setCategoriesCount(response.data.applications_count);
        setLoading(false);
        if (response.data.applications.length > 0) {
          selectApplicationCategory(response.data.applications[0]);
        } else {
          setPreviewLoading(false);
        }
        dispatch(SetPolicyChanges(response.data.count_of_changes));
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "APPLICATIONS_CATEGORIES.LOAD_ERROR", intl: intl });
      });
  }, [
    searchQuery,
    pageNumber,
    sort,
    dispatch,
    selectApplicationCategory,
    intl,
  ]);

  useEffect(() => {
    fetchApplicationCategories();
  }, [fetchApplicationCategories]);

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const getHeader = () => {
    return (
      <span className="my-auto">
        <i
          className={`${objectDatas["application-site-category"].icon} mr-2`}
        ></i>
        {selectedApplicationCategory.name}
      </span>
    )
  }

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={categoriesCount}
          items={applicationCategories}
          selectedItem={selectedApplicationCategory}
          selectItem={selectApplicationCategory}
          itemType="application-site-category"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
        />
      </ObjectExplorerItemsPane>
      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={categoriesCount}
        usedList={usedList}
        header={getHeader()}
      >
        <div className="d-flex justify-content-between">
          <span>{selectedApplicationCategory.description}</span>
        </div>
      </ObjectExplorerDetails>
    </ObjectExplorerTab>
  );
};

export default Categories;
