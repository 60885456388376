import React from 'react'
import { Card, Form, Button } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import '../../vendor/styles/pages/authentication.scss'
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";
import { useFormik } from 'formik';
import { isValidEmail } from "utils/utils"
import { successToast } from "utils/ToastHelper";

const ForgotPassword = (props) => {
  const location = useLocation();
  const resetUrl = location.pathname === '/reset-network-password' ? "/network_users/mail_reset_password" : "/users/reset_password";
  const headerText = location.pathname === '/reset-network-password' ? "NETWORK_USERS.RESET_PASSWORD" : "USERS.RESET_PASSWORD" ;
  const intl = useIntl();

  const initialValues = {
    email: ''
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email || !values.email.trim()) {
      errors.email = "Required field"
    }else if (!isValidEmail(values.email)){
      errors.email = "Invalid Email Address"
    }
    return errors;
  };

  const onSubmit = (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    setStatus("");
    axios.post(resetUrl, { email: values.email }).then(response => {
      setSubmitting(false);
      successToast({
        body: "USERS.RESET_PASSWORD_MAIL_SUCCESS",
        intl: intl,
      });
      props.history.push('/login');
    }).catch((error) => {
      setSubmitting(false);
      setStatus(error.response.data?.error);
    });

  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/login_bg.jpg')` }}>
      <div className="ui-bg-overlay bg-dark opacity-25"></div>

      <div className="authentication-inner py-5">
        <Card>
          <div className="p-4 p-sm-5">
            {/* Logo */}
            <div className="d-flex justify-content-center align-items-center pb-2 mb-4">
              <div className="ui-w-60">
                <div className="w-100 position-relative" style={{ paddingBottom: '54%' }}>
                  <img className="w-100 position-absolute" src={`${process.env.PUBLIC_URL}/images/besafe_logo.png`} alt="logo" />
                </div>
              </div>
            </div>
            {/* / Logo */}
            <h5 className="text-center text-muted font-weight-normal mb-4"><FormattedMessage id={headerText} /></h5>
            <form
              noValidate={true}
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              {formik.status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{formik.status}</div>
                </div>
              )}
              <Form.Group>
                <Form.Label>Email</Form.Label>
                {/* <Form.Control value={email} onChange={e => setEmail(e.target.value)} /> */}
                <Form.Control
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.email && formik.errors.email}
                  className="border-secondary"
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="d-flex justify-content-between align-items-center m-0">
                <Link to="/login">Cancel</Link>
                <Button variant="primary" type="submit" disabled={formik.isSubmitting}>Reset Password</Button>
              </div>
            </form>
          </div>
          <Card.Footer className="py-3 px-4 px-sm-5">
            <span className="font-weight-bold d-inline-block text-center w-100">v{process.env.REACT_APP_VERSION}</span>
          </Card.Footer>
        </Card>
      </div>
    </div>
  )
}

export default ForgotPassword
