import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  withRouter,
} from "react-router-dom";
import Login from "../components/pages/Login";
import Logout from "../components/pages/Logout";
import Register from "../components/pages/Register";
import PasswordEdit from "../components/pages/PasswordEdit";
import ForgotPassword from "../components/pages/ForgotPassword";
import {
  DefaultLayout,
  defaultRoute,
  adminDefaultRoute,
  routes,
  titleTemplate,
} from "../routes";
import { cancelRequest } from "../utils/utils";
import NotFound from "./NotFound";

const Router = (props) => {
  const location = useLocation();
  const [currentDefaultRoute, setCurrentDefaultRoute] = useState(defaultRoute);
  const { isAuthorized, isSuperUser, isImpersonated } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken !== null,
      isSuperUser: auth.user !== null ? auth.user.is_admin : false,
      isImpersonated: auth.impersonated
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!isAuthorized && location.pathname !== "/register" && location.pathname !== "/forgot-password" && location.pathname !== "/password-edit" && location.pathname !== "/reset-network-password") {
      props.history.push("/login");
    }
    return () => {
      cancelRequest();
    };
  }, [isAuthorized, props.history, location.pathname]);

  useEffect(() => {
    const removeLoadingClass = () => {
      document.documentElement.classList.remove("app-loading");
    };

    // Remove splash screen
    const splashScreen = document.querySelector(".app-splash-screen");
    if (splashScreen) {
      splashScreen.style.opacity = 0;
      setTimeout(() => {
        if (splashScreen && splashScreen.parentNode) {
          splashScreen.parentNode.removeChild(splashScreen);
        }
        removeLoadingClass();
      }, 300);
    } else {
      removeLoadingClass();
    }
  }, []);

  useEffect(() => {
    setCurrentDefaultRoute(isSuperUser ? adminDefaultRoute : defaultRoute);
  }, [isSuperUser]);

  const routers = routes.map((route) => {
    route.layout = route.layout || DefaultLayout;
    route.exact = typeof route.exact === "undefined" ? true : route.exact;
    return route;
  });
  const setTitle = (title) => {
    document.title = titleTemplate.replace("%s", title);
  };

  const scrollTop = (
    to,
    duration,
    element = document.scrollingElement || document.documentElement
  ) => {
    if (element.scrollTop === to) return;
    const start = element.scrollTop;
    const change = to - start;
    const startDate = +new Date();

    if (!duration) {
      element.scrollTop = to;
      return;
    }

    // t = current time; b = start value; c = change in value; d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      const currentDate = +new Date();
      const currentTime = currentDate - startDate;
      element.scrollTop = parseInt(
        easeInOutQuad(currentTime, start, change, duration)
      );
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };

    animateScroll();
  };

  return (
    <Switch>
      {(!isAuthorized || location.pathname === "/password-edit") ? (
        <>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/password-edit" component={PasswordEdit} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-network-password" component={ForgotPassword} />
        </>
      ) : (
        routers.map((route) => {
          if (
            (!route.superUserPage && !isSuperUser) ||
            (route.superUserPage && isSuperUser)
          ) {
            return (
              <Route
                path={route.path}
                exact={route.exact}
                render={(props) => {
                  // On small screens collapse sidenav
                  if (
                    window.layoutHelpers &&
                    window.layoutHelpers.isSmallScreen()
                  ) {
                    window.layoutHelpers.setCollapsed(true, false);
                  }

                  // Scroll page to top on route render
                  scrollTop(0, 0);

                  // Return layout
                  return (
                    <route.layout {...props}>
                      <route.component
                        {...props}
                        setTitle={setTitle}
                        scrollTop={scrollTop}
                      />
                    </route.layout>
                  );
                }}
                key={route.path}
              />
            );
          }
          return null;
        })
      )}
      <Route path="/logout" component={Logout} />
      {isAuthorized && isImpersonated ? (
        <Redirect to="/" exact={true} />
      ) : null }
      {isAuthorized && location.pathname === "/login" ? (
        <Redirect to="/" exact={true} />
      ) : null}
      {currentDefaultRoute !== "/" && (
        <Redirect from="/" to={currentDefaultRoute} exact={true} />
      )}

      <Route path="/api-docs/" />
      {/* NotFound page */}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default withRouter(Router);
