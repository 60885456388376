import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, InputGroup, ListGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { renderTooltip } from "../../../utils/utils";
import axios from "axios";
import { useDispatch } from "react-redux";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { successToast, errorToast } from "utils/ToastHelper";
import RegexSyntaxModal from "./RegexSyntaxModal";
import { CircularProgress } from "@material-ui/core";

const PatternFormModal = (props) => {
  const [regexList, setRegexList] = useState([]);
  const [selectedRegex, setSelectedRegex] = useState({});
  const regexNameFocus = useRef(null);
  const [newRegexName, setRegexName] = useState("");
  const [showSyntaxModal, setShowSyntaxModal] = useState(false);

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    props.editMode ? setRegexList(props.selectedPattern.patterns) : setRegexList([]);
  }, [props.selectedPattern, props.editMode]);

  useEffect(() => {
    if (selectedRegex && regexNameFocus.current) {
      regexNameFocus.current.focus();
    }
  }, [selectedRegex]);

  const addRegex = (value, setFieldValue) => {
    setRegexList((prevList) => [...(prevList ? prevList : []), value]);
    setFieldValue("regex", "");
  };

  const editRegex = (rgx, index) => {
    const newRegexList = regexList.map((r, i) => {
      if (r === selectedRegex) return rgx;
      return r;
    });
    setRegexList(newRegexList);
  };

  const deleteRegex = (index, rgx) => {
    const newRegexList = regexList.filter((r) => r !== rgx);
    setRegexList(newRegexList);
  };

  const createPattern = (values, setSubmitting) => {
    let params = {
      pattern: {
        name: values.name,
        patterns: regexList,
      },
    };
    axios
      .post("/patterns/", params)
      .then((response) => {
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        successToast({
          body: "PATTERNS.PATTERN_CREATE_SUCCESS",
          intl: intl,
        });
        props.submitHandler(response.data.pattern);
        props.onHide();
        setSubmitting(false);
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const editPattern = (values, setSubmitting) => {
    let params = {
      pattern: {
        uid: props.selectedPattern?.uid,
        name: values.name,
        patterns: regexList,
      },
    };
    axios
      .patch(`/patterns/${props.selectedPattern.uid}`, params)
      .then((response) => {
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        successToast({
          body: "PATTERNS.PATTERN_UPDATE_SUCCESS",
          intl: intl,
        });
        props.submitHandler(response.data.pattern);
        props.onHide();
        setSubmitting(false);
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const onHide = () => {
    setRegexList([]);
    props.onHide();
  };

  const initialValues = {
    name: props.editMode ? props.selectedPattern?.name : "",
    regex: "",
    patterns: props.selectedPattern?.patterns || [],
  };

  const validate = (values) => {
    const errors = {};

    return errors;
  };

  const onSubmit = (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    props.editMode
      ? editPattern(values, setSubmitting)
      : createPattern(values, setSubmitting);
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal show={props.showModal} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id={
              props.editMode
                ? "PATTERNS.EDIT_PATTERN"
                : "PATTERNS.CREATE_PATTERN"
            }
          />
          {renderTooltip(
            <Button
              variant="outline-primary"
              className="ml-2 py-1 px-2"
              style={{ marginBottom: "0.25em" }}
              onClick={() => setShowSyntaxModal(true)}
            >
              <i className="fas fa-info"></i>
            </Button>,
            <FormattedMessage id="PATTERNS.REGEX_SYNTAX" />
          )}
        </Modal.Title>
      </Modal.Header>
        <Form noValidate={true} autoComplete="off" onSubmit={formik.handleSubmit}>
          <Modal.Body className="obj-cus-h-35 overflow-auto">
          { props.loading ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <CircularProgress />
            </div>
          ) : (
          <>
            <div className="pb-3">
              <span className="font-weight-bold">
                <FormattedMessage id="PATTERNS.NAME" />
              </span>

              <Form.Control
                type="text"
                name="name"
                className="border-secondary"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </div>

            <div>
              <span className="font-weight-bold">
                <FormattedMessage id="PATTERNS.PATTERNS" />
              </span>
              <hr className="mb-3 mt-1 border-primary" />
              <InputGroup>
                <Form.Control
                  type="text"
                  name="regex"
                  className="border-secondary"
                  value={formik.values.regex}
                  onChange={formik.handleChange}
                  placeholder="e.g: .*\.marca\.com"
                />
                <InputGroup.Append>
                  <Button
                    variant="outline-secondary"
                    onClick={() => addRegex(formik.values.regex, formik.setFieldValue)}
                  >
                    <i className="fa fa-plus" />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>

            {regexList?.length > 0 && (
              <ListGroup className="mt-3">
                {regexList?.map((rgx, index) => (
                  <ListGroup.Item
                    key={`${rgx}-${index}`}
                    className="d-flex justify-content-between"
                  >
                    {rgx === selectedRegex ? (
                      <>
                        <Form.Control
                          type="text"
                          value={newRegexName}
                          className="w-75 rounded-0 border-top-0 border-right-0 border-left-0 border-primary pl-0"
                          onChange={(e) => setRegexName(e.target.value)}
                          ref={regexNameFocus}
                        />
                        <div className="my-auto">
                          {renderTooltip(
                            <Button
                              variant="transparent"
                              className="p-0 mr-2"
                              disabled={formik.isSubmitting}
                              onClick={() => {
                                editRegex(newRegexName, index);
                              }}
                            >
                              <i className="fas fa-check" />
                            </Button>,
                            <FormattedMessage id="GENERAL.SAVE_SMALL" />
                          )}
                          {renderTooltip(
                            <Button
                              variant="transparent"
                              className="p-0"
                              disabled={formik.isSubmitting}
                              onClick={() => {
                                setSelectedRegex({});
                                setRegexName(rgx);
                              }}
                            >
                              <i className="fas fa-times" />
                            </Button>,
                            <FormattedMessage id="GENERAL.CANCEL" />
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <span className="w-75">{rgx}</span>
                        <div className="my-auto">
                          {renderTooltip(
                            <Button
                              variant="transparent"
                              className="px-1 py-0"
                              onClick={() => {
                                setSelectedRegex(rgx);
                                setRegexName(rgx);
                              }}
                            >
                              <i className="fas fa-pencil-alt" />
                            </Button>,
                            <FormattedMessage id="GENERAL.EDIT_SMALL" />
                          )}
                          {renderTooltip(
                            <Button
                              variant="transparent"
                              className="px-1 py-0"
                              onClick={() => deleteRegex(index, rgx)}
                            >
                              <i className="fas fa-trash" />
                            </Button>,
                            <FormattedMessage id="GENERAL.DELETE_SMALL" />
                          )}
                        </div>
                      </>
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
            </>
          )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" disabled={formik.isSubmitting || props.loading} type="submit">
              <i className="fas fa-check mr-2" />
              {props.editMode ? (
                <FormattedMessage id="GENERAL.UPDATE" />
              ) : (
                <FormattedMessage id="GENERAL.SAVE" />
              )}
            </Button>
          </Modal.Footer>
        </Form>
      <RegexSyntaxModal
        showModal={showSyntaxModal}
        onHide={() => setShowSyntaxModal(false)}
      />
    </Modal>
  );
};

export default PatternFormModal;
