import React from 'react'
import Router from './shared/Router'

import './vendor/styles/bootstrap.scss'
import './vendor/styles/appwork.scss'
import './vendor/styles/theme-teldat.scss'
import './vendor/styles/colors.scss'
import './vendor/styles/uikit.scss'
import './App.scss'
import I18nProvider from './i18n/i18nProvider'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from './store'
import ThemeProvider from 'assets/materialUIThemeProvider/ThemeProvider'

const App = () => {
  return (
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <ThemeProvider>
          <I18nProvider>
            <Router />
          </I18nProvider>
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  )
}

export default App
