import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useIntl } from "react-intl";
import { whereUsed, objectDatas } from "utils/utils";
import { useDispatch } from "react-redux";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { errorToast } from "utils/ToastHelper";
import { WaitTime } from "utils/SearchWaitTime";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";

const Applications = () => {
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedApplication, setSelectedApplication] = useState({ name: "" });
  const [applications, setApplications] = useState([]);
  const [usedList, setUsedList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [applicationCount, setApplicationCount] = useState(0);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [sort, setSort] = useState("ASC");
  const intl = useIntl();
  const dispatch = useDispatch();

  const selectApplication = useCallback(async (application) => {
    setPreviewLoading(true);
    setSelectedApplication(application);
    let val = await whereUsed(application.uid);
    setUsedList(val);
    setPreviewLoading(false);
  }, []);

  const fetchApplications = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    
    let and_param = [{"not": {"in":[ "comments", objectDatas["application-site-custom"].comment ]}}];
    if (searchQuery && searchQuery !== ""){
      and_param.push({"in":[ "name", searchQuery ]});
    }

    axios
      .get("/application_sites", {
        params: { page_num: pageNumber, and_param: and_param, sort: sort },
      })
      .then((response) => {
        setApplications(response.data.applications);
        setApplicationCount(response.data.applications_count);
        setLoading(false);
        if (response.data.applications.length > 0) {
          selectApplication(response.data.applications[0]);
        } else {
          setPreviewLoading(false);
        }
        dispatch(SetPolicyChanges(response.data.count_of_changes));
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "APPLICATIONS.LOAD_ERROR", intl: intl });
      });
  }, [pageNumber, searchQuery, sort, dispatch, selectApplication, intl]);

  useEffect(() => {
    fetchApplications();
  }, [fetchApplications]);

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const getHeader = () => {
    return (
      <span className="my-auto">
        <i
          className={`${objectDatas["application-site"].icon} mr-2`}
        ></i>
        {selectedApplication.name}
      </span>
    )
  }

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={applicationCount}
          items={applications}
          selectedItem={selectedApplication}
          selectItem={selectApplication}
          itemType="application-site"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
        />
      </ObjectExplorerItemsPane>
      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={applicationCount}
        usedList={usedList}
        header={getHeader()}
      >
        <div className="d-flex justify-content-between">
          <span>{selectedApplication.description}</span>
        </div> 
      </ObjectExplorerDetails>
    </ObjectExplorerTab>
  );
};

export default Applications;
