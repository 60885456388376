import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import {
  whereUsed,
  isUserReadOnly,
  objectDatas,
} from "utils/utils";
import DomainFormModal from "./DomainFormModal";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { successToast, errorToast } from "utils/ToastHelper";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import BeSafeButton from "components/common/BeSafeButton";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";

const Domain = () => {
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState({ name: "" });
  const [domains, setDomains] = useState([]);
  const [usedList, setUsedList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [domainCount, setDomainCount] = useState(0);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [sort, setSort] = useState("ASC");
  const [lastAdded, setLastAdded] = useState();
  const intl = useIntl();
  const dispatch = useDispatch();

  const selectDomain = useCallback(
    async (domain) => {
      setPreviewLoading(true);
      axios
        .get(`/domains/${domain.uid}`)
        .then(async (response) => {
          dispatch(SetPolicyChanges(response.data.domain.count_of_changes));
          response.data.domain["is-sub-domain"] =
            !response.data.domain["is-sub-domain"];
          setSelectedDomain(response.data.domain);
        })
        .catch((err) => {
          if (err.response?.data?.error !== 'cancelled')
            errorToast({ body: "DOMAIN.SELECT_ERROR", intl: intl });
        });
      let val = await whereUsed(domain.uid);
      setUsedList(val);
      setPreviewLoading(false);
    },
    [dispatch, intl]
  );

  const fetchDomains = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/domains", {
        params: { page_num: pageNumber, search_query: searchQuery, sort: sort },
      })
      .then((response) => {
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        setDomains(response.data.domains);
        setLoading(false);
        if (response.data.domains.length > 0) {
          selectDomain(response.data.domains[0]);
        } else {
          setPreviewLoading(false);
        }
        setDomainCount(response.data.domains_count);
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "DOMAIN.LOAD_ERROR", intl: intl });
      });
  }, [dispatch, intl, pageNumber, searchQuery, selectDomain, sort]);

  useEffect(() => {
    fetchDomains();
  }, [fetchDomains]);

  const submitHandler = (domain) => {
    setLastAdded(domain.uid);
    fetchDomains();
  };

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const editDomain = (selectedDomain) => {
    setSelectedDomain(selectedDomain);
    setShowModal(true);
    setEditMode(true);
  };

  const updateDomain = (d) => {
    fetchDomains();
  };

  const deleteDomain = (selectedDomain) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        axios
          .delete(`/domains/${selectedDomain.uid}`)
          .then((response) => {
            successToast({ body: "GENERAL.DELETE_SUCCESS", intl: intl });
            fetchDomains();
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          });
      },
    });
  };

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
    }),
    shallowEqual
  );

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, 1000);
    setSearchTimeout(timeout);
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const showAddDomainModal = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const getHeader = () => {
    return (
      <>
        <span className="my-auto">
          <i className={`${objectDatas["dns-domain"].icon} mr-2`}></i>
          {selectedDomain.name}
        </span>
        <div>
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            onClick={() => editDomain(selectedDomain)}
            icon="fas fa-pencil-alt"
            tooltip="GENERAL.EDIT_SMALL"
            visible={!selectedDomain?.["read-only"] && !isReadOnlyUser && !selectedDomain.lock}
          />
          <BeSafeButton
            variant="transparent"
            className="px-2"
            onClick={() => deleteDomain(selectedDomain)}
            icon="fas fa-trash"
            tooltip="GENERAL.DELETE_SMALL"
            visible={!selectedDomain?.["read-only"] && !isReadOnlyUser && !selectedDomain.lock}
          />
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            icon="fas fa-lock"
            tooltip="GENERAL.LOCKED"
            visible={selectedDomain.lock}
          />
        </div>
      </>
    )
  }

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        createFunction={showAddDomainModal}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={domainCount}
          items={domains}
          selectedItem={selectedDomain}
          selectItem={selectDomain}
          itemType="dns-domain"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          editFunction={editDomain}
          deleteFunction={deleteDomain}
          lastAdded={lastAdded}
        />
      </ObjectExplorerItemsPane>

      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={domainCount}
        usedList={usedList}
        header={getHeader()}
      >
        <div>
          <span>
            <FormattedMessage id="DOMAIN.FQDN" />:{" "}
          </span>
          <span>
            {selectedDomain["is-sub-domain"] ? (
              <FormattedMessage id="USERS.YES" />
            ) : (
              <FormattedMessage id="USERS.NO" />
            )}
          </span>
        </div>
        <div>{selectedDomain.comments}</div>
      </ObjectExplorerDetails>
      <DomainFormModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        domain={editMode ? selectedDomain : ""}
        isEditMode={editMode ? true : false}
        updateDomainList={submitHandler}
        updateDomain={(domain) => updateDomain(domain)}
        loading={previewLoading}
      />
    </ObjectExplorerTab>
  );
};

export default Domain;
