import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import cogoToast from "cogo-toast";
import axios from "axios";
import moment from "moment";
import { IPv6 } from 'ipaddr.js';

const CANCELLED_PROMISE_RESPONSE = { response: { data: { error: 'cancelled' } } };
let cancelTokenSource = axios.CancelToken.source();

export function setupAxios(axios) {
  axios.defaults.baseURL =
    process.env.NODE_ENV === "production"
    ? /*process.env.REACT_APP_BASE_URL + */ "/api"
      : "http://localhost:3000/api";
  axios.defaults.withCredentials = true;
  axios.defaults.headers["Cache-Control"] = "no-cache";
  axios.defaults.headers.Accept = "application/json";

  const renderLogin = async () => {
    return window.location.replace("/logout");
  };

  axios.interceptors.request.use(request => {
    if (request.method === 'get' && request.url !== '/policies')
      request.cancelToken = cancelTokenSource.token;
    return request;
  });

  if (axios.defaults.timeout === 0) {
    axios.interceptors.response.use(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        if (error.message === "Network Error") {
          cogoToast.error("API server not running", {
            heading: "Error",
            bar: { size: "10px" },
            position: "top-right",
          });
        } else if (
          !axios.isCancel(error) &&
          window.location.pathname !== "/forgot-password" &&
          window.location.pathname !== "/reset-network-password" &&
          window.location.pathname !== "/logout" &&
          window.location.pathname !== "/login" &&
          window.location.pathname !== "/register" &&
          error.response.status === 401
        ) {
          renderLogin();
        } else {
          return Promise.reject(axios.isCancel(error) ? CANCELLED_PROMISE_RESPONSE : error);
        }
      }
    );
  }
}

export function cancelRequest() {
  cancelTokenSource.cancel('Route changed');
  cancelTokenSource = axios.CancelToken.source();
}

export function validateIPaddress(ipaddress) {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  ) {
    return true;
  }
  return false;
}

export function validateIPV6address(ipaddress) {
  if (IPv6.isValid(ipaddress)) {
    return true;
  }
  return false;
}

export function isUrlValid(userInput) {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
}

export function isHostnameValid(userInput) {
  var res = userInput.match(
    /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)+([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])\.([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/g
  );
  if (res == null) return false;
  else return true;
}

export function isValidEmail(email) {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return true;
  }
  return false;
}

export const renderTooltip = (child, tootipText, placement) =>
  tootipText !== "" ? (
    <OverlayTrigger
      placement={placement || "bottom"}
      overlay={<Tooltip id="button-tooltip-2">{tootipText}</Tooltip>}
    >
      {child}
    </OverlayTrigger>
  ) : (
    child
  );

export const isUserReadOnly = (auth) => {
  return auth.user !== null ? auth.user.is_read_only || auth.user.roles[0].name === "client" : false;
};

export const getAccountType = (auth) => {
  return auth.user !== null ? auth.user.account_type : accountTypes.standard.code;
};

export const isMultifirewall = (auth) => {
  return auth.user !== null ? auth.user.account_type === accountTypes.multifirewall.code : false;
};

export const isClientUser = (auth) => {
  return auth.user !== null ? auth.user.roles[0].name === "client" : false;
};

export const getClientName = (auth) => {
  if (auth.user !== null && auth.user.clients && auth.user.clients.length > 0) {
    return auth.user.clients[0].name;
  }
  return "";
};

export const isSubdomain = (url) => {
  var regex = new RegExp(/^([a-z]+:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);

  return !!url.match(regex);
};

export const localGroup = "Local Group";
export const publicGroup = "Public Group";
export const LAN_GROUP = "LanGroup";
export const NO_LAN_GROUP = "NoLanGroup";

export const whereUsed = async (uid) => {
  let values = [];
  try {
    var response = await axios.get(`/miscs/${uid}/where_used_list`);
    var res = response.data;
    if (res.used_directly.length) values = [...res.used_directly];
    if (res.used_indirectly.length)
      values = [...values, ...res.used_indirectly];
    return values.length <= 0 ? [] : values.map((val) => val);
  } catch (err) {
    return [];
  }
};

export const isSpacePreset = (value) => {
  if (/\s/.test(value)) {
    return true;
  }
  return false;
};

export const hasSpecialCharacters = (value) => {
  if (/[ `!@#$%^&*()+=[\]{};':"\\|,.<>/?~]/.test(value)) {
    return true;
  }
  return false;
};

export const isStartingWithAlphabet = (value) => {
  if (/^[a-zA-Z]+$/.test(value.charAt(0))) {
    return true;
  }
  return false;
};

export const isLocalIpaddress = (value) => {
  return /(((192\.168\.)|(172\.16\.))(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([8-9]|(1[0-9])|(2[0-9])|(3[0-2]))|(10\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))\/([8-9]|(1[0-9])|(2[0-9])|(3[0-2])))$/.test(
    value
  );
};

export const isPublicIpaddress = (value) => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([8-9]|(1[0-9])|(2[0-9])|(3[0-2]))$/.test(
    value
  );
};

export const isMaskBetween24And32 = (value) => {
  return /^(.*?)\/((2[4-9])|(3[0-2]))$/.test(
    value
  );
};

export const isValidIPSubnet = (value, type) => {
  // MASK MUST BE 8-32
  if (isLocalIpaddress(value) || isPublicIpaddress(value)) {
    return true;
  }
  return false;
};

export const isLocalIpaddressNoMask = (value) => {
  return /(((192\.168\.)|(172\.16\.))(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/((1[6-9])|(2[0-9])|(3[0-2]))|(10\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))$/.test(
    value
  );
};

export const isPublicIpaddressNoMask = (value) => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    value
  );
};

export const isValidIP = (value) => {
  if (isLocalIpaddressNoMask(value) || isPublicIpaddressNoMask(value)) {
    return true;
  }
  return false;
};

export const logsInputSearchValidation = (value) => {
  return /(:|\s?and|\s?or|\s?not)\s*?$/i.test(value);
};

export const dateTimeFormat = (value) => {
  let dateTime = moment(new Date(value)).format("MMMM Do YYYY, h:mm:ss a");
  return dateTime;
};

export const capitalizeCustomTimePeriod = (value) => {
  return value
    .split("-")
    .map(function (item) {
      return item.toUpperCase();
    })
    .join("_");
};

export const removeGroupNameUnderScore = (value) => {
  return value.replace(/^A_/, "");
};

export const isAccessRoleSourceType = (value) => {
  return value === "access-role";
};

export const isLanNetwork = (n) =>
  n?.["nat-settings"]?.["hide-behind"] === "gateway";

export const isNotLanNetwork = (n) =>
  n?.["nat-settings"]?.["hide-behind"] === undefined;

export const isLanGroup = (g) => {
  const groupType = g.comments ? g.comments.split(";")[0] : g.comments;
  return groupType === LAN_GROUP;
}

export const isNotLanGroup = (g) => {
  const groupType = g.comments ? g.comments.split(";")[0] : g.comments;
  return groupType === NO_LAN_GROUP;
}

export const isValidNumberInput = (value) => {
  return value || value === 0;
};

export const objectDatas = {
  network: {
    text: "OBJECT_EXPLORER.NETWORK_GROUPS",
    icon: "fas fa-network-wired",
  },
  group: {
    text: "OBJECT_EXPLORER.NETWORK_GROUP",
    icon: "fas fa-project-diagram",
  },
  client_group: {
    text: "OBJECT_EXPLORER.CLIENT_NETWORK_GROUP",
    icon: "fas fa-project-diagram",
  },
  "access-role": {
    text: "OBJECT_EXPLORER.ACCESS_ROLES",
    icon: "fas fa-users",
  },
  "service-tcp": {
    text: "SERVICES.TCP_SERVICE",
    icon: "fas fa-exchange-alt",
  },
  "service-udp": {
    text: "SERVICES.UDP_SERVICE",
    icon: "fas fa-exchange-alt",
  },
  "service-icmp": {
    text: "SERVICES.ICMP_SERVICE",
    icon: "fas fa-exchange-alt",
  },
  "service-other": {
    text: "SERVICES.OTHER_SERVICE",
    icon: "fas fa-exchange-alt",
  },
  "application-site": {
    text: "OBJECT_EXPLORER.APPLICATIONS_DEFAULT",
    icon: "fas fa-cubes",
  },
  "application-site-custom": {
    text: "OBJECT_EXPLORER.APPLICATIONS_CUSTOM",
    icon: "fas fa-cubes",
    comment: "_(is_custom)_"
  },
  "application-site-category": {
    text: "OBJECT_EXPLORER.CATEGORIES",
    icon: "fas fa-shapes",
  },
  "dns-domain": {
    text: "OBJECT_EXPLORER.DOMAIN",
    icon: "fas fa-globe",
  },
  users: {
    text: "USER_GROUPS.GROUP",
    icon: "far fa-user",
  },
  "user-group": {
    text: "USER_GROUPS.GROUP",
    icon: "fas fa-users",
  },
  "access-role-with-group": {
    text: "USER_GROUPS.GROUP",
    icon: "fas fa-users",
  },
  "threat-protection": {
    text: "THREAT_PREVENTION.THREAT_PROTECTIONS",
    icon: "fas fa-shield-virus",
  },
  CpmiSdTopicPerProfileDynamic: {
    icon: "fas fa-shield-virus",
  },
  pattern: {
    text: "PATTERNS.URL",
    icon: "fas fa-spell-check",
  },
  CpmiPatternDataType: {
    text: "PATTERNS.URL",
    icon: "fas fa-spell-check",
  },
  default: {
    icon: "far fa-circle",
  },
  "simple-gateway": {
    text: "FIREWALLS.FIREWALLS",
    icon: "fas fa-hdd"
  },
  "simple-cluster": {
    icon: "fas fa-server",
  },
  policy: {
    icon: "fas fa-scroll",
  },
  "updatable-object": {
    text: "OBJECT_EXPLORER.UPDATABLE_OBJECTS",
    icon: "fas fa-exchange-alt",
  },
  "files": {
    text: "PATTERNS.FILE",
    icon: "fas fa-file",
  },
  "CpmiFileDataType": {
    text: "PATTERNS.FILE",
    icon: "fas fa-file",
  },
  "application-site-group": {
    text: "OBJECT_EXPLORER.APPLICATION_GROUPS",
    icon: "fas fa-cubes",
  },
  "address-range": {
    text: "OBJECT_EXPLORER.ADDRESS_RANGE",
    icon: "fas fa-circle-nodes",
  },
  "access-rule": {
    text: "POLICIES.ACCESS_RULE",
    icon: "fas fa-key",
  },
  "https-rule": {
    text: "SSL_INSPECTION.SSL_EXCEPTION",
    icon: "fas fa-syringe",
  },
  "threat-prevention-rule": {
    text: "THREAT_PREVENTION.THREAT_RULE",
    icon: "fas fa-shield-virus",
  },
};

export const accountTypes = {
  standard: {
    code: "standard",
    text: "ACCOUNTS.STANDARD",
  },
  carrier: {
    code: "carrier",
    text: "ACCOUNTS.CARRIER",
  },
  multifirewall: {
    code: "multifirewall",
    text: "ACCOUNTS.MULTIPLE_FIREWALL",
  },
  carrier_cgnat: {
    code: "carrier_cgnat",
    text: "ACCOUNTS.CARRIER_CGNAT",
  },
  standard_cgnat: {
    code: "standard_cgnat",
    text: "ACCOUNTS.STANDARD_CGNAT",
  }
};
