import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import NetworkFormModal from "./NetworkFormModal";
import { whereUsed, getAccountType, accountTypes, isValidIPSubnet } from "utils/utils";
import { useDispatch } from "react-redux";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { successToast, errorToast, warningToast } from "utils/ToastHelper";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { WaitTime } from "utils/SearchWaitTime";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { useSelector, shallowEqual} from "react-redux";
import {
  isUserReadOnly,
  objectDatas,
} from "utils/utils";
import BeSafeButton from "components/common/BeSafeButton";
import { IPv4 } from 'ipaddr.js';

const Networks = () => {
  const [networks, setNetworks] = useState([]);
  const [networksCount, setNetworksCount] = useState(0);
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const [usedList, setUsedList] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [lastAdded, setLastAdded] = useState();
  const sort = "ASC";

  const dispatch = useDispatch();
  const intl = useIntl();

  const { isReadOnlyUser, accountType } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
      accountType: getAccountType(auth)
    }),
    shallowEqual
  );

  const selectNetwork = useCallback(
    (network) => {
      setPreviewLoading(true);
      axios
        .get(`/networks/${network.uid}`)
        .then(async (response) => {
          dispatch(SetPolicyChanges(response.data.network.count_of_changes));
          setSelectedNetwork(response.data.network);
          await getNetworkUsedList(network.uid);
          setPreviewLoading(false);
        })
        .catch((err) => {
          if (err.response?.data?.error !== 'cancelled')
            errorToast({ body: "NETWORK_GROUPS.SELECT_ERROR", intl: intl });
        });
    },
    [dispatch, intl]
  );

  const fetchNetworks = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/networks", {
        params: { page_num: pageNumber, search_query: searchQuery, sort: sort },
      })
      .then((response) => {

        dispatch(SetPolicyChanges(response.data.count_of_changes));
        setNetworks(response.data.networks);
        setLoading(false);
        if (response.data.networks.length) {
          selectNetwork(response.data.networks[0]);
          setNetworksCount(response.data.networks_count);
        } else {
          setPreviewLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "NETWORK_GROUPS.LOAD_ERROR", intl: intl });
      });
  }, [dispatch, intl, pageNumber, searchQuery, selectNetwork, sort]);

  useEffect(() => {
    fetchNetworks();
  }, [fetchNetworks]);

  const showEditNetworkModal = (selectedNetwork) => {
    setSelectedNetwork(selectedNetwork);
    setEditMode(true);
    setShowModal(true);
  };

  const showNewNetworkModal = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
    setEditMode(false);
  };

  const submitHandler = (network) => {
    setLastAdded(network.uid);
    fetchNetworks();
  };

  const deleteNetwork = async (network) => {
    let newUsedList;
    if (network.uid !== selectedNetwork.uid) {
      newUsedList = await getNetworkUsedList(network.uid);
    }

    if ((typeof newUsedList !== "undefined" && newUsedList.length > 0) || (typeof newUsedList === "undefined" && usedList.length > 0)) {
      warningToast({
        body: "NETWORK_GROUPS.ALREADY_IN_USE",
        intl: intl,
      });
    } else {
      ConfirmationPopup({
        title: intl.formatMessage({
          id: "GENERAL.WARNING",
        }),
        description: intl.formatMessage({
          id: "GENERAL.CONFIRM_DELETE",
        }),
        okLabel: intl.formatMessage({
          id: "GENERAL.OK",
        }),
        cancelLabel: intl.formatMessage({
          id: "GENERAL.CANCEL",
        }),
        okAction: () => {
          axios
          .delete(`/networks/${network.uid}`)
          .then((response) => {
            let vals = networks.filter(
              (nw) => nw.uid !== network.uid
            );
            dispatch(SetPolicyChanges(response.data.count_of_changes));
            setNetworks(vals);
            if (vals.length) selectNetwork(vals[0]);
            successToast({
              body: "NETWORK_GROUPS.NETWORK_OBJECT_DELETED_SUCCESS",
              intl: intl,
            });
          })
          .catch((err) => {
            if (err.response.data.already_in_used) {
              errorToast({
                body: "NETWORK_GROUPS.ALREADY_IN_USE",
                intl: intl,
              });
            } else {
              errorToast({
                body: err.response.data?.error,
                intl: intl,
              });
            }
          });
        },
      });
    }
  };

  const getNetworkUsedList = async (uid) => {
    let val = await whereUsed(uid);
    setUsedList(val);
    return val;
  };

  const sortData = (sort) => {};

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const getHeader = () => {
    return (
      <>
        <span className="my-auto">
          <i
            className={`${objectDatas["network"].icon} mr-2`}
          ></i>
          {selectedNetwork.name}
        </span>
        <div>
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            onClick={() => showEditNetworkModal(selectedNetwork)}
            icon="fas fa-pencil-alt"
            tooltip="GENERAL.EDIT_SMALL"
            visible={!isReadOnlyUser && !selectedNetwork.lock}
          />
          <BeSafeButton
            variant="transparent"
            className="px-2"
            onClick={() => deleteNetwork(selectedNetwork)}
            icon="fas fa-trash"
            tooltip="GENERAL.DELETE_SMALL"
            visible={!isReadOnlyUser && !selectedNetwork.lock}
          />
        </div>
      </>
    )
  };

  const getNetworkType = (item) => {
    if (item["nat-settings"]?.["hide-behind"] === "gateway") {
      return "LAN";
    }

    return "No-LAN";
  };

  const getNetworkMask = (ip) => {
    const type = getNetworkType(selectedNetwork)
    if (isValidIPSubnet(ip, type)) {
      const ipv4 = IPv4.parseCIDR(ip);

      return IPv4.subnetMaskFromPrefixLength(ipv4[1]);
    }
    return "";
  };

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        createFunction={showNewNetworkModal}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={networksCount}
          items={networks}
          selectedItem={selectedNetwork}
          selectItem={selectNetwork}
          itemType="network"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          editFunction={showEditNetworkModal}
          deleteFunction={deleteNetwork}
          lastAdded={lastAdded}
        />
      </ObjectExplorerItemsPane>
      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={networksCount}
        usedList={usedList}
        header={getHeader()}
      >
        <Container>
          <Row>
            <Col sm={12}>
              <span className="font-weight-bold">
                <FormattedMessage id="NETWORK_GROUPS.TYPE" />
              </span>
              <span>{`: ${getNetworkType(selectedNetwork)}`}</span>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <span className="font-weight-bold">
                {(accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) ? <FormattedMessage id="NETWORK_GROUPS.NATED_IPV4" /> : <FormattedMessage id="NETWORK_GROUPS.IPV4" />}
              </span>
              <span>{`: ${selectedNetwork?.subnet4}`}</span>
            </Col>
            <Col sm={6}>
              <span className="font-weight-bold">
                <FormattedMessage id="NETWORK_GROUPS.NET_MASK" />
              </span>
              <span>{`: ${selectedNetwork?.["subnet-mask"]}`}</span>
            </Col>
          </Row>
          {(accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) && (
            <Row>
              <Col sm={6}>
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_GROUPS.ORIGINAL_IPV4" />
                </span>
                <span>{`: ${selectedNetwork?.comments?.split("/")[0]}`}</span>
              </Col>
              <Col sm={6}>
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_GROUPS.NET_MASK" />
                </span>
                <span>{`: ${getNetworkMask(selectedNetwork?.comments)}`}</span>
              </Col>
            </Row>
          )}

        <div className="d-flex py-2 justify-content-between font-weight-bold">
          <FormattedMessage id="NETWORK_GROUPS.GROUPS" />
        </div>
        <ListGroup>
          {selectedNetwork?.groups?.map((g, index) => (
            <ListGroup.Item
              key={g.uid}
              className="d-flex justify-content-between py-0"
            >
              <span className="w-75 py-2">{g.name}</span>
            </ListGroup.Item>
          ))}
        </ListGroup>
        </Container>
      </ObjectExplorerDetails>
      <NetworkFormModal
        showModal={showModal}
        onHide={hideModal}
        editMode={editMode}
        selectedNetwork={editMode ? selectedNetwork : {}}
        submitHandler={submitHandler}
        loading={previewLoading}
        usedList={usedList}
      />
    </ObjectExplorerTab>
  );
};

export default Networks;
