import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "assets/scss/logs.scss";

const BulkUserFileHelpModal = (props) => {
  const onHide = () => {
    props.onHide();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={onHide}
      centered
      size="lg"
      dialogClassName="modal-shadow-lg"
    >
      <Modal.Header closeButton className="bg-light-grey">
        <Modal.Title>
          <img
            height="30"
            width="30"
            src={`${process.env.PUBLIC_URL}/images/besafe_logo.png`}
            alt="besafe-corporate"
          />
          <span className="d-none d-md-inline-block ml-1">
            <FormattedMessage id="NETWORK_USERS.FILE_HELP_TITTLE" />
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="vh-75 overflow-auto">
        <div>
          <p>To generate a file with the exported users from your Active Directory execute the following command in a Powershell console in your Active Directory Manager Server:</p>
          <pre>
            <code class="d-inline-block bg-light p-3">
              <p>csvde -f [filename].csv -r objectClass=user</p>
            </code>
          </pre>
          <p>When the file is loaded only the valid rows will be processed.</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BulkUserFileHelpModal;
