import React, { useState } from "react";
import { Modal, Tab, Row, Col, Nav } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Networks from "./networks/Networks";
import NetworkGroups from "./networks/NetworkGroups";
import AddressRanges from "./networks/AddressRanges";
import Applications from "./applications/Applications";
import CustomApplications from "./applications/CustomApplications";
import ApplicationGroups from "./applications/ApplicationGroups";
import Categories from "./categories/Categories";
import ServiceObjects from "./services/ServiceObjects";
import Domain from "./domain/Domain";
import NetworkUsers from "./network_users/NetworkUsers";
import UserGroups from "./network_users/UserGroups";
import PatternObjects from "./patterns/PatternObjects";
import PatternFiles from "./patterns/PatternFiles";
import { useSelector, shallowEqual } from "react-redux";
import {
  getAccountType,
  accountTypes,
  isClientUser
} from "utils/utils";
import UpdatableObjects from "./updatable_objects/UpdatableObjects";

const ObjectExplorer = (props) => {
  const [isServicesExpanded, setServicesExpanded] = useState(false);
  const [isNetworkMenuExpanded, setNetworkMenuExpanded] = useState(true);
  const [isApplicationsExpanded, setApplicationsExpanded] = useState(false);
  const [isDLPMenuExpanded, setDLPMenuExpanded] = useState(false);

  const { accountType, isUserClient } = useSelector(
    ({ auth }) => ({
      accountType: getAccountType(auth),
      isUserClient: isClientUser(auth)
    }),
    shallowEqual
  );

  const hideModal = () => {
    setServicesExpanded(false);
    setDLPMenuExpanded(false);
    setNetworkMenuExpanded(true);
    props.hideModal();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={hideModal}
      centered
      scrollable
      className="object-explorer"
      dialogClassName="vw-90"
    >
      <Modal.Header className="py-3 border border-0 bg-light-grey" closeButton>
        <Modal.Title>
          <FormattedMessage id="NAVBAR.OBJECT_EXPLORER" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="obj-cus-h-35 p-0 bg-light-grey">
        <Tab.Container id="left-tabs-example" defaultActiveKey={props.activeKey || "networks"}>
          <Row className="obj-cus-h-35 mx-0">
            <Col md={3} sm={12} className="obj-cus-h-35 m-0 px-3 py-2">
              <div className="card h-100 overflow-auto">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <div className="border-bottom">
                      <Nav.Link
                        className="pl-2 mr-0 d-flex justify-content-between align-items-baseline"
                        onClick={() =>
                          setNetworkMenuExpanded(!isNetworkMenuExpanded)
                        }
                      >
                        <FormattedMessage id="OBJECT_EXPLORER.NETWORK_ELEMENTS" />
                        <i
                          className={`${
                            isNetworkMenuExpanded
                              ? "fas fa-chevron-up"
                              : "fas fa-chevron-down"
                          } px-3`}
                        />
                      </Nav.Link>
                      <div
                        className={isNetworkMenuExpanded ? "d-block" : "d-none"}
                      >
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="networks"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.NETWORKS" />
                        </Nav.Link>
                        {!isUserClient &&
                          <Nav.Link
                            className="pl-3 border-bottom mr-0 small"
                            eventKey="network-groups"
                          >
                            { (accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) ? <FormattedMessage id="OBJECT_EXPLORER.CLIENT_NETWORK_GROUP" /> : <FormattedMessage id="OBJECT_EXPLORER.NETWORK_GROUP" />}
                          </Nav.Link>
                        }

                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="address-range"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.ADDRESS_RANGE" />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav.Item>

                  <Nav.Item>
                    <div className="border-bottom">
                      <Nav.Link
                        className="pl-2 mr-0 d-flex justify-content-between align-items-baseline"
                        onClick={() => setServicesExpanded(!isServicesExpanded)}
                      >
                        <FormattedMessage id="OBJECT_EXPLORER.SERVICES" />
                        <i
                          className={`${
                            isServicesExpanded
                              ? "fas fa-chevron-up"
                              : "fas fa-chevron-down"
                          } px-3`}
                        />
                      </Nav.Link>
                      <div
                        className={isServicesExpanded ? "d-block" : "d-none"}
                      >
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="service-objects-tcp"
                        >
                          <FormattedMessage id="SERVICES.TCP_SERVICE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="service-objects-udp"
                        >
                          <FormattedMessage id="SERVICES.UDP_SERVICE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="service-objects-icmp"
                        >
                          <FormattedMessage id="SERVICES.ICMP_SERVICE" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="service-objects-others"
                        >
                          <FormattedMessage id="SERVICES.OTHER_SERVICE" />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav.Item>
                  <Nav.Item>
                    <div className="border-bottom">
                      <Nav.Link
                        className="pl-2 mr-0 d-flex justify-content-between align-items-baseline"
                        onClick={() =>
                          setApplicationsExpanded(!isApplicationsExpanded)
                        }
                      >
                        <FormattedMessage id="OBJECT_EXPLORER.APPLICATIONS" />
                        <i
                          className={`${
                            isApplicationsExpanded
                              ? "fas fa-chevron-up"
                              : "fas fa-chevron-down"
                          } px-3`}
                        />
                      </Nav.Link>
                      <div
                        className={
                          isApplicationsExpanded ? "d-block" : "d-none"
                        }
                      >
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="applications"
                        >
                          <FormattedMessage id="APPLICATIONS.DEFAULT" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="custom-applications"
                        >
                          <FormattedMessage id="APPLICATIONS.CUSTOM" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="application-groups"
                        >
                          <FormattedMessage id="APPLICATIONS.GROUPS" />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="pl-2 border-bottom mr-0"
                      eventKey="categories"
                    >
                      <FormattedMessage id="OBJECT_EXPLORER.CATEGORIES" />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="pl-2 border-bottom mr-0"
                      eventKey="domain"
                    >
                      <FormattedMessage id="OBJECT_EXPLORER.DOMAIN" />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <div className="border-bottom">
                      <Nav.Link
                        className="pl-2 mr-0 d-flex justify-content-between align-items-baseline"
                        onClick={() =>
                          setDLPMenuExpanded(!isDLPMenuExpanded)
                        }
                      >
                        <FormattedMessage id="OBJECT_EXPLORER.PATTERNS" />
                        <i
                          className={`${
                            isDLPMenuExpanded
                              ? "fas fa-chevron-up"
                              : "fas fa-chevron-down"
                          } px-3`}
                        />
                      </Nav.Link>
                      <div
                        className={isDLPMenuExpanded ? "d-block" : "d-none"}
                      >
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="pattern_contents"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.PATTERNS_CONTENT" />
                        </Nav.Link>
                        <Nav.Link
                          className="pl-3 border-bottom mr-0 small"
                          eventKey="pattern_files"
                        >
                          <FormattedMessage id="OBJECT_EXPLORER.PATTERNS_FILE" />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="pl-2 border-bottom mr-0"
                      eventKey="updatable-objects"
                    >
                      <FormattedMessage id="OBJECT_EXPLORER.UPDATABLE_OBJECTS" />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="pl-2 border-bottom mr-0"
                      eventKey="network-users"
                    >
                      <FormattedMessage id="NETWORK_USERS.USERS" />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="pl-2 border-bottom mr-0"
                      eventKey="user-groups"
                    >
                      <FormattedMessage id="USER_GROUPS.GROUP" />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
            <Col md={9} sm={12} className="px-0">
              <Tab.Content>
                <Tab.Pane eventKey="networks" mountOnEnter unmountOnExit>
                  <Networks />
                </Tab.Pane>
                <Tab.Pane eventKey="network-groups" mountOnEnter unmountOnExit>
                  <NetworkGroups />
                </Tab.Pane>
                <Tab.Pane eventKey="address-range" mountOnEnter unmountOnExit>
                  <AddressRanges />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="service-objects-tcp"
                  mountOnEnter
                  unmountOnExit
                >
                  <ServiceObjects type="tcp" key="tcp" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="service-objects-udp"
                  mountOnEnter
                  unmountOnExit
                >
                  <ServiceObjects type="udp" key="udp" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="service-objects-icmp"
                  mountOnEnter
                  unmountOnExit
                >
                  <ServiceObjects type="icmp" key="icmp" />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="service-objects-others"
                  mountOnEnter
                  unmountOnExit
                >
                  <ServiceObjects type="other" key="other" />
                </Tab.Pane>
                <Tab.Pane eventKey="applications" mountOnEnter unmountOnExit>
                  <Applications />
                </Tab.Pane>
                <Tab.Pane
                  eventKey="custom-applications"
                  mountOnEnter
                  unmountOnExit
                >
                  <CustomApplications />
                </Tab.Pane>
                <Tab.Pane eventKey="application-groups" mountOnEnter unmountOnExit>
                  <ApplicationGroups />
                </Tab.Pane>
                <Tab.Pane eventKey="categories" mountOnEnter unmountOnExit>
                  <Categories />
                </Tab.Pane>
                <Tab.Pane eventKey="domain" mountOnEnter unmountOnExit>
                  <Domain />
                </Tab.Pane>
                <Tab.Pane eventKey="network-users" mountOnEnter unmountOnExit>
                  <NetworkUsers />
                </Tab.Pane>
                <Tab.Pane eventKey="user-groups" mountOnEnter unmountOnExit>
                  <UserGroups />
                </Tab.Pane>
                <Tab.Pane eventKey="pattern_contents" mountOnEnter unmountOnExit>
                  <PatternObjects />
                </Tab.Pane>
                <Tab.Pane eventKey="updatable-objects" mountOnEnter unmountOnExit>
                  <UpdatableObjects />
                </Tab.Pane>
                <Tab.Pane eventKey="pattern_files" mountOnEnter unmountOnExit>
                  <PatternFiles />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default ObjectExplorer;
