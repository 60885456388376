import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";
import { useDispatch } from "react-redux";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { successToast, errorToast } from "utils/ToastHelper";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { CircularProgress } from "@material-ui/core";
import { isStartingWithAlphabet } from "utils/utils";

const NetworkUsersModal = (props) => {
  const [networkUser, setNetworkUser] = useState([]);

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    setNetworkUser(props.networkUser);
  }, [props.networkUser]);

  const createNetworkUser = (values, setSubmitting) => {
    axios
      .post("/network_users", {
        network_user: {
          name: values.name,
          email: values.email,
          "authentication-method": "check point password",
        },
      })
      .then((res) => {
        let response = res.data.network_user;
        response["read-only"] = false;
        dispatch(SetPolicyChanges(response.count_of_changes));
        successToast({
          body: "NETWORK_USERS.USER_CREATED_SUCCESS",
          intl: intl,
        });
        props.onHide();
        setSubmitting(false);
        props.submitHandler(response);
        formik.resetForm();
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const updateNetworkUser = (values, setSubmitting) => {
    axios
      .patch(`/network_users/${networkUser.uid}`, {
        network_user: {
          uid: networkUser.uid,
          "new-name": values.name,
          email: values.email
        },
      })
      .then((res) => {
        let response = res.data.network_user;
        response["read-only"] = false;
        dispatch(SetPolicyChanges(response.count_of_changes));
        successToast({
          body: "NETWORK_USERS.USER_UPDATED_SUCCESS",
          intl: intl,
        });
        props.onHide();
        setSubmitting(false);
        props.submitHandler(response);
        formik.resetForm();
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const confirmPasswordReset = () => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "NETWORK_USERS.CONFIRM_RESET",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        axios.post("/network_users/" + networkUser.uid + "/reset_password", {}).then(response => {
          dispatch(SetPolicyChanges(response.data.count_of_changes));
          successToast({
            body: "NETWORK_USERS.RESET_PASSWORD_MAIL_SUCCESS",
            intl: intl,
          });
        }).catch((error) => {
          errorToast({
            body: error.response.data?.error,
            intl: intl,
          });
        });
      },
    });
  };

  const initialValues = {
    name: props.editMode ? props.networkUser.name : "",
    email: props.editMode ? props.networkUser.email : "",
    auth_method: "check point password",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name || !values.name.trim()) {
      errors.name = intl.formatMessage({ id: "GENERAL.REQUIRED_FIELD" });
    } else if (!isStartingWithAlphabet(values.name)) {
      errors.name = intl.formatMessage({
        id: "GENERAL.INVALID_OBJECT_NAME",
      });
    }

    if (!values.auth_method || !values.auth_method.trim()) {
      errors.auth_method = intl.formatMessage({
        id: "GENERAL.REQUIRED_FIELD",
      });
    }
    if (props.editMode && !!props.networkUser?.email && !values.email) {
      errors.email = intl.formatMessage({
        id: "GENERAL.REQUIRED_FIELD",
      });
    }

    return errors;
  };

  const onSubmit = async (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    props.editMode
      ? updateNetworkUser(values, setSubmitting)
      : createNetworkUser(values, setSubmitting);
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal show={props.showModal} onHide={props.onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id={
              props.editMode
                ? "NETWORK_USERS.EDIT_USER"
                : "NETWORK_USERS.NEW_USER"
            }
          />
        </Modal.Title>
      </Modal.Header>
      <Form noValidate={true} autoComplete="off" onSubmit={formik.handleSubmit}>
        <Modal.Body>
        { props.loading ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <CircularProgress />
            </div>
          ) : (
          <>
          <Row className="mb-3">
            <Col sm={1} className="mt-2">
              <i className="fas fa-user text-xlarge" />
            </Col>
            <Col sm={11}>
              <Form.Control
                type="text"
                name="name"
                className="border-secondary"
                placeholder={intl.formatMessage({
                  id: "NETWORK_USERS.NAME",
                })}
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.touched.name && formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Form.Group className="mb-4">
            <span
              className="text-secondary text-big"
              style={{ textTransform: "uppercase" }}
            >
              <FormattedMessage id="NETWORK_USERS.EMAIL" />
            </span>
            <hr className="mt-0 border-secondary" />
            <Form.Control
              type="text"
              name="email"
              className="border-secondary"
              placeholder={intl.formatMessage({
                id: "NETWORK_USERS.USERNAME_EXAMPLE",
              })}
              value={formik.values.email}
              onChange={formik.handleChange}
              isInvalid={formik.touched.email && formik.errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          {props.editMode && (
            <>
              <span
                className="text-secondary text-big"
                style={{ textTransform: "uppercase" }}
              >
                <FormattedMessage id="NETWORK_USERS.PASSWORD" />
              </span>
              <hr className="mt-0 border-secondary" />
              <Form.Group as={Row} className="mb-4">
                <Col sm={3}>
                  <Button
                    variant="default"
                    onClick={() => confirmPasswordReset(formik.setFieldValue)}
                  >
                    <FormattedMessage id="GENERAL.RESET" />
                  </Button>
                </Col>
              </Form.Group>
            </>
          )}
          </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={formik.isSubmitting || props.loading}>
            <i className="fas fa-check mr-2" />
            <FormattedMessage
              id={props.editMode ? "GENERAL.UPDATE" : "GENERAL.SAVE"}
            />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default NetworkUsersModal;
