import React, { Component } from 'react'
import { Card, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import '../../vendor/styles/pages/authentication.scss'

class Register extends Component {
  constructor(props) {
    super(props)

    this.state = {
      credentials: {
        name: '',
        email: '',
        password: ''
      }
    }
  }

  onValueChange(field, e) {
    this.setState({
      credentials: {
        ...this.state.credentials,
        [field]: e.target.value
      }
    })
  }

  prevent(e) {
    e.preventDefault()
  }

  render() {
    return (
      <div className="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/login_bg.jpg')`}} >
        <div className="ui-bg-overlay bg-dark opacity-25"></div>

        <div className="authentication-inner py-5">

          <Card>
            <div className="p-4 px-sm-5 pt-sm-5">
              {/* Logo */}
              <div className="d-flex justify-content-center align-items-center pb-2 mb-4">
                <div className="ui-w-60">
                  <div className="w-100 position-relative" style={{ paddingBottom: '54%' }}>
                    <img className="w-100 position-absolute" src={`${process.env.PUBLIC_URL}/images/besafe_logo.png`} alt="logo" />
                  </div>
                </div>
              </div>
              {/* / Logo */}

              <h5 className="text-center text-muted font-weight-normal mb-4">Create an Account</h5>

              {/* Form */}
              <form>
                <Form.Group>
                  <Form.Label>Your name</Form.Label>
                  <Form.Control value={this.state.credentials.name} onChange={e => this.onValueChange('name', e)} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Your email</Form.Label>
                  <Form.Control value={this.state.credentials.email} onChange={e => this.onValueChange('email', e)} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" value={this.state.credentials.password} onChange={e => this.onValueChange('password', e)} />
                </Form.Group>
                <Button variant="primary" block className="mt-4">Sign Up</Button>
                <div className="text-light small mt-4">
                  By clicking "Sign Up", you agree to our&nbsp;
                  <a href="#d" onClick={this.prevent}>terms of service and privacy policy</a>.
                  We’ll occasionally send you account related emails.
                </div>
              </form>
              {/* / Form */}

            </div>
            <Card.Footer className="py-3 px-4 px-sm-5">
              <div className="text-center text-muted">
                Already have an account? <Link to="/login">Sign In</Link>
              </div>
            </Card.Footer>
          </Card>

        </div>
      </div>
    )
  }
}

export default Register
