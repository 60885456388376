import React, { useState, useEffect } from "react";
import ReactPaginate from "react-js-pagination";

const Pagination = (props) => {
  const [activePage, setActivePage] = useState(1);
  const perPage = 500;

  useEffect(() => {
    setActivePage(props.pageNumber);
  }, [props]);

  const onPageChange = (pageNum) => {
    if (activePage !== pageNum) {
      setActivePage(pageNum);
      props.handlePageChange(pageNum);
    }
  };

  return (
    <>
      <ReactPaginate
        activePage={activePage}
        itemsCountPerPage={perPage}
        totalItemsCount={props.totalItems}
        pageRangeDisplayed={props.pageRangeDisplayed}
        onChange={(event) => onPageChange(event)}
      />
    </>
  );
};

Pagination.defaultProps = {
  pageRangeDisplayed: 5
};

export default Pagination;
