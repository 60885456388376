import { combineReducers } from "redux";
import theme from "./themeStore";
import { reducer } from "../../i18n/i18n";
import * as policy from "store/ducks/policyChange.duck";
import * as maintenance from "store/ducks/maintenance.duck";
import * as auth from "../ducks/auth.duck";
import * as threat_profiles from "../ducks/threatProfile.duck";

export default combineReducers({
  theme,
  i18n: reducer,
  auth: auth.reducer,
  policy: policy.reducer,
  threat_profiles: threat_profiles.reducer,
  maintenance: maintenance.reducer
});
