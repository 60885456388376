import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { ListGroup, FormControl } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import {
  isUserReadOnly,
  whereUsed,
  objectDatas,
  isStartingWithAlphabet,
} from "utils/utils";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { successToast, errorToast } from "utils/ToastHelper";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { WaitTime } from "utils/SearchWaitTime";
import ApplicationFormModal from "./ApplicationModal";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import BeSafeContainer from "components/common/BeSafeContainer";
import BeSafeButton from "components/common/BeSafeButton";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";

const CustomApplications = () => {
  const [applications, setApplications] = useState([]);
  const [applicationsCount, setApplicationsCount] = useState(0);
  const [newApplicationName, setApplicationName] = useState("");
  const [editApplicationName, setEditApplicationName] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(true);
  const applicationNameFocus = useRef(null);
  const [selectedApplication, setSelectedApplication] = useState([]);
  const [usedList, setUsedList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [sort, setSort] = useState("ASC");
  const [lastAdded, setLastAdded] = useState();

  const dispatch = useDispatch();
  const intl = useIntl();

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
    }),
    shallowEqual
  );

  const selectApplication = useCallback((application) => {
    setPreviewLoading(true);
    setApplicationName(application.name);
    setSelectedApplication(application);
    getApplicationUsedList(application.uid);
    setPreviewLoading(false);
  }, []);

  const getApplicationUsedList = async (uid) => {
    let val = await whereUsed(uid);
    setUsedList(val);
  };

  const fetchApplications = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);

    let and_param = [
      { in: ["comments", objectDatas["application-site-custom"].comment] },
    ];
    if (searchQuery && searchQuery !== "") {
      and_param.push({ in: ["name", searchQuery] });
    }

    axios
      .get("/application_sites", {
        params: { page_num: pageNumber, and_param: and_param, sort: sort },
      })
      .then((response) => {
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        setApplications(response.data.applications);
        setApplicationsCount(response.data.applications_count);
        setLoading(false);
        if (response.data.applications.length) {
          selectApplication(response.data.applications[0]);
          setApplicationsCount(response.data.applications_count);
        } else {
          setPreviewLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "APPLICATIONS.LOAD_ERROR", intl: intl });
      });
  }, [dispatch, intl, pageNumber, searchQuery, selectApplication, sort]);

  useEffect(() => {
    fetchApplications();
  }, [fetchApplications]);

  useEffect(() => {
    if (editApplicationName && applicationNameFocus.current) {
      applicationNameFocus.current.focus();
    }
  }, [editApplicationName]);

  const showEditApplicationModal = () => {
    setEditMode(true);
    setShowModal(true);
  };
  const showCreateApplicationModal = () => {
    setSelectedApplication({});
    setEditMode(false);
    setShowModal(true);
  };

  const submitHandler = (application) => {
    setLastAdded(application.uid);
    fetchApplications();
  };

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const deleteApplication = (selectedApplication) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        setSubmitting(true);
        axios
          .delete(`/application_sites/${selectedApplication.uid}`)
          .then((response) => {
            dispatch(SetPolicyChanges(response.data.count_of_changes));
            fetchApplications();
            successToast({
              body: "APPLICATIONS.APPLICATION_DELETE_SUCCESS",
              intl: intl,
            });
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    });
  };

  const updateApplicationName = async () => {
    if (isStartingWithAlphabet(newApplicationName)) {
      var params = {
        application_site: {
          uid: selectedApplication.uid,
          "new-name": newApplicationName,
        },
      };
      setSubmitting(true);
      axios
      .patch(`/application_sites/${selectedApplication.uid}`, params)
      .then((response) => {
        setEditApplicationName(false);
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        fetchApplications();
        successToast({
          body: "APPLICATIONS.APPLICATION_UPDATE_SUCCESS",
          intl: intl,
        });
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
      })
      .finally(() => {
        setSubmitting(false);
      });
    }
  };

  const hideModal = () => {
    setShowModal(false);
    setEditMode(false);
  };

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const getHeader = () => {
    return (
      <>
        <BeSafeContainer visible={editApplicationName}>
          <FormControl
            type="text"
            value={newApplicationName}
            className="w-75 rounded-0 border-top-0 border-right-0 border-left-0 border-primary pl-0"
            onChange={(e) => setApplicationName(e.target.value)}
            ref={applicationNameFocus}
          />
          <div>
            <BeSafeButton
              variant="transparent"
              className="pr-1"
              onClick={updateApplicationName}
              icon="fas fa-check"
              tooltip="GENERAL.SAVE_SMALL"
              disabled={isSubmitting}
            />
            <BeSafeButton
              variant="transparent"
              className="px-2"
              onClick={() => {
                setApplicationName(selectedApplication.name);
                setEditApplicationName(false);
              }}
              icon="fas fa-times"
              tooltip="GENERAL.CANCEL"
              disabled={isSubmitting}
            />
          </div>
        </BeSafeContainer>
        <BeSafeContainer visible={!editApplicationName}>
          <span className="my-auto">
            <i
              className={`${objectDatas["group"].icon} mr-2`}
            ></i>
            {selectedApplication.name}
          </span>
          <div>
            <BeSafeButton
              variant="transparent"
              className="pr-1"
              onClick={() => setEditApplicationName(true)}
              icon="fas fa-pencil-alt"
              tooltip="GENERAL.EDIT_SMALL"
              disabled={isSubmitting}
              visible={!selectedApplication["read-only"] && !isReadOnlyUser && !selectedApplication.lock}
            />
            <BeSafeButton
              variant="transparent"
              className="px-2"
              onClick={() => deleteApplication(selectedApplication)}
              icon="fas fa-trash"
              tooltip="GENERAL.DELETE_SMALL"
              disabled={isSubmitting}
              visible={!selectedApplication["read-only"] && !isReadOnlyUser && !selectedApplication.lock}
            />
            <BeSafeButton
              variant="transparent"
              className="pr-1"
              icon="fas fa-lock"
              tooltip="GENERAL.LOCKED"
              disabled={isSubmitting}
              visible={selectedApplication.lock}
            />
          </div>
        </BeSafeContainer>
      </>
    )
  }

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        createFunction={showCreateApplicationModal}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={applicationsCount}
          items={applications}
          selectedItem={selectedApplication}
          selectItem={selectApplication}
          itemType="application-site-custom"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          buttonsDisable={isSubmitting}
          editFunction={showEditApplicationModal}
          deleteFunction={deleteApplication}
          lastAdded={lastAdded}
        />
      </ObjectExplorerItemsPane>
      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={applicationsCount}
        usedList={usedList}
        header={getHeader()}
      >
        <div className="d-flex justify-content-between font-weight-bold">
          <FormattedMessage id="APPLICATIONS.URL_LIST" />
          <BeSafeButton
            variant="transparent"
            className="py-0"
            onClick={showEditApplicationModal}
            icon="fas fa-plus"
            tooltip="OBJECT_MODAL.ADD"
            visible={!isReadOnlyUser && !selectedApplication.lock}
          />
        </div>
        <ListGroup>
          {selectedApplication["url-list"]?.map((url, index) => (
            <ListGroup.Item
              key={url}
              className="d-flex justify-content-between py-0"
            >
              <span className="w-75 py-2">{url}</span>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </ObjectExplorerDetails>
      <ApplicationFormModal
        showModal={showModal}
        onHide={hideModal}
        editMode={editMode}
        submitHandler={submitHandler}
        selectedApplication={selectedApplication}
      />
    </ObjectExplorerTab>
  );
};

export default CustomApplications;
