import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Sidenav from "../../vendor/libs/sidenav";
import { accountTypes } from "utils/utils";
import { Col, Row } from "react-bootstrap";

const LayoutSidenav = (props) => {

  const layoutSidenavClasses = () => {
    let bg = props.sidenavBg;

    if (
      props.orientation === "horizontal" &&
      (bg.indexOf(" sidenav-dark") !== -1 ||
        bg.indexOf(" sidenav-light") !== -1)
    ) {
      bg = bg
        .replace(" sidenav-dark", "")
        .replace(" sidenav-light", "")
        .replace("-darker", "")
        .replace("-dark", "");
    }

    return (
      `bg-${bg} ` +
      (props.orientation !== "horizontal"
        ? "layout-sidenav"
        : "layout-sidenav-horizontal container-p-x flex-grow-0")
    );
  };

  return (
    <Sidenav
      orientation={props.orientation}
      className={layoutSidenavClasses()}
    >
      <div
        className={`sidenav-inner ${
          props.orientation !== "horizontal" ? "py-1" : ""
        }`}
      >
        {!props.isSuperAdmin && (
          <>
            <Sidenav.RouterLink to="/" exact={true} icon="fas fa-tachometer-alt">
              <FormattedMessage id="SIDEBAR.DASHBOARD" />
            </Sidenav.RouterLink>
            <Sidenav.RouterLink to="/tunnels" exact={true} icon="fas fa-archway">
              <FormattedMessage id="SIDEBAR.TUNNELS" />
            </Sidenav.RouterLink>
            <Sidenav.RouterLink
              to="/policies"
              exact={true}
              icon="fas fa-key"
            >
              <FormattedMessage id="SIDEBAR.ACCESS_CONTROL" />
            </Sidenav.RouterLink>
            <Sidenav.RouterLink
              to="/threat-prevention"
              exact={true}
              icon="fas fa-shield-virus"
            >
              <FormattedMessage id="SIDEBAR.THREAT_PEVENTION" />
            </Sidenav.RouterLink>
            <Sidenav.RouterLink
              to="/ssl-inspection"
              exact={true}
              icon="fas fa-syringe"
            >
              <FormattedMessage id="SIDEBAR.SSL_INSPECTION" />
            </Sidenav.RouterLink>
            <Sidenav.RouterLink
              to="/logs"
              exact={true}
              icon="fas fa-clipboard-list"
            >
              <FormattedMessage id="SIDEBAR.LOGS" />
            </Sidenav.RouterLink>
            {!props.isClient && (
              <Sidenav.RouterLink
                to="/admin"
                exact={true}
                icon="fas fa-users-cog"
              >
                <FormattedMessage id="SIDEBAR.ADMIN" />
              </Sidenav.RouterLink>
            )}
            {props.hasMultiFirewalls && (
              <Sidenav.RouterLink
                to="/firewalls-clusters"
                exact={true}
                icon="fas fa-server"
              >
                <FormattedMessage id="SIDEBAR.FIREWALLS_CLUSTERS" />
              </Sidenav.RouterLink>
            )}
            {props.hasMultiFirewalls && (
              <Sidenav.RouterLink
                to="/licenses"
                exact={true}
                icon="fas fa-award"
              >
                <FormattedMessage id="SIDEBAR.LICENSES" />
              </Sidenav.RouterLink>
            )}
          </>
        )}
        {props.isSuperAdmin && (
          <>
            <Sidenav.RouterLink
              to="/accounts"
              exact={true}
              icon="fas fa-building"
            >
              <FormattedMessage id="SIDEBAR.TENANTS" />
            </Sidenav.RouterLink>
            <Sidenav.RouterLink
              to="/administrators"
              exact={true}
              icon="fas fa-users"
            >
              <FormattedMessage id="SIDEBAR.ADMINISTRATORS" />
            </Sidenav.RouterLink>
            <Sidenav.RouterLink
              to="/maintenance"
              exact={true}
              icon="fas fa-cog"
            >
              <FormattedMessage id="SIDEBAR.MAINTENANCE" />
            </Sidenav.RouterLink>
          </>
        )}
      </div>
      <Row className="text-center w-100 mx-0 p-2">
        <Col className="p-0">
          <img src={`${process.env.PUBLIC_URL}/images/teldat_logo.png`} alt="teldat"  style={{ width: '100%', maxWidth: '90px' }} />
        </Col>
      </Row>
      <Row className="text-center w-100 mx-0 p-2">
        <Col>
          <span className="font-weight-bold">v{process.env.REACT_APP_VERSION}</span>
        </Col>
      </Row>
    </Sidenav>
  );
};

LayoutSidenav.propTypes = {
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
};

LayoutSidenav.defaultProps = {
  orientation: "vertical",
};

export default connect((store) => ({
  sidenavBg: store.theme.sidenavBg,
  isSuperAdmin: store.auth.user?.is_admin,
  hasMultiFirewalls: store.auth.user?.account_type === accountTypes.multifirewall.code,
  isClient: store.auth.user?.roles[0].name === "client",
}))(LayoutSidenav);
