import React from "react";
import { Modal, Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "assets/scss/logs.scss";

const RegexSyntaxModal = (props) => {
  const onHide = () => {
    props.onHide();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={onHide}
      centered
      size="lg"
      dialogClassName="modal-shadow-lg"
    >
      <Modal.Header closeButton className="bg-light-grey">
        <Modal.Title>
          <img
            height="30"
            width="30"
            src={`${process.env.PUBLIC_URL}/images/besafe_logo.png`}
            alt="besafe-corporate"
          />
          <span className="d-none d-md-inline-block ml-1">
            <FormattedMessage id="PATTERNS.REGEX_SYNTAX_TITLE" />
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="vh-75 overflow-auto">
        <div>
          <div>
            <p>
              A quick reference guide for regular expressions (regex), including
              symbols, ranges, grouping, assertions and some sample patterns to
              get you started.
            </p>
            <div>
              <h4>Anchors</h4>
              <Table hover>
                <tbody>
                  <tr>
                    <td>^</td>
                    <td>
                      Start of string, or start of line in multi-line pattern
                    </td>
                  </tr>
                  <tr>
                    <td>\A</td>
                    <td>Start of string</td>
                  </tr>
                  <tr>
                    <td>$</td>
                    <td>End of string</td>
                  </tr>
                  <tr>
                    <td>\b</td>
                    <td>Word boundary</td>
                  </tr>
                  <tr>
                    <td>\B</td>
                    <td>Not word boundary</td>
                  </tr>
                  <tr>
                    <td>\{"<"}</td>
                    <td>Start of word</td>
                  </tr>
                  <tr>
                    <td>\{">"}</td>
                    <td>End of word</td>
                  </tr>
                </tbody>
              </Table>

              <h4>Character Classes</h4>
              <Table hover>
                <tbody>
                  <tr>
                    <td>\c</td>
                    <td>Control character</td>
                  </tr>
                  <tr>
                    <td>\s</td>
                    <td>White space</td>
                  </tr>
                  <tr>
                    <td>\S</td>
                    <td>Not white space</td>
                  </tr>
                  <tr>
                    <td>\d</td>
                    <td>Digit</td>
                  </tr>
                  <tr>
                    <td>\D</td>
                    <td>\Not digit</td>
                  </tr>
                  <tr>
                    <td>\w</td>
                    <td>Word</td>
                  </tr>
                  <tr>
                    <td>\W</td>
                    <td>Not word</td>
                  </tr>
                  <tr>
                    <td>\x</td>
                    <td>Hexadecimal</td>
                  </tr>
                  <tr>
                    <td>\O</td>
                    <td>Octal digit</td>
                  </tr>
                </tbody>
              </Table>

              <h4>Quantifiers</h4>
              <Table hover>
                <tbody>
                  <tr>
                    <td>0</td>
                    <td>0 or more</td>
                    <td>{"{3}"}</td>
                    <td>Exactly 3</td>
                  </tr>
                  <tr>
                    <td>*</td>
                    <td>0 or more</td>
                    <td>{"{3}"}</td>
                    <td>Exactly 3</td>
                  </tr>
                  <tr>
                    <td>+</td>
                    <td>1 or more</td>
                    <td>{"3,"}</td>
                    <td>3 or more</td>
                  </tr>
                  <tr>
                    <td>?</td>
                    <td>0 or 1</td>
                    <td>{"{3,5"}</td>
                    <td>3,4 or 5</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <h4>Escape Sequences</h4>
            <p>
              "­Esc­api­ng" is a way of treating characters which have a special
              meaning in regular expres­sions literally, rather than as special
              charac­ters.
            </p>
            <Table hover>
              <tbody>
                <tr>
                  <td>\</td>
                  <td>Escape following character</td>
                </tr>
                <tr>
                  <td>\Q</td>
                  <td>Begin literal sequence</td>
                </tr>
                <tr>
                  <td>\E</td>
                  <td>End literal sequence</td>
                </tr>
              </tbody>
            </Table>

            <h4>Common Metacharacters</h4>
            <p>The escape character is usually \</p>
            <Table hover>
              <tbody>
                <tr>
                  <td>^</td>
                  <td>[</td>
                  <td>.</td>
                  <td>$</td>
                  <td>{"{"}</td>
                  <td>*</td>
                  <td>(</td>
                  <td>\</td>
                  <td>+</td>
                  <td>)</td>
                  <td>|</td>
                  <td>?</td>
                  <td>{"<"}</td>
                  <td>{">"}</td>
                </tr>
              </tbody>
            </Table>

            <h4>Groups and Ranges</h4>
            <p>Ranges are inclusive</p>
            <Table hover>
              <tbody>
                <tr>
                  <td>.</td>
                  <td>Any character except new line (\n)</td>
                </tr>
                <tr>
                  <td>(a|b)</td>
                  <td>a or b</td>
                </tr>
                <tr>
                  <td>(...)</td>
                  <td>Group</td>
                </tr>
                <tr>
                  <td>(?:...)</td>
                  <td>Passive (non-capturing) group</td>
                </tr>
                <tr>
                  <td>[abc]</td>
                  <td>Range (a or b or c)</td>
                </tr>
                <tr>
                  <td>[^abc]</td>
                  <td>Not (a or b or c)</td>
                </tr>
                <tr>
                  <td>[a-q]</td>
                  <td>Lower case letter from a to q</td>
                </tr>
                <tr>
                  <td>[A-Q]</td>
                  <td>Upper case letter from A to Q</td>
                </tr>
                <tr>
                  <td>[0-7]</td>
                  <td>Digit from 0 to 7</td>
                </tr>
                <tr>
                  <td>\x</td>
                  <td>Group/subpattern number "x"</td>
                </tr>
              </tbody>
            </Table>

            <h4>Some examples</h4>
            <Table hover>
              <tbody>
                <tr>
                  <td>.*\.elmundo\.es</td>
                  <td>Matches URLs like www.elmundo.es</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RegexSyntaxModal;
