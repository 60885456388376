import React, { useState, useEffect, useCallback, useRef, createRef } from "react";
import axios from "axios";
import { Modal, Table, Button, Form, FormControl } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import NetworkFormModal from "./networks/NetworkFormModal";
import NetworkGroupFormModal from "./networks/NetworkGroupFormModal";
import ServiceFormModal from "./services/ServiceFormModal";
import ServiceIcmpFormModal from "./services/ServiceIcmpFormModal";
import ServiceOtherFormModal from "./services/ServiceOtherFormModal";
import DomainFormModal from "./domain/DomainFormModal";
import UserGroupsModal from "./network_users/UserGroupModal";
import PatternFormModal from "./patterns/PatternModal";
import ApplicationFormModal from "./applications/ApplicationModal";
import ApplicationGroupFormModal from "./applications/ApplicationGroupModal";
import { useSelector, shallowEqual } from "react-redux";
import {
  objectDatas,
  isValidIPSubnet,
  removeGroupNameUnderScore,
  isAccessRoleSourceType,
  isLanNetwork,
  isNotLanNetwork,
  isLanGroup,
  LAN_GROUP,
  getAccountType,
  accountTypes,
  cancelRequest
} from "utils/utils";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { successToast, errorToast } from "utils/ToastHelper";
import { useDispatch } from "react-redux";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { WaitTime } from "utils/SearchWaitTime";
import TableLoader from "../common/TableLoader";
import NoDataDisplay from "../common/NoDataDisplay";
import BeSafeButton from "components/common/BeSafeButton";

const ObjectModal = (props) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [tempItems, setTempItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [serviceTitle, setServiceTitle] = useState("");
  const [showNetworkGroupModal, setShowNetworkGroupModal] = useState(false);
  const [showIcmpModal, setShowIcmpModal] = useState(false);
  const [showOtherModal, setShowOtherModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showDomainFormModal, setShowDomainFormModal] = useState(false);
  const [showApplicationFormModal, setShowApplicationFormModal] =
    useState(false);
  const [showApplicationGroupFormModal, setShowApplicationGroupFormModal] =
    useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showPatternModal, setShowPatternModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showLoadingInfo, setShowLoadingInfo] = useState(false);
  const [selectedObject, setSelectedObject] = useState("network");
  const [currentPage, setCurrentPage] = useState(1);
  const [stopFetchingData, setStopFetchingData] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [showNetworkModal, setShowNetworkModal] = useState(false);
  const [disabledSelect, setDisabledSelect] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const [searchTimeout, setSearchTimeout] = useState(0);
  const [tempSearchQuery, setTempSearchQuery] = useState("");

  const readOnlyObjects = [
    "access-role",
    "application-site",
    "application-site-category",
    "threat-protection",
    "files",
  ];
  const addServiceTitles = {
    "service-tcp": "SERVICES.NEW_TCP_SERVICE",
    "service-udp": "SERVICES.NEW_UDP_SERVICE",
  };
  const intl = useIntl();

  const checkboxRefs = useRef([]);

  const { accountType } = useSelector(
    ({ auth }) => ({
      accountType: getAccountType(auth),
    }),
    shallowEqual
  );

  useEffect(() => {
    checkboxRefs.current = items.map((_, i) => checkboxRefs.current[i] ?? createRef());
  }, [items])

  useEffect(() => {
    setItems([]);
    setTempItems([]);
    setLoading(true);
    setSelectedItems(props.selectedItems);
    if (props.allowedObjects.length > 0)
      setSelectedObject(props.allowedObjects[0]);
  }, [props.allowedObjects, props.selectedItems]);

  const fetchItems = useCallback(async () => {
    if (props.showModal) {
      try {
        let objectType =
          selectedObject === "access-role-with-group"
            ? "access-role"
            : selectedObject;

        objectType = objectType === "client_group" ? "group" : objectType;
        
        var res = null;

        if (objectType === "simple-gateway") {
          res = await axios.get("miscs/show_gateways_and_servers");
          if (
            res.data.devices.length === 0 ||
            res.data.devices.length < 100
          ) {
            setStopFetchingData(true);
          }
        } else if (objectType === "threat-protection") {
          res = await axios.get("threat_exceptions/threat_protections", {
            params: {
              search_query: searchQuery,
              page_num: currentPage,
            },
          });
          if (
            res.data.threat_protections.length === 0 ||
            res.data.threat_protections.length < 100
          ) {
            setStopFetchingData(true);
          }
        } else if (objectType === "pattern") {
          res = await axios.get("/patterns", {
            params: { page_num: currentPage },
          });
          if (
            res.data.patterns.length === 0 ||
            res.data.patterns.length < 100
          ) {
            setStopFetchingData(true);
          }
        } else if (
          objectType === "application-site" ||
          objectType === "application-site-custom"
        ) {
          let and_param = [
            {
              not: {
                in: [
                  "comments",
                  objectDatas["application-site-custom"].comment,
                ],
              },
            },
          ];
          if (objectType === "application-site-custom") {
            and_param = [
              {
                in: [
                  "comments",
                  objectDatas["application-site-custom"].comment,
                ],
              },
            ];
          }
          if (searchQuery && searchQuery !== "") {
            and_param.push({ in: ["name", searchQuery] });
          }
          res = await axios.get("/application_sites", {
            params: {
              page_num: currentPage,
              and_param: and_param,
              sort: "ASC",
            },
          });
          if (
            res.data.applications.length === 0 ||
            res.data.applications.length < 100
          ) {
            setStopFetchingData(true);
          }
        } else if (objectType === "application-site-group") {
          res = await axios.get("/application_groups", {
            params: {
              search_query: searchQuery,
              page_num: currentPage,
            },
          });
          if (
            res.data.application_groups.length === 0 ||
            res.data.application_groups.length < 100
          ) {
            setStopFetchingData(true);
          }
        } else if (objectType === "files") {
          res = await axios.get("/patterns/files", {
            params: {
              page_num: currentPage,
            },
          });
          if (
            res.data.patterns_files.length === 0 ||
            res.data.patterns_files.length < 100
          ) {
            setStopFetchingData(true);
          }
        } else {
          res = await axios.get("/miscs/all_objects", {
            params: {
              search_query: searchQuery,
              type: objectType,
              page_num: currentPage,
              network_types: props.networkTypes,
            },
          });
          if (res.data.objects.length === 0 || res.data.objects.length < 100) {
            setStopFetchingData(true);
          }

          if (objectType === "network" && props.networkTypes) {
            res.data.objects = filterNetworkByLanType(
              res.data.objects,
              props.networkTypes
            );
          }

          if (objectType === "group" && props.networkTypes) {
            res.data.objects = getOnlyLanGroups(
              res.data.objects,
              props.networkTypes
            );
          }
        }
        var list = [];
        if (initialLoad) {
          if (objectType === "simple-gateway") {
            list = res.data.devices
                    .filter((device) => device.type !== "checkpoint-host")
                    .map(device => { device.type = 'simple-gateway'; return device });
          } else if (objectType === "threat-protection") {
            list = res.data.threat_protections;
          } else if (objectType === "pattern") {
            list = sortPatterns(res.data.patterns);
          } else if (
            objectType === "application-site" ||
            objectType === "application-site-custom"
          ) {
            list = res.data.applications;
          } else if (objectType === "application-site-group") {
            list = res.data.application_groups
          } else if (objectType === "files") {
            list = res.data.patterns_files
          } else {
            list = res.data.objects;
          }
          if (objectType === "network") {
            list = filterValidNetworks(list, props.networkTypes);
          }
          if (isAccessRoleSourceType(objectType)) {
            list = correctUserGroupNames(list);
          }
          setItems(list);
        } else {
          if (objectType === "threat-protection") {
            list = [...tempItems, ...res.data.threat_protections];
          } else if (objectType === "pattern") {
            list = sortPatterns([...tempItems, ...res.data.patterns]);
          } else if (
            objectType === "application-site" ||
            objectType === "application-site-custom"
          ) {
            list = [...tempItems, ...res.data.applications];
          } else if (objectType === "application-site-group") {
            list = [...tempItems, ...res.data.application_groups]
          } else if (objectType === "files") {
            list = [...tempItems, ...res.data.patterns_files]
          } else {
            list = [...tempItems, ...res.data.objects];
          }
          if (objectType === "network") {
            list = filterValidNetworks(list, props.networkTypes);
          }
          if (isAccessRoleSourceType(objectType)) {
            list = correctUserGroupNames(list);
          }
          setItems(list);
        }
        setDisabledSelect(false);
        setInitialLoad(false);
        setShowLoadingInfo(false);
      } catch (err) {}
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    initialLoad,
    props.showModal,
    searchQuery,
    selectedObject,
    tempItems,
    props.networkTypes,
  ]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems, searchQuery]);

  const sortPatterns = (patterns) => {
    const sortedPatterns = patterns.sort((a, b) => {
      if (a.name > b.name)
        return 1;
      if (a.name < b.name)
        return -1;
      return 0; 
    });

    return sortedPatterns;
  };

  const correctUserGroupNames = (values) => {
    let newValues = values.map(function (policy) {
      policy.name = removeGroupNameUnderScore(policy.name);
      return policy;
    });
    return newValues;
  };

  const selectRows = (item, e) => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      const removedItems = selectedItems.filter((i) => i.uid !== item.uid);
      setSelectedItems(removedItems);
    }
  };

  const filterValidNetworks = (networks, type) => {
    return networks.filter((net) => isValidIPSubnet(net.name, type));
  };

  const filterNetworkByLanType = (networks, lanValue) => {
    if (lanValue === "lan") {
      return networks.filter(isLanNetwork);
    } else {
      return networks.filter(isNotLanNetwork);
    }
  };

  const getOnlyLanGroups = (groups, lanType) => {
    if (accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) {
      if (props.sourceSelected && props.sourceSelected.length > 0) {
        return groups.filter((group) => group.uid === props.sourceSelected[0].uid);
      }
      return groups.filter(isLanGroup);
    }
      
      return groups.filter(isLanGroup);
  };

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);

    if (selectedObject === "pattern") {
      return;
    }

    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setSearchQuery(searchText);
      setTempItems([]);
      setInitialLoad(true);
      setCurrentPage(1);
      setStopFetchingData(false);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const handleSubmit = () => {
    props.changeItems(selectedItems);
    handleHide();
  };

  const handleHide = () => {
    setSelectedItems([]);
    props.onHide();
    setSearchQuery("");
    setTempSearchQuery("");
  };

  const addObject = () => {
    console.log(selectedObject)
    if (selectedObject === "network") {
      setShowNetworkModal(true);
    } else if (selectedObject === "group" || selectedObject === "client_group") {
      setShowNetworkGroupModal(true);
    } else if (selectedObject === "pattern") {
      setShowPatternModal(true);
    } else if (selectedObject === "dns-domain") {
      setShowDomainFormModal(true);
    } else if (selectedObject === "application-site-custom") {
      setShowApplicationFormModal(true);
    } else if (selectedObject === "application-site-group") {
      setShowApplicationGroupFormModal(true);
    } else if (
      selectedObject === "user-group" ||
      selectedObject === "access-role-with-group"
    ) {
      setShowGroupModal(true);
    } else if (selectedObject === "service-icmp") {
      setShowIcmpModal(true);
    } else if (selectedObject === "service-other") {
      setShowOtherModal(true);
    } else {
      setServiceTitle(
        intl.formatMessage({ id: addServiceTitles[selectedObject] })
      );
      setServiceType(selectedObject.split("-")[1]);
      setShowServiceModal(true);
    }
  };
  const changeObject = (e) => {
    cancelRequest();
    setItems([]);
    setTempItems([]);
    setDisabledSelect(true);
    setLoading(true);
    setCurrentPage(1);
    setSelectedObject(e.target.value);
    setStopFetchingData(false);
    setSearchQuery("");
    setTempSearchQuery("");
  };

  const onScroll = (e) => {
    let isBottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (isBottom) {
      if (!stopFetchingData) {
        setCurrentPage(currentPage + 1);
        setTempItems([...items]);
        setShowLoadingInfo(true);
        setDisabledSelect(true);
      }
    }
  };

  const submitHandler = (data) => {
    if (searchQuery === "" || data.name.indexOf(searchQuery) !== -1) {
      let allItems = [...items];
      allItems.unshift(data);
      setItems(allItems);
    }
  };

  const isDisabled = () => {
    return selectedObject === "simple-gateway";
  };

  const deleteNetwork = (e, networkId, index) => {
    e.stopPropagation();

    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.DELETE_SMALL",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: async () => {
        setIsDeleting(true);
            
        const values = [...items];
        values.splice(index, 1);

        axios
          .delete(`/networks/${networkId}`)
          .then((response) => {
            dispatch(SetPolicyChanges(response.data.count_of_changes));
            setItems(values);
            const remainingItems = selectedItems.filter(
              (i) => i.uid !== networkId
            );
            setSelectedItems(remainingItems);
            successToast({
              body: "GENERAL.DELETE_SUCCESS",
              intl: intl,
            });
          })
          .catch((err) => {
            if (err.response.data.already_in_used) {
              errorToast({
                body: "NETWORK_GROUPS.ALREADY_IN_USED",
                intl: intl,
              });
            } else if (err.response?.data?.error !== 'cancelled') {
              errorToast({
                body: err.response.data?.error,
                intl: intl,
              });
            }
          })
          .finally(() => {
            setIsDeleting(false);
          });
      },
    });
  };

  const filteredItems = (items) => {
    if (selectedObject === "pattern" || selectedObject === "files") {
      const filteredItems = items.filter((pattern) => pattern.name.toLowerCase().includes(tempSearchQuery.toLowerCase()));
      return filteredItems.sort((a, b) => a.name.indexOf(tempSearchQuery) < b.name.indexOf(tempSearchQuery) ? -1 : 1);
    }
    return items;
  };

  const selectItemOnClick = (item, index) => {
    checkboxRefs.current[index].current.checked = !checkboxRefs.current[index].current.checked;

    if (checkboxRefs.current[index].current.checked) {
      setSelectedItems([...selectedItems, item]);
    } else {
      const removedItems = selectedItems.filter((i) => i.uid !== item.uid);
      setSelectedItems(removedItems);
    }
  };

  const getItemName = (item) => {
    if (item.type === "network") {
      if (item["nat-settings"]?.["hide-behind"] === "gateway") {
        return `${
          item.name +
          ", " +
          intl.formatMessage({ id: "NETWORK_GROUPS.TYPE" }) +
          ": LAN"
        }`;
      }

      return `${
        item.name +
        ", " +
        intl.formatMessage({ id: "NETWORK_GROUPS.TYPE" }) +
        ": No-LAN"
      }`;

    }
    
    if (item.type === "group") {
      const groupType = item.comments ? item.comments.split(";")[0] : item.comments;
      if (groupType === LAN_GROUP) {
        return `${
          item.name +
          ", " +
          intl.formatMessage({ id: "NETWORK_GROUPS.TYPE" }) +
          ": LAN"
        }`;
      }

      return `${
        item.name +
        ", " +
        intl.formatMessage({ id: "NETWORK_GROUPS.TYPE" }) +
        ": No-LAN"
      }`;
    }

    return item.name;
  };

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={handleHide}
        size="lg"
        centered
        dialogClassName="modal-shadow-lg"
      >
        <Modal.Header className="p-4 py-1 px-2">
          <div className="w-100 d-flex align-items-center">
            <i className="fas fa-search" />
            <FormControl
              type="text"
              className="border-0 mr-auto w-50"
              placeholder={intl.formatMessage({
                id: "GENERAL.PLACEHOLDER_SEARCH",
              })}
              value={tempSearchQuery}
              onChange={(event) => searchData(event)}
            />
            <Form.Group className="mr-2 mb-0">
              <Form.Control
                as="select"
                value={selectedObject}
                onChange={(e) => changeObject(e)}
                disabled={disabledSelect}
              >
                {props.allowedObjects.map((item) => (
                  <option value={item} key={"object-" + item}>
                    {intl.formatMessage({ id: objectDatas[item].text })}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {readOnlyObjects.indexOf(selectedObject) === -1 && (
              <Button onClick={() => addObject()} disabled={isDisabled()}>
                <i className="fas fa-plus mr-1" />
                <FormattedMessage id="OBJECT_MODAL.ADD" />
              </Button>
            )}
          </div>
        </Modal.Header>
        <Modal.Body className="pt-2">
          <TableLoader rows={10} visible={loading}/>
          <NoDataDisplay visible={!loading && items.length <= 0} />
          {!loading && items.length > 0 && (
            <div onScroll={(e) => onScroll(e)} className="mh-50 overflow-auto">
              <Table className="mb-0 table-borderless" hover responsive="lg">
                <tbody>
                  {filteredItems(items).map((item, index) => (
                    <tr key={index}>
                      <td className="border-bottom" onClick={() => selectItemOnClick(item, index)}>
                        <div className="d-flex justify-content-between">
                          <Form.Group className="mb-0 d-inline-block">
                            <Form.Check
                              id={item.uid}
                              className="mb-0"
                              type="checkbox"
                            >
                              <Form.Check.Input
                                type="checkbox"
                                isValid
                                value={selectedItems.some(
                                  (si) => si.uid === item.uid
                                )}
                                checked={selectedItems.some(
                                  (si) => si.uid === item.uid
                                )}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => selectRows(item, e)}
                                ref={checkboxRefs.current[index]}
                              />
                              <Form.Check.Label onClick={(e) => e.stopPropagation()}>
                                <i
                                  className={`${
                                    objectDatas[item.type]
                                      ? objectDatas[item.type].icon
                                      : objectDatas["default"].icon
                                  } mr-1`}
                                ></i>
                                {getItemName(item)}
                                {item.type === "application-site-category" && item.name === "Very Low Risk" ? <div className="badge badge-success ml-2">1</div> : null}
                                {item.type === "application-site-category" && item.name === "Low Risk" ? <div className="badge badge-warning ml-2">2</div> : null}
                                {item.type === "application-site-category" && item.name === "Medium Risk" ? <div className="badge badge-medium ml-2">3</div> : null}
                                {item.type === "application-site-category" && item.name === "High Risk" ? <div className="badge badge-danger ml-2">4</div> : null}
                                {item.type === "application-site-category" && item.name === "Critical Risk" ? <div className="badge badge-very-high ml-2">5</div> : null}
                              </Form.Check.Label>
                            </Form.Check>
                          </Form.Group>
                          {item.type === "network" && (
                            <div className="mr-3">
                              <BeSafeButton
                                variant="transparent"
                                className="p-0"
                                disabled={isDeleting}
                                onClick={(e) => deleteNetwork(e, item.uid, index)}
                                icon="fas fa-trash"
                                tooltip="GENERAL.DELETE_SMALL"
                              />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {showLoadingInfo && (
                <div className="text-center mt-3">
                  <FormattedMessage id="GENERAL.LOADING" />
                  ...
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="py-3">
          <Button variant="transparent" onClick={handleHide}>
            <FormattedMessage id="OBJECT_MODAL.CANCEL" />
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            <i className="fas fa-check mr-2" />
            <FormattedMessage id="GENERAL.SAVE" />
          </Button>
        </Modal.Footer>
      </Modal>
      <NetworkFormModal
        showModal={showNetworkModal}
        onHide={() => setShowNetworkModal(false)}
        submitHandler={submitHandler}
        lanDisabled={props.networkTypes && props.networkTypes !== null}
        initialLanValue={props.networkTypes}
      />
      <NetworkGroupFormModal
        showModal={showNetworkGroupModal}
        onHide={() => setShowNetworkGroupModal(false)}
        networkList={[]}
        submitHandler={submitHandler}
      />
      <ServiceFormModal
        showModal={showServiceModal}
        onHide={() => setShowServiceModal(false)}
        modalTitle={serviceTitle}
        serviceType={serviceType}
        updateServiceList={submitHandler}
      />
      <ServiceIcmpFormModal
        showModal={showIcmpModal}
        onHide={() => setShowIcmpModal(false)}
        modalTitle={intl.formatMessage({ id: "SERVICES.NEW_ICMP_SERVICE" })}
        serviceType={"icmp"}
        updateServiceList={submitHandler}
      />
      <ServiceOtherFormModal
        showModal={showOtherModal}
        onHide={() => setShowOtherModal(false)}
        modalTitle={intl.formatMessage({ id: "SERVICES.NEW_OTHER_SERVICE" })}
        serviceType={"other"}
        updateServiceList={submitHandler}
      />
      <DomainFormModal
        showModal={showDomainFormModal}
        updateDomainList={submitHandler}
        onHide={() => setShowDomainFormModal(false)}
      />
      <UserGroupsModal
        showModal={showGroupModal}
        onHide={() => setShowGroupModal(false)}
        userGroup={null}
        userGroupList={[]}
        editMode={false}
        submitHandler={submitHandler}
        updateUserGroupsList={() => {}}
        appendAccessRole={true}
      />
      <PatternFormModal
        showModal={showPatternModal}
        onHide={() => setShowPatternModal(false)}
        submitHandler={submitHandler}
        regexList={[]}
      />
      <ApplicationFormModal
        showModal={showApplicationFormModal}
        onHide={() => setShowApplicationFormModal(false)}
        editMode={false}
        submitHandler={submitHandler}
      />
      <ApplicationGroupFormModal
        showModal={showApplicationGroupFormModal}
        onHide={() => setShowApplicationGroupFormModal(false)}
        editMode={false}
        submitHandler={submitHandler}
        applicationGroup={null}
        applicationGroupList={[]}
        updateApplicationGroupsList={() => {}}
      />
    </>
  );
};

export default ObjectModal;
