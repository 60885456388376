import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Col } from "react-bootstrap";
import {
  isStartingWithAlphabet,
  objectDatas
} from "../../../utils/utils";
import axios from "axios";
import { useDispatch } from "react-redux";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { successToast, errorToast } from "utils/ToastHelper";
import ObjectModal from "components/object_explorer/ObjectModal";
import { useFormik } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";

const ApplicationGroupModal = (props) => {
  const intl = useIntl();
  const [showObjectModal, setShowObjectModal] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: props.editMode ? props.applicationGroup.name : "",
      tags: props.editMode ? props.applicationGroup.tags : "",
      application: "",
      members: props.editMode ? props.applicationGroupList : [],
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors = {};

      if (!props.editMode && (!values.name || !values.name.trim())) {
        errors.name = intl.formatMessage({ id: "GENERAL.REQUIRED_FIELD" });
      } else if (!props.editMode && !isStartingWithAlphabet(values.name)) {
        errors.name = intl.formatMessage({
          id: "GENERAL.INVALID_OBJECT_NAME",
        });
      }

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      submitHandler(values, setSubmitting);
    },
  });

  const createApplicationGroup = (values, setSubmitting) => {
    axios
      .post("/application_groups", {
        application_group: {
          name: values.name,
          members: values.members.map((n) => n.uid)
        },
      })
      .then((response) => {
        props.submitHandler(response.data.application_group);
        dispatch(SetPolicyChanges(response.data.application_group.count_of_changes));
        successToast({
          body: "APPLICATIONS.GROUP_CREATED_SUCCESS",
          intl: intl,
        });
        setSubmitting(false);
        onHide();
        formik.resetForm();
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const updateApplicationGroup = (values, setSubmitting) => {
    var params = {
      application_group: {
        uid: props.applicationGroup.uid,
        members: values.members.map((n) => n.uid),
      },
    };
    axios
      .patch(`/application_groups/${props.applicationGroup.uid}`, params)
      .then((response) => {
        successToast({
          body: "APPLICATIONS.APPLICATION_UPDATED_SUCCESS",
          intl: intl,
        });
        dispatch(SetPolicyChanges(response.data.application_group.count_of_changes));
        props.submitHandler(response.data["application_group"]);
        onHide();
        setSubmitting(false);
        formik.resetForm();
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const submitHandler = async (values, setSubmitting) => {
    setSubmitting(true);
    props.editMode
      ? updateApplicationGroup(values, setSubmitting)
      : createApplicationGroup(values, setSubmitting);
  };

  const onHide = () => {
    props.onHide();
  };

  return (
    <Modal show={props.showModal} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id={
              props.editMode
                ? "APPLICATIONS.EDIT_GROUP"
                : "APPLICATIONS.NEW_GROUP"
            }
          />
        </Modal.Title>
      </Modal.Header>
      <div>
        <form
          noValidate={true}
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body className="obj-cus-h-35 overflow-auto">
          { props.loading ? (
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <CircularProgress />
                </div>
              ) : (
          <>
            {!props.editMode && (
              <div className="pb-3">
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_GROUPS.NAME" />
                </span>
                <Form.Control
                  type="text"
                  name="name"
                  className="border-secondary"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.name && formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </div>
            )}

            <div>
              <span className="font-weight-bold">
                <FormattedMessage id="APPLICATIONS.APPLICATIONS_LIST" />
              </span>
              <hr className="mb-3 mt-1 border-primary" />

              <Form.Group as={Col}>
                <Form.Label column sm={4} className="">
                  <FormattedMessage id="OBJECT_EXPLORER.APPLICATIONS" />
                </Form.Label>
                <Col sm={12}>
                  <Autocomplete
                    multiple
                    freeSolo
                    disableClearable
                    limitTags={10}
                    forcePopupIcon={true}
                    options={[]}
                    getOptionLabel={(option) => {
                      return (
                        <div>
                          {objectDatas[`${option.type}`] ? (
                            <i
                              className={`${
                                objectDatas[`${option.type}`].icon
                              } mr-2`}
                            ></i>
                          ) : (
                            <i
                              className={`${objectDatas[`default`].icon} mr-2`}
                            ></i>
                          )}
                          {option.name}
                        </div>
                      );
                    }}
                    value={formik.values.members}
                    inputValue=""
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className="border-py-0"
                      />
                    )}
                    popupIcon={
                      <i className="fas fa-plus text-primary fa-xs picker-plus-icon" />
                    }
                    onOpen={() => setShowObjectModal(true)}
                    onInputChange={null}
                    onChange={(e, value) =>
                      formik.setFieldValue("members", value)
                    }
                    disabled={props.isLastPolicy}
                  />
                  {formik.errors.members && (
                    <div className="w-100 text-danger">
                      {formik.errors.members}
                    </div>
                  )}
                </Col>
              </Form.Group>
            </div>
          </>
          )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={formik.isSubmitting || props.loading}
              type="submit"
            >
              <i className="fas fa-check mr-2" />
              <FormattedMessage id="GENERAL.SAVE" />
            </Button>
          </Modal.Footer>
        </form>
      </div>
      <ObjectModal
        showModal={showObjectModal}
        onHide={() => setShowObjectModal(false)}
        selectedItems={formik.values.members}
        changeItems={(value) => formik.setFieldValue("members", value)}
        allowedObjects={["application-site", "application-site-custom"]}
      />
    </Modal>
  );
};

export default ApplicationGroupModal;
