import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Col } from "react-bootstrap";
import {
  isStartingWithAlphabet,
  objectDatas,
  LAN_GROUP,
  NO_LAN_GROUP,
  isLanGroup,
  getAccountType,
  accountTypes
} from "../../../utils/utils";
import axios from "axios";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { successToast, errorToast } from "utils/ToastHelper";
import ObjectModal from "components/object_explorer/ObjectModal";
import { useFormik } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, TextField } from "@material-ui/core";

const NetworkGroupFormModal = (props) => {
  const intl = useIntl();
  const [invalidNetwork, setInvalidNetwork] = useState(false);
  const [showObjectModal, setShowObjectModal] = useState(false);

  const dispatch = useDispatch();

  const { accountType } = useSelector(
    ({ auth }) => ({
      accountType: getAccountType(auth),
    }),
    shallowEqual
  );

  const getNetworkMode = () => {
    if (accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) {
      return "lan";
    }

    if (props.networkGroup && isLanGroup(props.networkGroup)) {
      return "lan";
    }

    return "noLan";
  };

  const formik = useFormik({
    initialValues: {
      name: props.editMode ? props.networkGroup?.name : "",
      ip_address: "",
      networks: props.editMode ? props.networkGroup.members : [],
      lan: getNetworkMode(),
      comments: props.editMode ? props.networkGroup.comments : "",
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors = {};

      if (!props.editMode && (!values.name || !values.name.trim())) {
        errors.name = intl.formatMessage({ id: "GENERAL.REQUIRED_FIELD" });
      } else if (!props.editMode && !isStartingWithAlphabet(values.name)) {
        errors.name = intl.formatMessage({
          id: "GENERAL.INVALID_OBJECT_NAME",
        });
      }

      if (invalidNetwork && !!values.ip_address) {
        errors.ip_address = intl.formatMessage({
          id: "NETWORK_GROUPS.INVALID_NETWORK",
        });
      }

      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      submitHandler(values, setSubmitting);
    },
  });

  const createNetworkGroup = (network_details, setSubmitting) => {
    const groupType = network_details.lan === "lan" ? LAN_GROUP : NO_LAN_GROUP;
    const comments = groupType + network_details.networks.filter(network => network.comments && network.type !== "group").map((n) => ";" + n.comments);
    axios
      .post("/network_groups", {
        network_group: {
          name: network_details.name,
          members: network_details.networks.map((n) => n.uid),
          comments: comments,
        },
      })
      .then((response) => {
        props.submitHandler(response.data.group);
        dispatch(SetPolicyChanges(response.data.networks_count));
        successToast({
          body: (accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) ? "NETWORK_GROUPS.CLIENT_CREATED_SUCCESS" : "NETWORK_GROUPS.GROUP_CREATED_SUCCESS",
          intl: intl,
        });
        setSubmitting(false);
        onHide();
        formik.resetForm();
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const updateNetworkGroup = (network_details, setSubmitting) => {
    const groupType = network_details.lan === "lan" ? LAN_GROUP : NO_LAN_GROUP;
    const comments = groupType + network_details.networks.filter(network => network.comments && network.type !== "group").map((n) => ";" + n.comments);
    var params = {
      network_group: {
        uid: props.networkGroup.uid,
        members: network_details.networks.map((n) => n.uid),
        comments: comments,
      },
    };
    axios
      .patch(`/network_groups/${props.networkGroup.uid}`, params)
      .then((response) => {
        successToast({
          body: (accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) ? "NETWORK_GROUPS.CLIENT_UPDATED_SUCCESS" :"NETWORK_GROUPS.NETWORK_UPDATE_SUCCESS",
          intl: intl,
        });
        dispatch(SetPolicyChanges(response.data.group.count_of_changes));
        props.submitHandler(response.data["group"]);
        onHide();
        setSubmitting(false);
        formik.resetForm();
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const submitHandler = async (network_details, setSubmitting) => {
    setSubmitting(true);
    props.editMode
      ? updateNetworkGroup(network_details, setSubmitting)
      : createNetworkGroup(network_details, setSubmitting);
  };

  const onHide = () => {
    setInvalidNetwork(false);
    formik.resetForm();
    props.onHide();
  };

  const getTitle = () => {
    if (accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code){
      if (props.editMode) {
        return "NETWORK_GROUPS.EDIT_CLIENT";
      }
      return "NETWORK_GROUPS.ADD_CLIENT";
    }

    if (props.editMode) {
      return "NETWORK_GROUPS.EDIT_NETWORKS";
    }
    return "NETWORK_GROUPS.ADD_NETWORK_GROUP";
  };

  return (
    <Modal show={props.showModal} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id={getTitle()} />
        </Modal.Title>
      </Modal.Header>
      <div>
        <form
          noValidate={true}
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body className="obj-cus-h-35 overflow-auto">
          { props.loading ? (
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <CircularProgress />
                </div>
              ) : (
          <>
            {!props.editMode && (
              <div className="pb-3">
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_GROUPS.NAME" />
                </span>
                <Form.Control
                  type="text"
                  name="name"
                  className="border-secondary"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.name && formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </div>
            )}

            <div>
              {accountType !== accountTypes.carrier.code  && accountType !== accountTypes.carrier_cgnat.code && (<Col lg="4" className="text-center my-3">
                <div className="d-flex">
                  <Form.Check
                    id="lan"
                    type="radio"
                    label="LAN"
                    name="lan"
                    className="checkbox-cursor mr-2"
                    value="lan"
                    checked={formik.values.lan === "lan"}
                    onChange={formik.handleChange}
                    disabled={props.editMode}
                    custom
                  />
                  <Form.Check
                    id="noLan"
                    type="radio"
                    label="No LAN"
                    name="lan"
                    className="checkbox-cursor mr-2"
                    value="noLan"
                    checked={formik.values.lan === "noLan"}
                    onChange={formik.handleChange}
                    disabled={props.editMode}
                    custom
                  />
                </div>
              </Col>)}

              <span className="font-weight-bold">
                <FormattedMessage id="NETWORK_GROUPS.NETWORK_IP_ADDRESS" />
              </span>
              <hr className="mb-3 mt-1 border-primary" />

              <Form.Group as={Col}>
                <Form.Label column sm={4} className="">
                  <FormattedMessage id="NETWORK_GROUPS.NETWORK" />
                </Form.Label>
                <Col sm={12}>
                  <Autocomplete
                    multiple
                    freeSolo
                    disableClearable
                    limitTags={10}
                    forcePopupIcon={true}
                    options={[]}
                    getOptionLabel={(option) => {
                      return (
                        <div>
                          {objectDatas[`${option.type}`] ? (
                            <i
                              className={`${
                                objectDatas[`${option.type}`].icon
                              } mr-2`}
                            ></i>
                          ) : (
                            <i
                              className={`${objectDatas[`default`].icon} mr-2`}
                            ></i>
                          )}
                          {option.name}
                        </div>
                      );
                    }}
                    value={formik.values.networks}
                    inputValue=""
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className="border-py-0"
                      />
                    )}
                    popupIcon={
                      <i className="fas fa-plus text-primary fa-xs picker-plus-icon" />
                    }
                    onOpen={() => setShowObjectModal(true)}
                    onInputChange={null}
                    onChange={(e, value) =>
                      formik.setFieldValue("networks", value)
                    }
                    disabled={props.isLastPolicy}
                  />
                  {formik.errors.networks && (
                    <div className="w-100 text-danger">
                      {formik.errors.networks}
                    </div>
                  )}
                </Col>
              </Form.Group>
            </div>
          </>
          )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              disabled={formik.isSubmitting || props.loading}
              type="submit"
            >
              <i className="fas fa-check mr-2" />
              <FormattedMessage id="GENERAL.SAVE" />
            </Button>
          </Modal.Footer>
        </form>
      </div>
      <ObjectModal
        showModal={showObjectModal}
        onHide={() => setShowObjectModal(false)}
        selectedItems={formik.values.networks}
        changeItems={(value) => formik.setFieldValue("networks", value)}
        networkTypes={formik.values.lan}
        allowedObjects={["network", "group"]}
      />
    </Modal>
  );
};

export default NetworkGroupFormModal;
