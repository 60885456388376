import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { isUrlValid, isStartingWithAlphabet } from "../../../utils/utils";
import axios from "axios";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { actions } from "store/ducks/auth.duck";
import { successToast, errorToast } from "utils/ToastHelper";
import { CircularProgress } from "@material-ui/core";
import BeSafeButton from "components/common/BeSafeButton";
import DomainHelpModal from "components/object_explorer/domain/DomainHelpModal";

const DomainFormModal = (props) => {
  const [showDomainHelp, setShowDomainHelp] = useState(false);

  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user
    }),
    shallowEqual
  );

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    const firstTimeVisited = user.first_time_visited;
    if (props.showModal && (typeof firstTimeVisited === 'undefined' || firstTimeVisited === "" || !firstTimeVisited.split(";").includes('domain_form'))) {
      const newFirstTimeVisited = `${user.first_time_visited}domain_form;`
      axios
        .patch(`/users/${user.id}/update_first_time_visit`, {
          first_time_visited: newFirstTimeVisited
        })
        .then(result => {
        })
        .catch((err) => {
          errorToast({ body: err.response.data?.error, intl: intl });
        });
      
      dispatch(actions.setFirstTimeVisit(newFirstTimeVisited));
      setShowDomainHelp(true);
    }
  }, [dispatch, intl, user, props.showModal]);

  const initialValues = {
    name: props.domain?.name || "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name || !values.name.trim()) {
      errors.name = intl.formatMessage({ id: "GENERAL.REQUIRED_FIELD" });
    } else if (values.name.charAt(0) !== ".") {
      errors.name = intl.formatMessage({ id: "DOMAIN.VALIDATION" });
    } else if (!isUrlValid(values.name)) {
      errors.name = intl.formatMessage({ id: "DOMAIN.INVALID_OBJECT" });
    } else if (!isStartingWithAlphabet(values.name.charAt(1))) {
      errors.name = intl.formatMessage({
        id: "GENERAL.INVALID_OBJECT_NAME",
      });
    }
    return errors;
  };

  const onSubmit = (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    if (!props.isEditMode) {
      axios
        .post("/domains", {
          domain: {
            name: values.name,
            "is-sub-domain": false,
          },
        })
        .then((res) => {
          let response = res.data.domain;
          dispatch(SetPolicyChanges(response.count_of_changes));
          response["read-only"] = false;
          props.updateDomainList(response);
          successToast({ body: "GENERAL.SAVE_SUCCESS", intl: intl });
          props.onHide();
          formik.resetForm();
        })
        .catch((err) => {
          errorToast({ body: err.response.data?.error, intl: intl });
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      axios
        .patch(`/domains/${props.domain?.uid}`, {
          domain: {
            uid: props.domain?.uid,
            "new-name": values.name,
            "is-sub-domain": false,
          },
        })
        .then((res) => {
          let response = res.data.domain;
          dispatch(SetPolicyChanges(response.count_of_changes));
          response["read-only"] = false;
          props.updateDomain(response);
          successToast({ body: "GENERAL.UPDATE_SUCCESS", intl: intl });
          props.onHide();
          formik.resetForm();
        })
        .catch((err) => {
          errorToast({ body: err.response.data?.error, intl: intl });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal show={props.showModal} onHide={props.onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id={props.isEditMode ? "DOMAIN.EDIT_DOMAIN" : "DOMAIN.ADD_DOMAIN"}
          />
          <BeSafeButton
            variant="outline-primary"
            className="py-1 px-2 ml-2"
            onClick={() => setShowDomainHelp(true)}
            icon="fas fa-info"
          />
        </Modal.Title>
      </Modal.Header>
      <Form autoComplete="off" onSubmit={formik.handleSubmit}>
        <Modal.Body>
        { props.loading ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <CircularProgress />
            </div>
          ) : (
          <Row className="mb-3">
            <Col sm={1} className="mt-2">
              <i className="fas fa-project-diagram text-xlarge" />
            </Col>
            <Col sm={11}>
              <Form.Control
                type="text"
                name="name"
                className="border-secondary"
                placeholder={intl.formatMessage({
                  id: "DOMAIN.ENTER_OBJECT_NAME",
                })}
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.touched.name && formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={formik.isSubmitting || props.loading}>
            <i className="fas fa-check mr-2" />
            <FormattedMessage
              id={props.isEditMode ? "GENERAL.UPDATE" : "GENERAL.SAVE"}
            />
          </Button>
        </Modal.Footer>
      </Form>
      <DomainHelpModal
        showModal={showDomainHelp}
        onHide={() => setShowDomainHelp(false)}
      />
    </Modal>
  );
};

export default DomainFormModal;
