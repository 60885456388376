import React from "react";

const ObjectExplorerTab = (props) => {
  return (
    <div className="d-flex obj-cus-h-35 py-2">
      { props.children }
    </div>
  );
};

export default ObjectExplorerTab;