import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import NetworkUsersModal from "./NetworkUsersModal";
import {
  isUserReadOnly,
  whereUsed,
  objectDatas,
} from "utils/utils";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { successToast, errorToast } from "utils/ToastHelper";
import BulkUserModal from "./BulkUserModal";
import { WaitTime } from "utils/SearchWaitTime";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import BeSafeButton from "components/common/BeSafeButton";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";

const NetworkUsers = () => {
  const [networkUsers, setNetworkUsers] = useState([]);
  const [networkUsersCount, setNetworkUsersCount] = useState(0);
  const [selectedNetworkUser, setSelectedNetworkUser] = useState({ name: "" });
  const [usedList, setUsedList] = useState([]);

  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);

  const [showBulUserModal, setShowBulUserModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [sort, setSort] = useState("ASC");
  const [lastAdded, setLastAdded] = useState();

  const intl = useIntl();
  const dispatch = useDispatch();

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
    }),
    shallowEqual
  );

  const selectNetworkUser = useCallback(
    async (networkUser) => {
      setPreviewLoading(true);
      axios
        .get(`/network_users/${networkUser.uid}`)
        .then(async (response) => {
          dispatch(
            SetPolicyChanges(response.data.network_user.count_of_changes)
          );
          setSelectedNetworkUser(response.data.network_user);
        })
        .catch((err) => {
          if (err.response?.data?.error !== 'cancelled')
            errorToast({ body: "NETWORK_USERS.SELECT_ERROR", intl: intl });
        });
      let val = await whereUsed(networkUser.uid);
      setUsedList(val);
      setPreviewLoading(false);
    },
    [dispatch, intl]
  );

  const fetchNetworkUsers = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/network_users", {
        params: { page_num: pageNumber, search_query: searchQuery, sort: sort },
      })
      .then((response) => {
        setNetworkUsers(response.data.network_users);
        if (response.data.network_users.length > 0) {
          selectNetworkUser(response.data.network_users[0]);
        } else {
          setPreviewLoading(false);
        }
        setNetworkUsersCount(response.data.network_users_count);
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        setLoading(false);
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "NETWORK_USERS.LOAD_ERROR", intl: intl });
      });
  }, [dispatch, intl, pageNumber, searchQuery, selectNetworkUser, sort]);

  useEffect(() => {
    fetchNetworkUsers();
  }, [fetchNetworkUsers]);

  const submitHandler = (networkUser) => {
    setLastAdded(networkUser.uid);
    fetchNetworkUsers();
  };

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const editNetworkUser = (selectedNetworkUser) => {
    setSelectedNetworkUser(selectedNetworkUser)
    setEditMode(true);
    setShowModal(true);
  };

  const deleteNetworkUser = (selectedNetworkUser) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        axios
          .delete(`/network_users/${selectedNetworkUser.uid}`)
          .then((response) => {
            successToast({ body: "GENERAL.DELETE_SUCCESS", intl: intl });
            fetchNetworkUsers();
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          });
      },
    });
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const openNewForm = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const openfileUploadForm = () => {
    setShowBulUserModal(true);
  };

  const getHeader = () => {
    return (
      <>
        <span className="my-auto">
          <i className={`${objectDatas["users"].icon} mr-2`}></i>
          {selectedNetworkUser.name}
        </span>
        <div>
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            onClick={() => editNetworkUser(selectedNetworkUser)}
            icon="fas fa-pencil-alt"
            tooltip="GENERAL.EDIT_SMALL"
            visible={!selectedNetworkUser["read-only"] && !isReadOnlyUser && !selectedNetworkUser.lock}
          />
          <BeSafeButton
            variant="transparent"
            className="px-2"
            onClick={() => deleteNetworkUser(selectedNetworkUser)}
            icon="fas fa-trash"
            tooltip="GENERAL.DELETE_SMALL"
            visible={!selectedNetworkUser["read-only"] && !isReadOnlyUser && !selectedNetworkUser.lock}
          />
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            icon="fas fa-lock"
            tooltip="GENERAL.LOCKED"
            visible={selectedNetworkUser.lock}
          />
        </div>
      </>
    )
  }

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        createFunction={openNewForm}
        sortFunction={sortData}
        bulkFunction={openfileUploadForm}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={networkUsersCount}
          items={networkUsers}
          selectedItem={selectedNetworkUser}
          selectItem={selectNetworkUser}
          itemType="users"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          editFunction={editNetworkUser}
          deleteFunction={deleteNetworkUser}
          lastAdded={lastAdded}
        />
      </ObjectExplorerItemsPane>
      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={networkUsersCount}
        usedList={usedList}
        header={getHeader()}
      >
        <div>
          <span className="font-weight-bold">
            <FormattedMessage id="USERS.EMAIL" />:{" "}
          </span>
          <span>{selectedNetworkUser.email || "NA"}</span>
        </div>
      </ObjectExplorerDetails>
      <NetworkUsersModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        networkUser={selectedNetworkUser}
        editMode={editMode}
        submitHandler={submitHandler}
        loading={previewLoading}
      />
      <BulkUserModal
        showModal={showBulUserModal}
        onHide={() => setShowBulUserModal(false)}
        submitHandler={fetchNetworkUsers}
      />
    </ObjectExplorerTab>
  );
};

export default NetworkUsers;
