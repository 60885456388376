import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Badge } from "react-bootstrap";
import layoutHelpers from "./helpers";
import { Link } from "react-router-dom";
import Language from "../../i18n/Language";
import { FormattedMessage, injectIntl } from "react-intl";
import ObjectExplorer from "../../components/object_explorer/ObjectExplorer";
import Avatar from "@material-ui/core/Avatar";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { isUserReadOnly, renderTooltip, accountTypes, setupAxios } from "utils/utils";
import axios from "axios";
import { successToast, errorToast, banner } from "utils/ToastHelper";
import {
  SetPolicyChanges,
  SetPublishingStatus,
  SetPublishingAction,
  SetTaskID,
  SetInstallationProgress,
  SetPolicy,
  SetPolicies,
} from "store/ducks/policyChange.duck";
import { SetShowBanner } from "store/ducks/maintenance.duck";
import PolicyExplorer from "components/policies/PolicyExplorer";

const LayoutNavbar = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [activeKey, setActiveKey] = useState(null);

  const isReadOnlyUser = isUserReadOnly({ user: props.user });
  const isSuperUser = props.user !== null ? props.user.is_admin : false;

  const actionPublish = "Publish";
  const actionInstall = "Install";
  const actionVerify = "VerifyPolicy";

  const axiosWithTimeout = axios.create({timeout: 5000});
  setupAxios(axiosWithTimeout);

  const toggleSidenav = (e) => {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
  }

  useEffect(() => {
    if (props.isPublishing && props.publishingAction === actionVerify) {
      checkVerifyPolicyTaskStatus(props.taskID);
    }
    if (props.isPublishing && props.publishingAction === actionInstall) {
      checkInstallPolicyTaskStatus(props.taskID);
    }
    if (props.isPublishing && props.publishingAction === "Discard") {
      discardChanges();
    }
    if (!isSuperUser) {
      loadPolicies();
      loadSystemMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const loadPolicies = () => {
    axios
      .get("/policies", {
        params: { sort: "ASC" },
      })
      .then((response) => {
        props.SetPolicies(response.data.policies);
        if (props.policy.name === "Standard" && !props.policy["installation-targets"]) {
          props.SetPolicy(response.data.policies[0]);
        }
      })
      .catch((err) => {
        errorToast({
          body: err.response.data?.error,
          intl: props.intl,
        });
      })
  };

  const loadSystemMessages = () => {
    if (props.showBanner) {
      axios
      .get("/system_messages")
      .then((response) => {
        const systemMessages = response.data.system_messages;

        systemMessages.forEach(message => {
          banner({
            body: JSON.parse(message.args)[0],
            intl: props.intl,
          });
        });
        props.SetShowBanner(false);
      })
      .catch((err) => {
      })
    }
  };

  const publishPolicy = () => {
    const { intl } = props;
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "NAVBAR.CONFIRM",
      }),
      description: intl.formatMessage({
        id: "NAVBAR.CONFIRM_INSTALL",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        props.SetInstallationProgress(0);
        props.SetPublishingStatus(true);
        props.SetPublishingAction(actionPublish);
        axios
          .post(`/miscs/publish`)
          .then(() => {
            verifyPolicy();
          })
          .catch((err) => {
            errorToast({
              body: err.response.data?.error,
              intl: props.intl,
            });
            props.SetPublishingStatus(false);
            props.SetPublishingAction("");
            checkTunnelsInstallFail();
          });
      },
    });
  };

  const verifyPolicy = () => {
    props.SetTaskID("");
    props.SetPublishingAction(actionVerify);

    const params = {
      "policy-package": props.policy.name
    }
    axios
      .post(`/miscs/verify_policy`, params)
      .then((response) => {
        props.SetTaskID(response.data.task_id);
        checkVerifyPolicyTaskStatus(response.data.task_id);
      });
  };

  const checkVerifyPolicyTaskStatus = (async (task_id) => {
    let status = "in progress";
    let response;
    while (status === "in progress") {
      await new Promise(resolve => setTimeout(resolve, 2000));
      response = await axiosWithTimeout
        .get(`/miscs/task_status`, { params: { task_id } })
        .catch(() => {});

      if (response) {
        status = response.data.status;
      }

      if (response && status !== "failed") {
        props.SetInstallationProgress(Math.floor(response.data.percent * 0.5));
      }
    }

    if (status === "succeeded") {
      installPolicy(props.hasMultiFirewalls);
    } else if (status === "failed") {
      resetPublishingValues();
      checkTunnelsInstallFail();
      errorToast({
        body: response.data.error,
        intl: props.intl,
        hideAfter: 0,
      });
    }
  });

  const installPolicy = (hasMultifirewall) => {
    props.SetPublishingAction(actionInstall);

    const params = {
      "policy-package": props.policy.name
    }

    const uri = hasMultifirewall? `/miscs/install_multifirewall` : `/miscs/install`

    axios.post(uri, params)
      .then((response) => {
        if (response.data.percent) {
          props.SetInstallationProgress(Math.floor(50 + (response.data.percent * 0.5)));
        }
        props.SetTaskID(response.data.task_id);
        checkInstallPolicyTaskStatus(response.data.task_id);
      })
      .catch((error) => {
        resetPublishingValues();
        checkTunnelsInstallFail();
        errorToast({
          body: error.response.data?.error,
          intl: props.intl,
          hideAfter: 0,
        });
      });
  };

  const checkInstallPolicyTaskStatus = (async (task_id) => {
    let status = "in progress";
    let response;
    while (status === "in progress") {
      await new Promise(resolve => setTimeout(resolve, 2000));
      response = await axiosWithTimeout
        .get(`/miscs/task_status`, { params: { task_id } })
        .catch(() => {});

      if (response) {
        status = response.data.status;
      }

      if (response && status === "in progress") {
        props.SetInstallationProgress(Math.floor(50 + (response.data.percent * 0.5)));
      }
    }

    resetPublishingValues();

    if (status === "succeeded") {
      props.SetPolicyChanges(0);
      successToast({
        body: "POLICIES.PUBLISH_SUCCESS",
        intl: props.intl,
      });
      return;
    } else if (status === "failed") {
      checkTunnelsInstallFail();
      errorToast({
        body: response.data.error,
        intl: props.intl,
        hideAfter: 0,
      });
      return;
    }
  });

  const resetPublishingValues = () => {
    props.SetPublishingStatus(false);
    props.SetPublishingAction("");
    props.SetTaskID("");
    props.SetInstallationProgress(0);
  };

  const discardChanges = () => {
    const { intl } = props;
    axios
      .delete(`/miscs/discard`)
      .then((response) => {
        props.SetPolicyChanges(0);
        successToast({ body: "POLICIES.DISCARD_SUCCESS", intl: intl });
      })
      .catch((err) => {
        errorToast({
          body: err.response.data?.error,
          intl: intl,
        });
      })
      .finally(() => {
        props.SetPublishingStatus(false);
        props.SetPublishingAction("");
        loadPolicies()
      });
  };

  const discardConfirmation = () => {
    const { intl } = props;
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "NAVBAR.CONFIRM_DISCARD",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        props.SetPublishingStatus(true);
        props.SetPublishingAction("Discard");
        discardChanges();
      },
    });
  };

  const checkTunnelsInstallFail = async () => {
    axios.post("/miscs/check_tunnels_on_install_fail");
  };

  const changePolicy = (policy) => {
    props.SetPolicy(policy);
  };

  const openManagePolicies = () => {
    setActiveKey("policies");
    setShowPolicyModal(true);
  }

  return (
    <Navbar
      bg={props.navbarBg}
      expand="lg"
      className="layout-navbar align-items-lg-center container-p-x"
    >
      {/* Sidenav toggle */}
      {props.sidenavToggle && (
        <Nav className="align-items-lg-center mr-lg-4">
          <Nav.Item
            as="a"
            className="nav-item nav-link px-0 ml-2 ml-lg-0"
            href="#toggle"
            onClick={toggleSidenav}
          >
            <i className="fas fa-bars text-large align-middle"></i>
          </Nav.Item>
        </Nav>
      )}

      {/* Brand */}
      <Navbar.Brand as={NavLink} to="/">
        <div className="d-flex">
          <img
            height="30"
            width="30"
            src={`${process.env.PUBLIC_URL}/images/besafe_logo.png`}
            alt="besafe-corporate"
          />
          <div className="d-flex flex-column">
            <span className="d-none d-md-inline-block"> beSafe Corporate</span>
            <span className="d-none d-md-inline-block text-right text-sub-title">Teldat</span>
          </div>
        </div>
      </Navbar.Brand>

      {/* Navbar toggle */}
      <Navbar.Toggle />

      <Navbar.Collapse>
        <Nav className="align-items-lg-center ml-auto">
          {!isReadOnlyUser && props.hasMultiFirewalls && 
            !isSuperUser && (
              renderTooltip(
                <NavDropdown
                  className="d-flex-navbar"
                  title={
                    <div className="d-flex align-items-center justify-content-center text-primary">
                      <i className="fas fa-scroll text-big mr-2" />
                        {props.policy.name}
                    </div>
                  }
                >
                  <NavDropdown.Item onClick={() => openManagePolicies()}>
                    <i className="fas fa-cog mr-2" />
                    <FormattedMessage id="NAVBAR.MANAGE_POLICIES" />
                  </NavDropdown.Item>

                {props.policies.map((policy, i) => (
                  <NavDropdown.Item key={"policy_" + i} onClick={() => changePolicy(policy)}>
                    <i className="fas fa-scroll mr-2" />
                    { policy.name }
                  </NavDropdown.Item>
                ))}
              </NavDropdown>,
              <FormattedMessage id="POLICIES.SELECTED_POLICY" />,
              "left"
            )
          )}
          {!isReadOnlyUser &&
            props.changeCount > 0 &&
            !isSuperUser && (
              <NavDropdown
                className="d-flex-navbar"
                title={
                  <div className="d-flex align-items-center justify-content-center text-primary">
                    <i className="fas fa-file-alt text-big mr-2" />
                    <FormattedMessage id="NAVBAR.CHANGES" />
                    <Badge variant="warning" className="ml-1 pb-1">
                      {props.changeCount}
                    </Badge>
                  </div>
                }
              >
                <NavDropdown.Item onClick={publishPolicy}>
                  <i className="fas fa-cloud-download-alt mr-2" />
                  <FormattedMessage id="NAVBAR.INSTALL_POLICY" />
                </NavDropdown.Item>
                <NavDropdown.Item onClick={discardConfirmation}>
                  <i className="fas fa-trash mr-2" />
                  <FormattedMessage id="NAVBAR.DISCARD_CHANGES" />
                </NavDropdown.Item>
              </NavDropdown>
            )}
          {!isSuperUser && (
            <Nav.Item>
              <Nav.Link
                onClick={() => setShowModal(true)}
                className="text-primary"
              >
                <i className="fas fa-cube text-big mr-2" />
                <FormattedMessage id="NAVBAR.OBJECT_EXPLORER" />
              </Nav.Link>
            </Nav.Item>
          )}
          <Language />
          <NavDropdown
            className="d-flex-navbar"
            title={
              <div className="d-flex align-items-center justify-content-center text-primary">
                  <Avatar className="mr-2 avatar-small bg-primary">
                    {props.user.full_name[0]}
                  </Avatar>
                <div className="d-flex align-items-start flex-column text-primary">
                  <span>{props.user.full_name}</span>
                  <span className="text-medium">{props.user.account_name}</span>
                </div>
              </div>
            }
          >
            <NavDropdown.Item as={Link} to="/logout">
              <i className="fas fa-power-off mr-2 text-big" />
              <FormattedMessage id="NAVBAR.LOGOUT" />
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      <ObjectExplorer
        showModal={showModal}
        hideModal={() => {
          setShowModal(false); 
          setActiveKey(null)
        }}
      />
      <PolicyExplorer
        showModal={showPolicyModal}
        hideModal={() => {
          setShowPolicyModal(false); 
          setActiveKey(null)
        }}
        activeKey={activeKey}
      />
    </Navbar>
  );
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool,
};

LayoutNavbar.defaultProps = {
  sidenavToggle: true,
};

const mapDispatchToProps = {
  SetPolicyChanges,
  SetPublishingStatus,
  SetPublishingAction,
  SetTaskID,
  SetInstallationProgress,
  SetPolicies,
  SetPolicy,
  SetShowBanner
};

export default connect(
  (store) => ({
    navbarBg: store.theme.navbarBg,
    user: store.auth.user,
    changeCount: store.policy.changeCount,
    isPublishing: store.policy.isPublishing,
    publishingAction: store.policy.publishingAction,
    taskID: store.policy.taskID,
    policy: store.policy.policy,
    policies: store.policy.policies,
    hasMultiFirewalls: store.auth.user?.account_type === accountTypes.multifirewall.code,
    showBanner: store.maintenance.showBanner
  }),
  mapDispatchToProps
)(injectIntl(LayoutNavbar));
