import React from "react";
import { Badge } from "react-bootstrap";
import { renderTooltip } from "utils/utils";
import "assets/scss/badge.scss";

const Pill = (props) => {
  const renderExpanded = () => {
    if (!props.expandable) return null;

    if (props.expanded) return <i className="fas fa-arrow-down mr-1"></i>;

    return <i className="fas fa-arrow-right mr-1"></i>;
  }

  return (
    renderTooltip(
      <Badge pill variant="secondary" className={`${props.className} px-2 py-1 ${props.ellipsable ? "ellipsable-badge" : ""}`}>
        { renderExpanded()  }
        { props.icon && <i className={"mr-1 " + props.icon}></i> }
        { props.text }
      </Badge>,
      props.tooltip ? props.tooltip : ""
    )
  );
};

Pill.defaultProps = {
  expandable: false,
  expanded: false
};

export default Pill;
