import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button, Row, Col, ListGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { CircularProgress, TextField } from "@material-ui/core";
import {
  renderTooltip,
  isStartingWithAlphabet,
  objectDatas,
  removeGroupNameUnderScore,
} from "utils/utils";
import { errorToast, successToast } from "utils/ToastHelper";

const filter = createFilterOptions();

const UserGroupModal = (props) => {
  const [userGroupList, setUserGroupList] = useState([]);
  const [networkUserList, setNetworkUserList] = useState([]);
  const [userListUids, setUserListUids] = useState([]);

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.showModal) {
      setUserGroupList(props.userGroupList);
      if (!!props.userGroupList && props.userGroupList.length) {
        let vals = props.userGroupList.map((ugl) => ugl.uid);
        setUserListUids(vals);
      }
    }
  }, [props.showModal, props.userGroupList]);

  const fetchNetworkUsers = useCallback(() => {
    if (props.showModal) {
      axios
        .get("/network_users", { params: { sort: "ASC" } })
        .then((response) => {
          setNetworkUserList(response.data.network_users);
          dispatch(SetPolicyChanges(response.data.count_of_changes));
        })
        .catch((err) => {
          if (err.response?.data?.error !== 'cancelled')
            errorToast({ body: "NETWORK_USERS.LOAD_ERROR", intl: intl });
        });
    }
  }, [dispatch, intl, props.showModal]);

  useEffect(() => {
    fetchNetworkUsers();
  }, [fetchNetworkUsers]);

  const correctUserGroupNames = (value) => {
    value.name = removeGroupNameUnderScore(value.name);
    return value;
  };

  const createUserGroup = (values, setSubmitting) => {
    axios
      .post("/user_groups", {
        user_group: {
          name: values.name,
          members: userListUids,
        },
      })
      .then((res) => {
        let response = props.appendAccessRole
          ? correctUserGroupNames(res.data.access_role)
          : res.data.user_group;
        response["read-only"] = false;
        dispatch(SetPolicyChanges(response.count_of_changes));
        successToast({
          body: "USER_GROUPS.GROUP_CREATED_SUCCESS",
          intl: intl,
        });
        onHide();
        setSubmitting(false);
        props.submitHandler(response);
        formik.resetForm();
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const updateUserGroup = (values, setSubmitting) => {
    axios
      .patch(`/user_groups/${props.userGroup.uid}`, {
        user_group: {
          uid: props.userGroup.uid,
          old_name: props.userGroup.name,
          "new-name": values.name,
          members: userListUids,
        },
      })
      .then((res) => {
        let response = res.data.user_group;
        response["read-only"] = false;
        props.updateUserGroupsList(response.members);
        dispatch(SetPolicyChanges(response.count_of_changes));
        successToast({
          body: "NETWORK_USERS.USER_UPDATED_SUCCESS",
          intl: intl,
        });
        onHide();
        setSubmitting(false);
        props.submitHandler(response);
        formik.resetForm();
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  };

  const deleteUser = (index, networkUser) => {
    let temp = [...userGroupList];
    temp.splice(index, 1);
    setUserGroupList(temp);
    temp = userListUids.filter((ul) => ul !== networkUser.uid);
    setUserListUids(temp);
  };

  const addUser = (networkUser, setFieldValue) => {
    let isUserAlreadyExist = userListUids.some((ul) => ul === networkUser.uid);
    if (!!networkUser.uid && !isUserAlreadyExist) {
      setUserGroupList([...userGroupList, networkUser]);
      setUserListUids([...userListUids, networkUser.uid]);
      setFieldValue("user", "");
    }
  };

  const onHide = () => {
    setUserGroupList([]);
    setNetworkUserList([]);
    setUserListUids([]);
    props.onHide();
  };

  const initialValues = {
    name: props.editMode ? props.userGroup.name : "",
    tags: props.editMode ? props.userGroup.tags : "",
    user: "",
    members: props.editMode ? props.userGroupList : [],
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name || !values.name.trim()) {
      errors.name = intl.formatMessage({
        id: "GENERAL.REQUIRED_FIELD",
      });
    } else if (!isStartingWithAlphabet(values.name)) {
      errors.name = intl.formatMessage({
        id: "GENERAL.INVALID_OBJECT_NAME",
      });
    }

    return errors;
  };

  const onSubmit = async (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    props.editMode
      ? updateUserGroup(values, setSubmitting)
      : createUserGroup(values, setSubmitting);
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <Modal show={props.showModal} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id={
                props.editMode
                  ? "USER_GROUPS.EDIT_GROUP"
                  : "USER_GROUPS.NEW_GROUP"
              }
            />
          </Modal.Title>
        </Modal.Header>
        <Form noValidate={true} autoComplete="off" onSubmit={formik.handleSubmit}>
          <Modal.Body className="obj-cus-h-35 overflow-auto">
          { props.loading ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <CircularProgress />
            </div>
          ) : (
          <>
            <Row className="mb-3">
              <Col sm={1} className="mt-2">
                <i className="fas fa-users text-xlarge" />
              </Col>
              <Col sm={11}>
                <Form.Control
                  type="text"
                  name="name"
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: "USER_GROUPS.ENTER_GROUP_NAME",
                  })}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.name && formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Form.Group className="mb-4">
              <span
                className="text-secondary text-big"
                style={{ textTransform: "uppercase" }}
              >
                <FormattedMessage id="USER_GROUPS.USERS_LIST" />
              </span>
              <hr className="mt-0 border-secondary" />
              <Autocomplete
                value={formik.values.user}
                onChange={(event, newValue) => {
                  formik.setFieldValue("user", newValue);
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  return filtered;
                }}
                id="user"
                options={networkUserList}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.name;
                }}
                selectOnFocus
                handleHomeEndKeys
                renderOption={(option) => option.name}
                freeSolo
                renderInput={(params) => (
                  <div className="d-flex">
                    <TextField
                      {...params}
                      variant="outlined"
                      className="input-py-0"
                    />
                    <Button
                      variant="outline-secondary"
                      className="py-0"
                      onClick={() => addUser(formik.values.user, formik.setFieldValue)}
                    >
                      <i className="fa fa-plus" />
                    </Button>
                  </div>
                )}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.user}
              </Form.Control.Feedback>
            </Form.Group>
            {userGroupList.length > 0 && (
              <ListGroup className="mt-3">
                {userGroupList.map((nu, index) => (
                  <ListGroup.Item
                    key={`${nu.uid}-${index}`}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      <i
                        className={`${objectDatas["users"].icon} mr-2`}
                      ></i>
                      <span>{nu.name}</span>
                    </div>
                    <div>
                      {renderTooltip(
                        <Button
                          variant="transparent"
                          className="p-0"
                          onClick={() => deleteUser(index, nu)}
                        >
                          <i className="fas fa-trash" />
                        </Button>,
                        <FormattedMessage id="GENERAL.DELETE_SMALL" />
                      )}
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
            </>
          )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={formik.isSubmitting || props.loading}>
              <i className="fas fa-check mr-2" />
              <FormattedMessage
                id={props.editMode ? "GENERAL.UPDATE" : "GENERAL.SAVE"}
              />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default UserGroupModal;
