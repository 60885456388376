import React from "react";
import { useIntl } from "react-intl";
import { FormControl } from "react-bootstrap";

const SearchBar = (props) => {
  const intl = useIntl();

  return (
    <div className="w-100 d-flex align-items-center">
      <i className="fas fa-search ml-2" />
      <FormControl
        type="text"
        placeholder={intl.formatMessage({
          id: "GENERAL.PLACEHOLDER_SEARCH",
        })}
        className="border-0"
        value={props.query}
        onChange={(event) => props.onSearch(event)}
      />
    </div>
  );
};

export default SearchBar;