import React from "react";
import BeSafeButton from "../../common/BeSafeButton";
import SearchBar from "../../common/SearchBar";
import { useSelector, shallowEqual } from "react-redux";
import {
  isUserReadOnly
} from "utils/utils";

const ObjectExplorerItemsPane = (props) => {

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
    }),
    shallowEqual
  );

  return (
    <div className="d-flex card flex-column w-40 h-100 mr-3">
      <div className="d-flex justify-content-between">
        <SearchBar 
          query={props.searchQuery}
          onSearch={(event) => props.searchData(event)}
        />
        <BeSafeButton
          className="py-0 ml-auto px-1"
          variant="transparent"
          onClick={ props.createFunction }
          icon="fas fa-plus"
          tooltip="OBJECT_MODAL.ADD"
          visible={ !isReadOnlyUser && props.createFunction !== undefined }
        />
        <BeSafeButton
          className="py-0 px-1"
          variant="transparent"
          onClick={ props.bulkFunction }
          icon="fas fa-file-upload"
          tooltip="GENERAL.UPLOAD"
          visible={ !isReadOnlyUser && props.bulkFunction !== undefined }
        />
        <BeSafeButton
          className="py-0 pl-1 pr-2"
          variant="transparent"
          onClick={ () => props.sortFunction(props.sort) }
          icon={props.sort === "ASC" ? "fas fa-sort-alpha-up" : "fas fa-sort-alpha-down"}
          tooltip="GENERAL.SORT"
          visible={ !isReadOnlyUser && props.sortFunction !== undefined }
        />
      </div>
      { props.children }
    </div>
  );
};

export default ObjectExplorerItemsPane;