import axios from 'axios';

export const LOGIN_URL = 'sign_in'
export const LOGOUT_URL = 'sign_out'
export const RO_LOGIN_URL = 'read_only_sign_in'
export const CHECK_LOGGED_IN = 'check_logged_in'
export const REGISTER_URL = "users";
export const PASSWORD_RESET_URL = "password_reset";

export const ME_URL = "users/my_profile";

export function login(email, password) {
  return axios.post(LOGIN_URL, { session: { email, password } });
}

export function logout(delete_all_sessions = false) {
  return axios.delete(LOGOUT_URL, { data: { delete_all_sessions } });
}

export function getCurrentUser() {
  return axios.get(ME_URL);
}

export function readOnlyLogin(email, password) {
  return axios.post(RO_LOGIN_URL, { session: { email, password } });
}

export function checkLoggedIn(email, password) {
  return axios.post(CHECK_LOGGED_IN, { session: { email, password } });
}
