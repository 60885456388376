import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import { connect } from 'react-redux';
import * as i18n from "./i18n"
import * as auth from "../store/ducks/auth.duck"

export const languages = [
  {
    lang: "en",
    name: "English"
  },
  {
    lang: "es",
    name: "Español"
  }
];

const Language = (props) => {
  const { lang, setLanguage } = props
  const currentLanguage = languages.find(x => x.lang === lang)
  return (
    <NavDropdown title={
      <span className="text-primary">
        <img className="mr-2" src={`${process.env.PUBLIC_URL}/images/flags/${currentLanguage.lang}.png`} alt={currentLanguage.lang} />
        {currentLanguage.name}
      </span>
    }>
      {languages.map(language => (
        <NavDropdown.Item
          key={language.lang}
          active={language.lang === currentLanguage.lang}
          onClick={() => setLanguage(language.lang)}
        >
          <img className="mr-2" src={`${process.env.PUBLIC_URL}/images/flags/${language.lang}.png`} alt={language.lang} /> {language.name}
        </NavDropdown.Item>

      ))}
    </NavDropdown>
  )
}

const mapStateToProps = ({ i18n }) => ({ lang: i18n.lang })
const mapDispatchToProps = { ...i18n.actions, ...auth.actions }

export default connect(mapStateToProps, mapDispatchToProps)(Language)
