import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { isValidIP } from "utils/utils";
import axios from "axios";
import { useDispatch } from "react-redux";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { successToast } from "utils/ToastHelper";
import cogoToast from "cogo-toast";
import { CircularProgress } from "@material-ui/core";

const AddressRangeFormModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const submitHandler = async (address_range_obj, setSubmitting) => {
    setSubmitting(true);
    if (isValidIP(address_range_obj.first_ip_address) && isValidIP(address_range_obj.last_ip_address)) {
      if (props.editMode) {
        let ar = {
          uid: props.selectedAddressRange?.uid,
          "new-name": address_range_obj.name,
          "ipv4-address-first": address_range_obj.first_ip_address,
          "ipv4-address-last": address_range_obj.last_ip_address,
        };
        axios
          .patch(`/networks/${props.selectedAddressRange.uid}/update_address_range`, {
            address_range: ar,
          })
          .then((response) => {
            let val = response.data.address_range;
            dispatch(SetPolicyChanges(val.count_of_changes));
            props.submitHandler(val);
            successToast({
              body: "NETWORK_GROUPS.ADDRESS_RANGE_UPDATED_SUCCESS",
              intl: intl,
            });
            setSubmitting(false);
            onHide();
          })
          .catch((err) => {
            cogoToast.error(err.response.data?.error, {
              heading: intl.formatMessage(
                { id: "NETWORK_GROUPS.NETWORK_ERROR" },
                { network_name: ar["new-name"] }
              ),
              bar: { size: "10px" },
              position: "top-right",
            });
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        let ar = {
          name: address_range_obj.name,
          "ipv4-address-first": address_range_obj.first_ip_address,
          "ipv4-address-last": address_range_obj.last_ip_address,
        };
        if (address_range_obj.lan === "lan") {
          ar["nat-settings"] = {
            "hide-behind": "gateway",
            method: "hide",
            "auto-rule": true,
          };
        }
        axios
          .post("/networks/create_address_range", { address_range: ar })
          .then((response) => {
            let val = response.data.address_range;
            dispatch(SetPolicyChanges(val.count_of_changes));
            props.submitHandler(val);
            successToast({
              body: "NETWORK_GROUPS.ADDRESS_RANGE_CREATED_SUCCESS",
              intl: intl,
            });
            setSubmitting(false);
            onHide();
          })
          .catch((err) => {
            cogoToast.error(err.response.data?.error, {
              heading: intl.formatMessage(
                { id: "NETWORK_GROUPS.NETWORK_ERROR" },
                { network_name: ar.name }
              ),
              bar: { size: "10px" },
              position: "top-right",
            });
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    } else {
      setSubmitting(false);
    }
  };

  const onHide = () => {
    props.onHide();
  };

  return (
    <Modal show={props.showModal} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id={"NETWORK_GROUPS.ADD_ADDRESS_RANGE_OBJECT"} />
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          name: props.selectedAddressRange?.name || "",
          first_ip_address: props.selectedAddressRange.first_ip_address || "",
          last_ip_address: props.selectedAddressRange.last_ip_address || ""            
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name || !values.name.trim()) {
            errors.name = intl.formatMessage({
              id: "GENERAL.REQUIRED_FIELD",
            });
          }
          if (
            !isValidIP(values.first_ip_address) ||
            (!values.first_ip_address || !values.first_ip_address.trim())
          ) {
            errors.first_ip_address = intl.formatMessage({
              id: "NETWORK_GROUPS.INVALID_IP_ADDRESS",
            });
          }
          if (
            !isValidIP(values.last_ip_address) ||
            (!values.last_ip_address || !values.last_ip_address.trim())
          ) {
            errors.last_ip_address = intl.formatMessage({
              id: "NETWORK_GROUPS.INVALID_IP_ADDRESS",
            });
          }

          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          submitHandler(values, setSubmitting);
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          handleReset,
        }) => (
          <Form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
            <Modal.Body className="overflow-auto">
            { props.loading ? (
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <CircularProgress />
                </div>
              ) : (
              <>
              <div className="pb-3">
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_GROUPS.NAME" />
                </span>
                <Form.Control
                  type="text"
                  name="name"
                  className="border-secondary"
                  value={values.name}
                  onChange={handleChange}
                  isInvalid={touched.name && errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </div>
              <div className="pb-3">
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_GROUPS.FIRST_IP_ADDRESS" />
                </span>
                <Form.Control
                  type="text"
                  name="first_ip_address"
                  className="border-secondary"
                  value={values.first_ip_address}
                  onChange={handleChange}
                  isInvalid={touched.first_ip_address && errors.first_ip_address}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.first_ip_address}
                </Form.Control.Feedback>
              </div>
              <div className="pb-3">
                <span className="font-weight-bold">
                  <FormattedMessage id="NETWORK_GROUPS.LAST_IP_ADDRESS" />
                </span>
                <Form.Control
                  type="text"
                  name="last_ip_address"
                  className="border-secondary"
                  value={values.last_ip_address}
                  onChange={handleChange}
                  isInvalid={touched.last_ip_address && errors.last_ip_address}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.last_ip_address}
                </Form.Control.Feedback>
              </div>
              </>
            )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="transparent" onClick={onHide}>
                <FormattedMessage id="OBJECT_MODAL.CANCEL" />
              </Button>
              <Button variant="primary" disabled={isSubmitting || props.loading} type="submit">
                <i className="fas fa-check mr-2" />
                <FormattedMessage id="GENERAL.SAVE" />
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddressRangeFormModal;
