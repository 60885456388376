import cogoToast from "cogo-toast";

export const successToast = ({
  title = "GENERAL.SUCCESS",
  body,
  intl,
  hideAfter = 5,
}) => {
  body = !body ? "GENERAL.SUCCESS" : body;
  const { hide } = cogoToast.success(
    intl.formatMessage({ id: body, defaultMessage: body }),
    {
      heading: intl.formatMessage({
        id: title,
      }),
      bar: { size: "10px" },
      hideAfter: hideAfter,
      position: "top-right",
      onClick: () => {
        hide();
      },
    }
  );
};

export const errorToast = ({
  title = "GENERAL.ERROR",
  body,
  intl,
  hideAfter = 5,
}) => {
  if (body !== 'cancelled') {
    body = !body ? "GENERAL.SWW" : body;
    const { hide } = cogoToast.error(
      intl.formatMessage({ id: body, defaultMessage: body }),
      {
        heading: intl.formatMessage({
          id: title,
        }),
        hideAfter: hideAfter,
        bar: { size: "10px" },
        position: "top-right",
        onClick: () => {
          hide();
        },
      }
    );
  }
};

export const warningToast = ({
  title = "GENERAL.WARNING",
  body,
  intl,
  hideAfter = 5,
}) => {
  body = !body ? "GENERAL.SWW" : body;
  const { hide } = cogoToast.warn(
    intl.formatMessage({ id: body, defaultMessage: body }),
    {
      heading: intl.formatMessage({
        id: title,
      }),
      hideAfter: hideAfter,
      bar: { size: "10px" },
      position: "top-right",
      onClick: () => {
        hide();
      },
    }
  );
};

export const banner = ({
  title = "GENERAL.INFO",
  body,
  intl,
  hideAfter = 0,
}) => {
  body = !body ? "GENERAL.SWW" : body;
  const { hide } = cogoToast.info(
    intl.formatMessage({ id: body, defaultMessage: body }),
    {
      heading: intl.formatMessage({
        id: title,
      }),
      hideAfter: hideAfter,
      bar: { size: "10px" },
      position: "top-center",
      onClick: () => {
        hide();
      }
    }
  );
};
