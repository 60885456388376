import React from "react";
import { FormattedMessage } from "react-intl";
import { Card } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { objectDatas } from "utils/utils";
import Pill from "components/common/Pill";

const ObjectExplorerDetails = (props) => {
  return (
    <>
      {props.loading ? (
        <div className="d-flex card justify-content-center align-items-center w-60 h-100 mr3">
          <CircularProgress />
        </div>
      ) : props.itemsCount <= 0 ? (
        <span className="card w-60 h-100 mr-3 p-3 text-center pt-5">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      ) : (
        <Card className="w-60 h-100 mr-3">
          <Card.Header className="py-0">
            <Card.Title className="d-flex justify-content-between py-0 my-2 text-cus-heading">
              {props.header}
            </Card.Title>
          </Card.Header>
          <Card.Body className="overflow-auto">
            {props.children}
          </Card.Body>
          {props.usedList && (
            <Card.Footer className="obj-where-used">
              <p className="font-weight-bold my-0">
                <FormattedMessage id="GENERAL.WHERE_USED" />:
              </p>
              <span>
                {props.usedList.length <= 0 ? (
                  <FormattedMessage id="GENERAL.NOT_IN_USE" />
                ) : (
                  props.usedList.map((used) => (
                    <Pill
                      icon={ objectDatas[used.type] ? objectDatas[used.type].icon : objectDatas["default"].icon }
                      text={ used.name }
                      className="mr-1 mb-1"
                    />
                  ))
                )}
              </span>
            </Card.Footer>
          )}
        </Card>
      )}
    </>
  );
};

export default ObjectExplorerDetails;