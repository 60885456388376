import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Button, Card, Form, ListGroup } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import {
  objectDatas,
} from "utils/utils";
import { useDispatch } from "react-redux";
import { errorToast, successToast } from "utils/ToastHelper";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import TableLoader from "components/common/TableLoader";
import { Modal } from "react-bootstrap";
import BeSafeButton from "components/common/BeSafeButton";
import NoDataDisplay from "components/common/NoDataDisplay";
import BeSafeContainer from "components/common/BeSafeContainer";

const NewUpdatableObjectsModal = (props) => {
  const [updatableObjects, setUpdatableObjects] = useState([]);
  const [updatableObjectsCount, setUpdatableObjectsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [selectedUpdatableObject, setSelectedUpdatableObject] = useState([]);
  const [loadingItems, setLoadingItems] = useState("");
  const [displayedElements, setDisplayedElements] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const dispatch = useDispatch();

  const intl = useIntl();

  const selectUpdatableObject = useCallback((object) => {
    if (object.uid) {
      setPreviewLoading(true);
      axios
        .get(`/updatable_objects/${object.uid}/show_from_repository`)
        .then((response) => {
          setSelectedUpdatableObject(response.data.updatable_object);
          setPreviewLoading(false);
        })
        .catch((error) => {
          errorToast({ body: "UPDATABLE_OBJECTS.ERROR", intl: intl });
        })
        .finally(() => {
          setPreviewLoading(false);
        });
    } else {
      setPreviewLoading(true);
      axios
        .get(`/updatable_objects/${object.name}/show_from_repository`)
        .then((response) => {
          setSelectedUpdatableObject(response.data.updatable_object);
          setPreviewLoading(false);
        })
        .catch((error) => {
          errorToast({ body: "UPDATABLE_OBJECTS.ERROR", intl: intl });
        })
        .finally(() => {
          setPreviewLoading(false);
        });
    }
  }, [intl]);

  const getUpdatableObjects = useCallback(() => {
    setUpdatableObjects([]);
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/updatable_objects/get_first_repository_level")
      .then((response) => {
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        setUpdatableObjects(response.data.objects);
        setUpdatableObjectsCount(response.data.objects.length);
        if (response.data.objects.length > 0){
          selectUpdatableObject(response.data.objects[0])
        }
        setLoading(false);
      })
      .catch((error) => {
        errorToast({ body: "UPDATABLE_OBJECTS.ERROR", intl: intl });
      })
      .finally(() => {
        setLoading(false);
        setPreviewLoading(false);
      });
  }, [dispatch, selectUpdatableObject, intl]);

  useEffect(() => {
    getUpdatableObjects();
  }, [getUpdatableObjects]);

  const displayChildren = (parent) => {
    if (parent.children && parent.children.length <= 0){
      setLoadingItems(parent.name);
      axios
        .get("/updatable_objects/get_children?parent_name=" + parent.name)
        .then((response) => {
          const updatedObjects = [...updatableObjects];
          const index = updatedObjects.findIndex(item => item.name === parent.name);
          updatedObjects[index].children = response.data.objects.children;
          setUpdatableObjects(updatedObjects);

          const path = parent.path  + "/" + parent.name;

          if (selectedObjects.includes(path)) {
            let updatedSelectedObjects = [...selectedObjects];
            includeChildren(updatedObjects[index], updatedSelectedObjects);
            setSelectedObjects(updatedSelectedObjects);
          }
        })
        .catch((error) => {
          errorToast({ body: "UPDATABLE_OBJECTS.ERROR_CHILDREN", intl: intl });
        })
        .finally(() => {
          setLoadingItems("");
        });
    }

    if (displayedElements.includes(parent.name)) {
      const updatedDisplayedElements = displayedElements.filter((element) => element !== parent.name)
      setDisplayedElements(updatedDisplayedElements);
    } else {
      const updatedDisplayedElements = [...displayedElements];
      updatedDisplayedElements.push(parent.name);
      setDisplayedElements(updatedDisplayedElements);
    }
  };

  const createObjects = (paths) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "UPDATABLE_OBJECTS.CONFIRM_CREATE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        const orderedPaths = paths.sort();

        const finalPaths = [];

        orderedPaths.forEach((path) => {
          if (!isParentPathPresent(finalPaths, path)) {
            finalPaths.push(path);
          }
        });

        setLoading(true);
        axios
          .post("/updatable_objects", {paths: finalPaths })
          .then((response) => {
            dispatch(SetPolicyChanges(response.data.count_of_changes));
            onHide();
            props.onSubmit();
            setLoading(false);
            successToast({body: "UPDATABLE_OBJECTS.CREATED_SUCCESS",intl: intl,});
          })
          .catch((error) => {
            errorToast({ body: "UPDATABLE_OBJECTS.ERROR_CREATING", intl: intl });
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  const downloadRepository = () => {
    setLoading(true);
    axios
      .post("/updatable_objects/download_repository")
      .then(() => {
        successToast({body: "UPDATABLE_OBJECTS.REPOSITORY_DOWNLOAD_SUCCESS",intl: intl,});
      })
      .catch((error) => {
        errorToast({ body: "UPDATABLE_OBJECTS.ERROR_DOWNLOADING", intl: intl });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isParentPathPresent = (paths, path) => {
    let parentPath = path.substring(0, path.lastIndexOf("/"));

    if (parentPath === "/Updatable Objects") {
      return false;
    }

    if (paths.includes(parentPath)) {
      return true;
    }
    return isParentPathPresent(paths, parentPath);
  };

  const displayRecursively = (item, n) => {
    if (displayedElements.includes(item.name)) {
      return item.children.map((child) => (
        <>
          <ListGroup.Item
            className="rounded-0 d-flex"
            active={child.name === selectedUpdatableObject.name}
            key={child.name}
            action
            onClick={() => selectUpdatableObject(child)}
        >
          <div style={{"paddingLeft": `${ n * 14}px`}}>
            { child.children && <i style={{width: "12px"}} className={`fas ${displayedElements.includes(child.name) ? "fa-caret-down" : "fa-caret-right"} mr-2`} onClick={() => displayChildren(child)}></i> }
            { !child.children && <i style={{width: "12px"}} className="fas fa-caret-right mr-2 invisible"></i> }
            <Form.Check
              type="checkbox"
              className="mb-0 d-inline-block"
              value={isSelected(child)}
              checked={isSelected(child)}
              onChange={() => selectItem(child)}
              ref={elm => {
                if (elm) {
                    elm.indeterminate = isChildSelected(child);
                }
              }}
            />
            { child.children && child.children.length > 0 ? child.name + ` (${child.children.length})` : child.name }
          </div>
        </ListGroup.Item>
        { displayRecursively(child, n + 2) }
      </>
      ));
    }
  }

  const onHide = () => {
    setSelectedObjects([]);
    setDisplayedElements([]);
    props.onHide();
  };

  const selectItem = (item) => {
    let updatedSelectedObjects = [...selectedObjects];
    const path = item.path  + "/" + item.name;

    if (selectedObjects.includes(path)) {
      updatedSelectedObjects = deselectAncestors(path, updatedSelectedObjects);
      updatedSelectedObjects = deselectSuccessors(item, updatedSelectedObjects);
      updatedSelectedObjects = updatedSelectedObjects.filter((selectedObject) => selectedObject !== path);
    } else {
      updatedSelectedObjects.push(path);
      updatedSelectedObjects = includeChildren(item, updatedSelectedObjects);
      updatedSelectedObjects = includeParentIfAllchildrenSelected(item.path, updatedSelectedObjects);
    }
    setSelectedObjects(updatedSelectedObjects);
  };

  const includeParentIfAllchildrenSelected = (path, updatedSelectedObjects) => {
    const parent = getItemFromPath(path);

    if (parent) {
      if (updatedSelectedObjects.filter((selectedObject) => selectedObject.substring(0, selectedObject.lastIndexOf("/")) === path).length === parent.children.length) {
        updatedSelectedObjects.push(parent.path  + "/" + parent.name);
        updatedSelectedObjects = includeParentIfAllchildrenSelected(parent.path, updatedSelectedObjects);
      }
    }
    return updatedSelectedObjects;
  };

  const includeChildren = (item, updatedSelectedObjects) => {
    if (!item.children) {
      return updatedSelectedObjects;
    }

    item.children.forEach((child) => {
      const childPath = child.path  + "/" + child.name
      updatedSelectedObjects.push(childPath);
      updatedSelectedObjects = includeChildren(child, updatedSelectedObjects);
    });
    return updatedSelectedObjects;
  };

  const deselectSuccessors = (item, updatedSelectedObjects) => {
    if (!item.children) {
      return updatedSelectedObjects;
    }

    item.children.forEach((child) => {
      const childPath = child.path  + "/" + child.name
      updatedSelectedObjects = updatedSelectedObjects.filter((selectedObject) => selectedObject !== childPath);
      updatedSelectedObjects = deselectSuccessors(child, updatedSelectedObjects);
    });
    return updatedSelectedObjects;
  };

  const deselectAncestors = (path, updatedSelectedObjects) => {
    if (path === "") {
      return updatedSelectedObjects;
    }

    const parentPath = path.substring(0, path.lastIndexOf("/"));

    if (updatedSelectedObjects.includes(parentPath)) {
      updatedSelectedObjects = updatedSelectedObjects.filter((selectedObject) => selectedObject !== parentPath);
    }

    updatedSelectedObjects = deselectAncestors(parentPath, updatedSelectedObjects);
    
    return updatedSelectedObjects;
  };

  const getItemFromPath = (path) => {
    const pathParts = path.split("/");
    pathParts.splice(0, 2);

    let parentObject = updatableObjects.find((updatableObject) => updatableObject.name === pathParts[0]);
    pathParts.shift();

    pathParts.forEach((pathPart) => {
      parentObject = parentObject.children.find((updatableObject) => updatableObject.name === pathPart);
    });
    return parentObject;
  };

  const isSelected = (item) => {
    const path = item.path  + "/" + item.name;

    return selectedObjects.includes(path);
  };

  const isChildSelected = (item) => {
    const itemPath = item.path  + "/" + item.name;

    return selectedObjects.filter(s => s.includes(itemPath)).length > 0 && !selectedObjects.includes(itemPath);
  };

  return (
    <Modal show={props.showModal} onHide={onHide} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="UPDATABLE_OBJECTS.NEW_UPDATABLE_OBJECT"/>
          <BeSafeButton
            variant="transparent"
            icon="fas fa-cloud-download-alt"
            tooltip="UPDATABLE_OBJECTS.DOWNLOAD_REPOSITORY"
            onClick={downloadRepository}
            className="btn-lg"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="obj-cus-h-35 overflow-auto object-explorer">
        <div className="d-flex obj-cus-h-35 py-2">
          <div className="d-flex card flex-column w-60 h-100 mr-3">
            <div className="flex-grow-1 overflow-auto">
              <TableLoader visible={loading} /> 
              <BeSafeContainer visible={!loading && updatableObjectsCount <= 0} >
                <NoDataDisplay visible />
                <div className="w-100 text-center">
                  <FormattedMessage id="UPDATABLE_OBJECTS.DOWNLOAD_REPOSITORY_TIP"/>
                </div>
              </BeSafeContainer>
              <BeSafeContainer visible={!loading && updatableObjectsCount > 0} >
                <div className="d-flex flex-column h-100">
                  <ListGroup className="flex-grow-1 overflow-auto">
                    {updatableObjects.map((item) => (
                      <>
                        <ListGroup.Item
                          className="rounded-0 d-flex"
                          active={item.name === selectedUpdatableObject.name}
                          key={item.name}
                          action
                          onClick={() => selectUpdatableObject(item)}
                        >
                          <div>
                            { loadingItems === item.name && <CircularProgress className="mr-2" size={12}></CircularProgress> }
                            { loadingItems !== item.name && item.has_children && <i style={{width: "12px"}} className={`fas ${displayedElements.includes(item.name) ? "fa-caret-down" : "fa-caret-right"} mr-2`} onClick={() => displayChildren(item)}></i> }
                            { !item.has_children && <i style={{width: "12px"}} className="fas fa-caret-right mr-2 invisible"></i> }
                            <Form.Check
                              type="checkbox"
                              className="mb-0 d-inline-block"
                              value={isSelected(item)}
                              checked={isSelected(item)}
                              onChange={() => selectItem(item)}
                              ref={elm => {
                                if (elm) {
                                    elm.indeterminate = isChildSelected(item);
                                }
                              }}
                            />
                            { item.children && item.children.length > 0 ? item.name + ` (${item.children.length})` : item.name }
                          </div>
                        </ListGroup.Item>
                        {displayRecursively(item, 2)}
                      </>
                    ))}
                  </ListGroup>
                </div>
              </BeSafeContainer>
            </div>
          </div>
          <BeSafeContainer visible={previewLoading} >
            <div className="d-flex card justify-content-center align-items-center w-40 h-100 mr-3">
              <CircularProgress />
            </div>
          </BeSafeContainer>
          <BeSafeContainer visible={!previewLoading && updatableObjectsCount > 0} >
            <Card className="w-40 h-100 mr-3">
              <Card.Header className="py-0">
                <Card.Title className="d-flex justify-content-between py-0 my-0 text-cus-heading">
                  <span className="my-auto">
                    <i
                      className={`${objectDatas["updatable-object"].icon} custom-headar-pad mr-2`}
                    ></i>
                    {selectedUpdatableObject.name}
                  </span>
                </Card.Title>
              </Card.Header>
              <Card.Body className="overflow-auto">
                <div>
                  <p dangerouslySetInnerHTML={{__html: selectedUpdatableObject.description}}></p>
                  <a rel="noreferrer" target="_blank" href={selectedUpdatableObject.url}>{selectedUpdatableObject.link}</a>
                </div>
              </Card.Body>
            </Card>
          </BeSafeContainer>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" disabled={loading} type="submit" onClick={() => createObjects(selectedObjects)}>
          <i className="fas fa-check mr-2" />
          <FormattedMessage id="GENERAL.SAVE" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewUpdatableObjectsModal;
