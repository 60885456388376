import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ListGroup } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import {
  isUserReadOnly,
  whereUsed,
  objectDatas,
} from "utils/utils";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { successToast, errorToast } from "utils/ToastHelper";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { WaitTime } from "utils/SearchWaitTime";
import PatternFormModal from "./PatternModal";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import BeSafeButton from "components/common/BeSafeButton";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";

const PatternObjects = () => {
  const [patterns, setPatterns] = useState([]);
  const [patternsCount, setPatternsCount] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(true);

  const [selectedPattern, setSelectedPattern] = useState([]);
  const [usedList, setUsedList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [searchTimeout, setSearchTimeout] = useState(0);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [sort, setSort] = useState("ASC");
  const [lastAdded, setLastAdded] = useState();

  const [filter, setFilter] = useState("");

  const dispatch = useDispatch();
  const intl = useIntl();

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
    }),
    shallowEqual
  );

  const selectPattern = useCallback((pattern) => {
    setPreviewLoading(true);
    setSelectedPattern(pattern);
    getPatternUsedList(pattern.uid);
    setPreviewLoading(false);
  }, []);

  const getPatternUsedList = async (uid) => {
    let val = await whereUsed(uid);
    setUsedList(val);
  };

  const fetchPatterns = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/patterns", { params: { page_num: pageNumber } })
      .then((response) => {
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        setPatterns(response.data.patterns);
        setPatternsCount(response.data.patterns_count);
        setLoading(false);
        if (response.data.patterns.length) {
          selectPattern(response.data.patterns[0]);
          setPatternsCount(response.data.patterns_count);
        } else {
          setPreviewLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "PATTERNS.LOAD_ERROR", intl: intl });
      });
  }, [dispatch, intl, pageNumber, selectPattern]);

  useEffect(() => {
    fetchPatterns();
  }, [fetchPatterns]);

  const showEditPatternModal = (pattern) => {
    setSelectedPattern(pattern);
    setEditMode(true);
    setShowModal(true);
  };
  const showCreatePatternModal = () => {
    setSelectedPattern({});
    setEditMode(false);
    setShowModal(true);
  };

  const submitHandler = (pattern) => {
    setLastAdded(pattern.uid);
    fetchPatterns();
  };

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const deletePattern = (pattern) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        setSubmitting(true);
        axios
          .delete(`/patterns/${pattern.uid}`)
          .then((response) => {
            dispatch(SetPolicyChanges(response.data.count_of_changes));
            fetchPatterns();
            successToast({
              body: "PATTERNS.PATTERN_DELETE_SUCCESS",
              intl: intl,
            });
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          }).finally(() => {
            setSelectedPattern(patterns ? patterns[0] : {});
            setSubmitting(false)
          });
      },
    });
  };

  const hideModal = () => {
    setShowModal(false);
    setEditMode(false);
  };

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setPageNumber(1);
      setFilter(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const filterPatterns = () => {
    if (filter && filter !== "") {
      const filteredPatterns = patterns.filter((pattern) => pattern.name.toLowerCase().includes(filter.toLowerCase()));
      return filteredPatterns.sort((a, b) => a.name.indexOf(filter) < b.name.indexOf(filter) ? -1 : 1);
    }

    const sortedPatterns = patterns?.sort((a, b) => {
      if (a.name > b.name)
        return 1;
      if (a.name < b.name)
        return -1;
      return 0; 
    });

    return sortedPatterns;
  };

  const getHeader = () => {
    return (
      <>
        <span className="my-auto">
          <i className={`${objectDatas["pattern"].icon} mr-2`}></i>
          {selectedPattern.name}
        </span>
        <div>
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            disabled={isSubmitting}
            onClick={() => showEditPatternModal(selectedPattern)}
            icon="fas fa-pencil-alt"
            tooltip="GENERAL.EDIT_SMALL"
            visible={!isReadOnlyUser && !selectedPattern.lock && selectedPattern.hideImplementation === false}
          />
          <BeSafeButton
            variant="transparent"
            className="px-2"
            disabled={isSubmitting}
            onClick={() => deletePattern(selectedPattern)}
            icon="fas fa-trash"
            tooltip="GENERAL.DELETE_SMALL"
            visible={!isReadOnlyUser && !selectedPattern.lock && selectedPattern.hideImplementation === false}
          />
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            disabled={isSubmitting}
            icon="fas fa-lock"
            tooltip="GENERAL.LOCKED"
            visible={selectedPattern.lock}
          />
        </div>
      </>
    )
  }

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        createFunction={showCreatePatternModal}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={patternsCount}
          items={filterPatterns()}
          selectedItem={selectedPattern}
          selectItem={selectPattern}
          itemType="pattern"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          buttonsDisable={isSubmitting}
          editFunction={showEditPatternModal}
          deleteFunction={deletePattern}
          lastAdded={lastAdded}
        />
      </ObjectExplorerItemsPane>

      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={patternsCount}
        usedList={usedList}
        header={getHeader()}
      >
        <div className="d-flex justify-content-between font-weight-bold">
          <FormattedMessage id="PATTERNS.PATTERNS" />
          <BeSafeButton
            variant="transparent"
            className="py-0"
            disabled={isSubmitting}
            onClick={() => showEditPatternModal(selectedPattern)}
            icon="fas fa-plus"
            tooltip="OBJECT_MODAL.ADD"
            visible={!isReadOnlyUser && !selectedPattern.lock && selectedPattern.hideImplementation === false}
          />
        </div>
        <ListGroup>
          {selectedPattern["patterns"]?.map((regex, index) => (
            <ListGroup.Item
              key={regex}
              className="d-flex justify-content-between py-0"
            >
              <span className="w-75 py-2">{regex}</span>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </ObjectExplorerDetails>
      <PatternFormModal
        showModal={showModal}
        onHide={hideModal}
        editMode={editMode}
        submitHandler={submitHandler}
        selectedPattern={selectedPattern}
        regexList={editMode ? selectedPattern?.patterns : []}
        loading={previewLoading}
      />
    </ObjectExplorerTab>
  );
};

export default PatternObjects;
