import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { FormControl } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import {
  isUserReadOnly,
  objectDatas,
  isStartingWithAlphabet,
} from "utils/utils";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { successToast, errorToast } from "utils/ToastHelper";
import { SetPolicyChanges, SetPolicies } from "store/ducks/policyChange.duck";
import { WaitTime } from "utils/SearchWaitTime";
import PolicyModal from "../policies/PolicyModal";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import BeSafeContainer from "components/common/BeSafeContainer";
import BeSafeButton from "components/common/BeSafeButton";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";

const PolicyObjects = () => {
  const [policies, setPolicyList] = useState([]);
  const [policiesCount, setPoliciesCount] = useState(0);
  const [newPolicyName, setPolicyName] = useState("");
  const [editPolicyName, seteditPolicyName] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(true);
  const policyNameFocus = useRef(null);
  const [selectedPolicy, setSelectedPolicy] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [searchTimeout, setSearchTimeout] = useState(0);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [sort, setSort] = useState("ASC");

  const dispatch = useDispatch();
  const intl = useIntl();

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
    }),
    shallowEqual
  );

  const selectPolicy = useCallback((policy) => {
    setPreviewLoading(true);
    setPolicyName(policy.name);
    setSelectedPolicy(policy);
    setPreviewLoading(false);
  }, []);

  const fetchPolicies = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/policies", {
        params: { page_num: pageNumber, sort: sort, search_query: searchQuery },
      })
      .then((response) => {
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        setPolicyList(response.data.policies);
        dispatch(SetPolicies(response.data.policies));
        setPoliciesCount(response.data.policy_count);
        setLoading(false);
        if (response.data.policies.length) {
          selectPolicy(response.data.policies[0]);
        } else {
          setPreviewLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "APPLICATIONS.LOAD_ERROR", intl: intl });
      });
  }, [dispatch, intl, pageNumber, searchQuery, selectPolicy, sort]);

  useEffect(() => {
    fetchPolicies();
  }, [fetchPolicies]);

  useEffect(() => {
    if (editPolicyName && policyNameFocus.current) {
      policyNameFocus.current.focus();
    }
  }, [editPolicyName]);

  const showEditPolicyModal = () => {
    setEditMode(true);
    setShowModal(true);
  };
  const showCreatePolicyModal = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const submitHandler = () => {
    fetchPolicies();
  };

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const deletePolicy = (selectedPolicy) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        setSubmitting(true);
        axios
          .delete(`/policies/${selectedPolicy.uid}`)
          .then((response) => {
            publishChanges("POLICIES.DELETE_SUCCESS");
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    });
  };

  const publishChanges = (message) => {
    axios
      .post(`/miscs/publish`)
      .then((response) => {
        successToast({
          body: message,
          intl: intl,
        });
        fetchPolicies();
        setSubmitting(false);
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setSubmitting(false);
      });
  }

  const updatePolicyName = async () => {
    if (isStartingWithAlphabet(newPolicyName)) {
      var params = {
        policy: {
          uid: selectedPolicy.uid,
          "new-name": newPolicyName,
        },
      };
      setSubmitting(true);
      axios
      .patch(`/policies/${selectedPolicy.uid}`, params)
      .then((response) => {
        seteditPolicyName(false);
        publishChanges("POLICIES.UPDATE_SUCCESS")
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
      })
      .finally(() => {
        setSubmitting(false);
      });
    }
  };

  const hideModal = () => {
    setShowModal(false);
    setEditMode(false);
  };

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const getHeader = () => {
    return (
      <>
        <BeSafeContainer visible={editPolicyName}>
          <FormControl
            type="text"
            value={newPolicyName}
            className="w-75 rounded-0 border-top-0 border-right-0 border-left-0 border-primary pl-0"
            onChange={(e) => setPolicyName(e.target.value)}
            ref={policyNameFocus}
          />
          <div>
            <BeSafeButton
              variant="transparent"
              className="pr-1"
              onClick={updatePolicyName}
              icon="fas fa-check"
              tooltip="GENERAL.SAVE_SMALL"
              disabled={isSubmitting}
            />
            <BeSafeButton
              variant="transparent"
              className="px-2"
              onClick={() => {
                setPolicyName(selectedPolicy.name);
                seteditPolicyName(false);
              }}
              icon="fas fa-times"
              tooltip="GENERAL.CANCEL"
              disabled={isSubmitting}
            />
          </div>
        </BeSafeContainer>
        <BeSafeContainer visible={!editPolicyName}>
          <span className="my-auto">
            <i
              className={`${objectDatas.policy.icon} mr-2`}
            ></i>
            {selectedPolicy.name}
          </span>
          <div>
            <BeSafeButton
              variant="transparent"
              className="pr-1"
              onClick={() => seteditPolicyName(true)}
              icon="fas fa-pencil-alt"
              tooltip="GENERAL.EDIT_SMALL"
              disabled={isSubmitting}
              visible={!selectedPolicy["read-only"] && !isReadOnlyUser && !selectedPolicy.lock}
            />
            <BeSafeButton
              variant="transparent"
              className="px-2"
              onClick={() => deletePolicy(selectedPolicy)}
              icon="fas fa-trash"
              tooltip="GENERAL.DELETE_SMALL"
              disabled={isSubmitting}
              visible={!selectedPolicy["read-only"] && !isReadOnlyUser && !selectedPolicy.lock}
            />
            <BeSafeButton
              variant="transparent"
              className="pr-1"
              icon="fas fa-lock"
              tooltip="GENERAL.LOCKED"
              disabled={isSubmitting}
              visible={selectedPolicy.lock}
            />
          </div>
        </BeSafeContainer>
      </>
    )
  }

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        createFunction={showCreatePolicyModal}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={policiesCount}
          items={policies}
          selectedItem={selectedPolicy}
          selectItem={selectPolicy}
          itemType="policy"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          editFunction={showEditPolicyModal}
          deleteFunction={deletePolicy}
          buttonsDisabled={isSubmitting}
        />
      </ObjectExplorerItemsPane>
      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={policiesCount}
        header={getHeader()}
      >
        <div className="d-flex justify-content-between font-weight-bold">
          <FormattedMessage id="POLICIES.TARGETS" />
          <BeSafeButton
            variant="transparent"
            className="py-0"
            onClick={showEditPolicyModal}
            icon="fas fa-pencil-alt"
            tooltip="OBJECT_MODAL.ADD"
            visible={!isReadOnlyUser && !selectedPolicy.lock}
          />
        </div>
      </ObjectExplorerDetails>
      <PolicyModal
        showModal={showModal}
        onHide={hideModal}
        editMode={editMode}
        submitHandler={submitHandler}
        selectedPolicy={selectedPolicy}
      />
    </ObjectExplorerTab>
  );
};

export default PolicyObjects;
