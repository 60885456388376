import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ListGroup } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import UserGroupsModal from "./UserGroupModal";
import NetworkUsersModal from "./NetworkUsersModal";
import {
  isUserReadOnly,
  whereUsed,
} from "utils/utils";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { successToast, errorToast } from "utils/ToastHelper";
import { WaitTime } from "utils/SearchWaitTime";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import BeSafeButton from "components/common/BeSafeButton";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";

const UserGroups = () => {
  const [userGroups, setUserGroups] = useState([]);
  const [userGroupsCount, setUserGroupsCount] = useState(0);
  const [userGroupList, setUserGroupList] = useState([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState({ name: "" });
  const [selectedGroupUser, setSelectedGroupUser] = useState({ name: "" });
  const [usedList, setUsedList] = useState([]);

  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [groupEditMode, setGroupEditMode] = useState(false);
  const [userEditMode, setUserEditMode] = useState(false);
  const [sort, setSort] = useState("ASC");
  const [lastAdded, setLastAdded] = useState();

  const dispatch = useDispatch();
  const intl = useIntl();

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
    }),
    shallowEqual
  );

  const selectUserGroup = useCallback(
    async (userGroup) => {
      setPreviewLoading(true);
      axios
        .get(`/user_groups/${userGroup.uid}`)
        .then(async (response) => {
          setSelectedUserGroup(response.data.user_group);
          setUserGroupList(response.data.user_group.members);
          await getUserGroupUsedList(userGroup.uid);
          setPreviewLoading(false);
          dispatch(SetPolicyChanges(response.data.user_group.count_of_changes));
        })
        .catch((err) => {});
    },
    [dispatch]
  );

  const fetchUserGroups = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/user_groups", {
        params: { page_num: pageNumber, search_query: searchQuery, sort: sort },
      })
      .then((response) => {
        setUserGroups(response.data.user_groups);
        setLoading(false);
        if (response.data.user_groups.length > 0) {
          selectUserGroup(response.data.user_groups[0]);
        } else {
          setPreviewLoading(false);
        }
        setUserGroupsCount(response.data.user_groups_count);
        dispatch(SetPolicyChanges(response.data.count_of_changes));
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "USER_GROUPS.LOAD_ERROR", intl: intl });
      });
  }, [dispatch, intl, pageNumber, searchQuery, selectUserGroup, sort]);

  useEffect(() => {
    fetchUserGroups();
  }, [fetchUserGroups]);

  const submitHandler = (userGroup) => {
    setLastAdded(userGroup.uid);
    fetchUserGroups();
  };

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const getUserGroupUsedList = async (uid) => {
    let val = await whereUsed(uid);
    setUsedList(val);
  };

  const editUserGroup = (selectedUserGroup) => {
    setSelectedUserGroup(selectedUserGroup);
    setGroupEditMode(true);
    setShowGroupModal(true);
  };

  const deleteUserGroup = (selectedUserGroup) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        axios
          .delete(`/user_groups/${selectedUserGroup.uid}`)
          .then((response) => {
            let vals = userGroups.filter(
              (ng) => ng.uid !== selectedUserGroup.uid
            );
            setUserGroups(vals);
            if (vals.length) selectUserGroup(vals[0]);
            successToast({
              body: "USER_GROUPS.GROUP_DELETED_SUCCESS",
              intl: intl,
            });
            dispatch(SetPolicyChanges(response.data.count_of_changes));
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          });
      },
    });
  };

  const editGroupUser = (selectedUser) => {
    setSelectedGroupUser(selectedUser);
    setUserEditMode(true);
    setShowUserModal(true);
  };

  const deleteGroupUser = (uid) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        let vals = [];
        userGroupList.forEach((ugl) => {
          if (uid !== ugl.uid) vals.push(ugl.uid);
        });
        axios
          .patch(`/user_groups/${selectedGroupUser.uid}`, {
            user_group: {
              uid: selectedUserGroup.uid,
              members: vals,
            },
          })
          .then((response) => {
            successToast({ body: "GENERAL.DELETE_SUCCESS", intl: intl });
            selectUserGroup(selectedUserGroup);
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          });
      },
    });
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const openNewForm = () => {
    setGroupEditMode(false);
    setShowGroupModal(true);
  };

  const getHeader = () => {
    return (
      <>
        <span className="my-auto">
          <i className="fas fa-users mr-2"></i>
          {selectedUserGroup.name}
        </span>
        <div>
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            onClick={() => editUserGroup(selectedUserGroup)}
            icon="fas fa-pencil-alt"
            tooltip="GENERAL.EDIT_SMALL"
            visible={!selectedUserGroup["read-only"] && !isReadOnlyUser && !selectedUserGroup.lock}
          />
          <BeSafeButton
            variant="transparent"
            className="px-2"
            onClick={() => deleteUserGroup(selectedUserGroup)}
            icon="fas fa-trash"
            tooltip="GENERAL.DELETE_SMALL"
            visible={!selectedUserGroup["read-only"] && !isReadOnlyUser && !selectedUserGroup.lock}
          />
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            icon="fas fa-lock"
            tooltip="GENERAL.LOCKED"
            visible={selectedUserGroup.lock}
          />
        </div>
      </>
    )
  }

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        createFunction={openNewForm}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={userGroupsCount}
          items={userGroups}
          selectedItem={selectedUserGroup}
          selectItem={selectUserGroup}
          itemType="user-group"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          editFunction={editUserGroup}
          deleteFunction={deleteUserGroup}
          lastAdded={lastAdded}
        />
      </ObjectExplorerItemsPane>
      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={userGroupsCount}
        usedList={usedList}
        header={getHeader()}
      >
        <div className="d-flex justify-content-between font-weight-bold">
          <FormattedMessage id="USER_GROUPS.USERS_LIST" />
          <BeSafeButton
            variant="transparent"
            className="py-0"
            onClick={() => editUserGroup(selectedUserGroup)}
            icon="fas fa-plus"
            visible={!isReadOnlyUser && !selectedUserGroup.lock}
          />
        </div>
        <ListGroup>
          {userGroupList.length <= 0 ? (
            <span>
              <FormattedMessage id="USER_GROUPS.NO_USERS" />
            </span>
          ) : (
            userGroupList.map((ugl) => (
              <ListGroup.Item
                key={ugl.uid}
                className="d-flex justify-content-between"
              >
                <span>{ugl.name}</span>
                <div>
                  <BeSafeButton
                    variant="transparent"
                    className="p-0 mr-2"
                    onClick={() => editGroupUser(ugl)}
                    icon="fas fa-pencil-alt"
                    tooltip="GENERAL.EDIT_SMALL"
                    visible={!isReadOnlyUser && !selectedUserGroup.lock}
                  />
                  <BeSafeButton
                    variant="transparent"
                    className="p-0"
                    onClick={() => deleteGroupUser(ugl.uid)}
                    icon="fas fa-trash"
                    tooltip="GENERAL.DELETE_SMALL"
                    visible={!isReadOnlyUser && !selectedUserGroup.lock}
                  />
                </div>
              </ListGroup.Item>
            ))
          )}
        </ListGroup>
      </ObjectExplorerDetails>
      <UserGroupsModal
        showModal={showGroupModal}
        onHide={() => setShowGroupModal(false)}
        userGroup={selectedUserGroup}
        userGroupList={groupEditMode ? userGroupList : []}
        editMode={groupEditMode}
        updateUserGroupsList={(userGroups) => setUserGroupList(userGroups)}
        submitHandler={submitHandler}
        loading={previewLoading}
      />
      <NetworkUsersModal
        showModal={showUserModal}
        onHide={() => setShowUserModal(false)}
        networkUser={selectedGroupUser}
        updateNetworkUsersList={(networkUser) =>
          setUserGroupList([...userGroupList, networkUser])
        }
        editMode={userEditMode}
        submitHandler={() => selectUserGroup(selectedUserGroup)}
      />
    </ObjectExplorerTab>
  );
};

export default UserGroups;
