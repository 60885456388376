import React, { useState } from 'react'
import { Card, Form, Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import '../../vendor/styles/pages/authentication.scss'
import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";
import { useFormik } from 'formik';
import { errorToast } from "utils/ToastHelper";

const PasswordEdit = (props) => {
  const intl = useIntl();
  const queryParams = new URLSearchParams(useLocation().search);
  const [resetMsg, setResetMsg] = useState("");
  const headerText = queryParams.get('network-user') == null ? "Enter Your New Account Password" : "Enter Your New Network User Password" ;

  const initialValues = {
    password: '',
    repeatPassword: '',
  };

  const validate = (values) => {
    const errors = {};
    if (!values.password.trim()) {
      errors.password = intl.formatMessage({
        id: "GENERAL.REQUIRED_FIELD",
      });
    } else if (
      queryParams.get('network-user') == null &&
      (values.password.length < 8 ||
      values.password.length > 32)
    ) {
      errors.password = intl.formatMessage({
        id: "NETWORK_USERS.PASSWORD_LENGTH_EXT",
      });
    } else if (
      queryParams.get('network-user') != null &&
      (values.password.length < 4 ||
      values.password.length > 8)
    ) {
      errors.password = intl.formatMessage({
        id: "NETWORK_USERS.PASSWORD_LENGTH",
      });
    }

    if (!values.repeatPassword.trim()) {
      errors.repeatPassword = intl.formatMessage({
        id: "GENERAL.REQUIRED_FIELD",
      });
    } else if (values.password !== values.repeatPassword) {
      errors.repeatPassword = intl.formatMessage({
        id: "NETWORK_USERS.REPEAT_PASSWORD_NOT_EQUAL",
      });
    }
    return errors;
  };

  const onSubmit = (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    const url = queryParams.get('network-user') == null ? "/users/set_password_mail" : "/network_users/set_password_mail" ;
    axios.post(url, { token: queryParams.get('token'), password: values.password }).then(response => {
      setSubmitting(false);
      setResetMsg("USERS.RESET_PASSWORD_SUCCESS");
    }).catch((error) => {
      setSubmitting(false);
      errorToast({
        body: error.response.data?.error,
        intl: intl,
      });
    });

  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="authentication-wrapper authentication-2 ui-bg-cover ui-bg-overlay-container px-4" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/images/login_bg.jpg')` }}>
      <div className="ui-bg-overlay bg-dark opacity-25"></div>

      <div className="authentication-inner py-5">
        <Card>
          <div className="p-4 p-sm-5">
            {/* Logo */}
            <div className="d-flex justify-content-center align-items-center pb-2 mb-4">
              <div className="ui-w-60">
                <div className="w-100 position-relative" style={{ paddingBottom: '54%' }}>
                  <img className="w-100 position-absolute" src={`${process.env.PUBLIC_URL}/images/besafe_logo.png`} alt="logo" />
                </div>
              </div>
            </div>
            {/* / Logo */}

            <h5 className="text-center text-muted font-weight-normal mb-4">{headerText}</h5>

            {resetMsg ? (
              <div role="alert" className="alert alert-success">
                <div className="alert-text"><FormattedMessage id={resetMsg} /></div>
              </div>
            ) : (
              <form
                noValidate={true}
                autoComplete="off"
                onSubmit={formik.handleSubmit}
              >
                <Form.Group controlId="password">
                  <Form.Label>
                    <FormattedMessage id="USERS.PASSWORD" />
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    className="border-secondary"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.password && formik.errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>
                    <FormattedMessage id="USERS.REPEAT_PASSWORD" />
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="repeatPassword"
                    className="border-secondary"
                    value={formik.values.repeatPassword}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.repeatPassword && formik.errors.repeatPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.repeatPassword}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="d-flex justify-content-between align-items-center m-0">
                  <Button variant="primary" type="submit" disabled={formik.isSubmitting}>Reset Password</Button>
                </div>
              </form>
            )}

          </div>
        </Card>
      </div>
    </div>
  )
}

export default PasswordEdit
