import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Card, Button } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import AddressRangeFormModal from "./AddressRangeFormModal";
import {
  renderTooltip,
  isUserReadOnly,
  whereUsed,
  objectDatas,
} from "utils/utils";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { successToast, errorToast, warningToast } from "utils/ToastHelper";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { WaitTime } from "utils/SearchWaitTime";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";

const AddressRanges = () => {
  const [addressRanges, setAddressRanges] = useState([]);
  const [addressRangesCount, setAddressRangesCount] = useState(0);
  const [selectedAddressRange, setSelectedAddressRange] = useState([]);
  const [usedList, setUsedList] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [lastAdded, setLastAdded] = useState();
  const [sort, setSort] = useState("ASC");

  const dispatch = useDispatch();
  const intl = useIntl();

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
    }),
    shallowEqual
  );

  const selectAddressRange = useCallback(
    (addressRange) => {
      setPreviewLoading(true);
      axios
        .get(`/networks/show_address_range`, { params: { name: addressRange.name } })
        .then(async (response) => {
          dispatch(SetPolicyChanges(response.data.address_range.count_of_changes));
          setSelectedAddressRange(response.data.address_range);
          await getAddressRangeUsedList(addressRange.uid);
          setPreviewLoading(false);
        })
        .catch((err) => {
          if (err.response?.data?.error !== 'cancelled')
            errorToast({ body: "NETWORK_GROUPS.ADDRESS_RANGE_SELECT_ERROR", intl: intl });
        });
    },
    [dispatch, intl]
  );

  const fetchAddressRanges = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/networks/address_range_list", {
        params: { page_num: pageNumber, search_query: searchQuery, sort: sort },
      })
      .then((response) => {
        const addressRanges = response.data.address_ranges.sort((a, b) => {
          if (a.name > b.name)
            return sort === "ASC" ? 1 : -1;
          if (a.name < b.name)
            return sort === "ASC" ? -1 : 1;
          return 0; 
        });

        dispatch(SetPolicyChanges(response.data.count_of_changes));
        setAddressRanges(addressRanges);
        setLoading(false);
        if (addressRanges.length) {
          selectAddressRange(addressRanges[0]);
          setAddressRangesCount(response.data.address_ranges_count);
        } else {
          setPreviewLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "NETWORK_GROUPS.ADDRESS_RANGE_LOAD_ERROR", intl: intl });
      });
  }, [dispatch, intl, pageNumber, searchQuery, selectAddressRange, sort]);

  useEffect(() => {
    fetchAddressRanges();
  }, [fetchAddressRanges]);

  const showEditAddressRangeModal = (selectedAddressRange) => {
    setSelectedAddressRange(selectedAddressRange);
    setEditMode(true);
    setShowModal(true);
  };

  const showNewAddressRangeModal = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const hideModal = () => {
    setShowModal(false);
    setEditMode(false);
  };

  const submitHandler = (newAddressRange) => {
    setLastAdded(newAddressRange.uid);
    fetchAddressRanges();
  };

  const deleteAddressRange = async (addressRange) => {
    let newUsedList;
    if (addressRange.uid !== selectedAddressRange.uid) {
      newUsedList = await getAddressRangeUsedList(addressRange.uid);
    }

    if ((typeof newUsedList !== "undefined" && newUsedList.length > 0) || (typeof newUsedList === "undefined" && usedList.length > 0)) {
      warningToast({
        body: "NETWORK_GROUPS.ALREADY_IN_USE",
        intl: intl,
      });
    } else {
      ConfirmationPopup({
        title: intl.formatMessage({
          id: "GENERAL.WARNING",
        }),
        description: intl.formatMessage({
          id: "GENERAL.CONFIRM_DELETE",
        }),
        okLabel: intl.formatMessage({
          id: "GENERAL.OK",
        }),
        cancelLabel: intl.formatMessage({
          id: "GENERAL.CANCEL",
        }),
        okAction: () => {
          axios
            .delete(`/networks/${addressRange.uid}/delete_address_range`)
            .then((response) => {
              let vals = addressRanges.filter(
                (nw) => nw.uid !== addressRange.uid
              );
              dispatch(SetPolicyChanges(response.data.count_of_changes));
              setAddressRanges(vals);
              if (vals.length) selectAddressRange(vals[0]);
              successToast({
                body: "NETWORK_GROUPS.ADDRESS_RANGE_DELETED_SUCCESS",
                intl: intl,
              });
            })
            .catch((err) => {
              if (err.response.data.already_in_used) {
                errorToast({
                  body: "NETWORK_GROUPS.ALREADY_IN_USE",
                  intl: intl,
                });
              } else {
                errorToast({
                  body: err.response.data?.error,
                  intl: intl,
                });
              }
            });
        },
      });
    }
  };

  const getAddressRangeUsedList = async (uid) => {
    let val = await whereUsed(uid);
    setUsedList(val);
    return val;
  };

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  return (
    <div className="d-flex obj-cus-h-35 py-2">
      <ObjectExplorerItemsPane
        searchData={searchData}
        createFunction={showNewAddressRangeModal}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={addressRangesCount}
          items={addressRanges}
          selectedItem={selectedAddressRange}
          selectItem={selectAddressRange}
          itemType="address-range"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          editFunction={showEditAddressRangeModal}
          deleteFunction={deleteAddressRange}
          lastAdded={lastAdded}
        />
      </ObjectExplorerItemsPane>

      {previewLoading ? (
        <div className="d-flex card justify-content-center align-items-center w-60 h-100 mr3">
          <CircularProgress />
        </div>
      ) : addressRanges.length <= 0 ? (
        <span className="card w-60 h-100 mr-3 p-3 text-center pt-5">
          <FormattedMessage id="GENERAL.NO_DATA" />
        </span>
      ) : (
        <Card className="w-60 h-100 mr-3">
          <Card.Header className="py-0">
            <Card.Title className="d-flex justify-content-between py-0 my-0 text-cus-heading">
              <span className="my-auto">
                <i
                  className={`${objectDatas["address-range"].icon} custom-headar-pad mr-2`}
                ></i>
                {selectedAddressRange.name}
              </span>
              {!isReadOnlyUser && !selectedAddressRange.lock && (
                <div>
                  {renderTooltip(
                    <Button
                      variant="transparent"
                      className="pr-1"
                      disabled={false}
                      onClick={() => showEditAddressRangeModal(selectedAddressRange)}
                    >
                      <i className="fas fa-pencil-alt" />
                    </Button>,
                    <FormattedMessage id="GENERAL.EDIT_SMALL" />
                  )}
                  {renderTooltip(
                    <Button
                      variant="transparent"
                      className="px-2"
                      disabled={false}
                      onClick={() => deleteAddressRange(selectedAddressRange)}
                    >
                      <i className="fas fa-trash" />
                    </Button>,
                    <FormattedMessage id="GENERAL.DELETE_SMALL" />
                  )}
                </div>
              )}
            </Card.Title>
          </Card.Header>
          <Card.Body className="overflow-auto">
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="NETWORK_GROUPS.FIRST_IP_ADDRESS" />
              </span>
              <span>{`: ${selectedAddressRange?.first_ip_address}`}</span>
            </div>
            <div className="d-flex py-2">
              <span className="font-weight-bold">
                <FormattedMessage id="NETWORK_GROUPS.LAST_IP_ADDRESS" />
              </span>
              <span>{`: ${selectedAddressRange?.last_ip_address}`}</span>
            </div>
          </Card.Body>
          <Card.Footer className="obj-where-used">
            <p className="font-weight-bold my-0">
              <FormattedMessage id="GENERAL.WHERE_USED" />:
            </p>
            <span>
              {usedList.length <= 0 ? (
                <FormattedMessage id="GENERAL.NOT_IN_USE" />
              ) : (
                usedList.join(", ")
              )}
            </span>
          </Card.Footer>
        </Card>
      )}
      <AddressRangeFormModal
        showModal={showModal}
        onHide={hideModal}
        editMode={editMode}
        selectedAddressRange={editMode ? selectedAddressRange : {}}
        submitHandler={submitHandler}
        loading={previewLoading}
      />
    </div>
  );
};

export default AddressRanges;
