import React from "react";
import { Modal, Tab, Row, Col, Nav } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PolicyObjects from "./PolicyObjects";

const PolicyExplorer = (props) => {
  const hideModal = () => {
    props.hideModal();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={hideModal}
      centered
      scrollable
      className="object-explorer"
      dialogClassName="vw-90"
    >
      <Modal.Header className="py-3 border border-0 bg-light-grey" closeButton>
        <Modal.Title>
          <FormattedMessage id="NAVBAR.POLICY_EXPLORER" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="obj-cus-h-35 p-0 bg-light-grey">
        <Tab.Container id="left-tabs-example" defaultActiveKey={props.activeKey || "networks"}>
          <Row className="obj-cus-h-35 mx-0">
            <Col md={3} sm={12} className="obj-cus-h-35 m-0 px-3 py-2">
              <div className="card h-100 overflow-auto">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link
                      className="pl-2 border-bottom mr-0"
                      eventKey="policies"
                    >
                      <FormattedMessage id="OBJECT_EXPLORER.POLICIES" />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
            <Col md={9} sm={12} className="px-0">
              <Tab.Content>
                <Tab.Pane eventKey="policies" mountOnEnter unmountOnExit>
                  <PolicyObjects />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  );
};

export default PolicyExplorer;
