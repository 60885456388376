import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { ListGroup } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import ApplicationGroupModal from "./ApplicationGroupModal";
import ApplicationFormModal from "./ApplicationModal";
import {
  whereUsed,
} from "utils/utils";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { useDispatch } from "react-redux";
import { successToast, errorToast } from "utils/ToastHelper";
import { WaitTime } from "utils/SearchWaitTime";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import BeSafeButton from "components/common/BeSafeButton";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";
import { isUserReadOnly } from "utils/utils";
import { useSelector, shallowEqual } from "react-redux";

const ApplicationGroups = () => {
  const [applicationGroups, setApplicationGroups] = useState([]);
  const [applicationGroupsCount, setApplicationGroupsCount] = useState(0);
  const [applicationGroupList, setApplicationGroupList] = useState([]);
  const [selectedApplicationGroup, setSelectedApplicationGroup] = useState({ name: "" });
  const [selectedGroupApplication, setSelectedGroupApplication] = useState({ name: "" });
  const [usedList, setUsedList] = useState([]);

  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [groupEditMode, setGroupEditMode] = useState(false);
  const [applicationEditMode, setApplicationEditMode] = useState(false);
  const [sort, setSort] = useState("ASC");
  const [searchQueryChanged, setSearchQueryChanged] = useState(false);
  const [lastAdded, setLastAdded] = useState();

  const dispatch = useDispatch();
  const intl = useIntl();
  const prevSearchQueryRef = useRef();

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth)
    }),
    shallowEqual
  );

  const selectApplicationGroup = useCallback(
    async (applicationGroup) => {
      setPreviewLoading(true);
      axios
        .get(`/application_groups/${applicationGroup.uid}`)
        .then(async (response) => {
          setSelectedApplicationGroup(response.data.application_group);
          setApplicationGroupList(response.data.application_group.members);
          await getApplicationGroupUsedList(applicationGroup.uid);
          setPreviewLoading(false);
          dispatch(SetPolicyChanges(response.data.application_group.count_of_changes));
        })
        .catch((err) => {});
    },
    [dispatch]
  );

  const fetchApplicationGroups = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/application_groups", {
        params: { page_num: pageNumber, search_query: searchQuery, sort: sort },
      })
      .then((response) => {
        if (typeof prevSearchQueryRef.current !== 'undefined' && searchQuery !== prevSearchQueryRef.current) {
          setSearchQueryChanged(true);
          prevSearchQueryRef.current = searchQuery;
        } else {
          setSearchQueryChanged(false);
        }
        setApplicationGroups(response.data.application_groups);
        setLoading(false);
        if (response.data.application_groups.length > 0) {
          selectApplicationGroup(response.data.application_groups[0]);
        } else {
          setPreviewLoading(false);
        }
        setApplicationGroupsCount(response.data.application_groups_count);
        dispatch(SetPolicyChanges(response.data.count_of_changes));
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "APPLICATIONS.LOAD_ERROR", intl: intl });
      });
  }, [dispatch, intl, pageNumber, searchQuery, selectApplicationGroup, sort]);

  useEffect(() => {
    fetchApplicationGroups();
  }, [fetchApplicationGroups]);

  const submitHandler = (applicationGroup) => {
    setLastAdded(applicationGroup.uid);
    fetchApplicationGroups();
  };

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const getApplicationGroupUsedList = async (uid) => {
    let val = await whereUsed(uid);
    setUsedList(val);
  };

  const editApplicationGroup = (selectedApplicationGroup) => {
    setSelectedApplicationGroup(selectedApplicationGroup);
    setGroupEditMode(true);
    setShowGroupModal(true);
  };

  const deleteApplicationGroup = (applicationGroupId) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        axios
          .delete(`/application_groups/${selectedApplicationGroup.uid}`)
          .then((response) => {
            let vals = applicationGroups.filter(
              (ng) => ng.uid !== selectedApplicationGroup.uid
            );
            setApplicationGroups(vals);
            if (vals.length) selectApplicationGroup(vals[0]);
            successToast({
              body: "APPLICATIONS.GROUP_DELETED_SUCCESS",
              intl: intl,
            });
            dispatch(SetPolicyChanges(response.data.count_of_changes));
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          });
      },
    });
  };

  const editApplication = (selectedApplication) => {
    setSelectedGroupApplication(selectedApplication);
    setApplicationEditMode(true);
    setShowApplicationModal(true);
  };

  const deleteGroupApplication = (uid) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        let vals = [];
        applicationGroupList.forEach((ugl) => {
          if (uid !== ugl.uid) vals.push(ugl.uid);
        });
        axios
          .patch(`/application_groups/${selectedGroupApplication.uid}`, {
            application_group: {
              uid: selectedApplicationGroup.uid,
              members: vals,
            },
          })
          .then((response) => {
            successToast({ body: "GENERAL.DELETE_SUCCESS", intl: intl });
            selectApplicationGroup(selectedApplicationGroup);
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          });
      },
    });
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      prevSearchQueryRef.current = searchQuery;
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const openNewForm = () => {
    setGroupEditMode(false);
    setShowGroupModal(true);
  };

  const getHeader = () => {
    return (
      <>
        <span className="my-auto">
          <i className="fas fa-applications mr-2"></i>
          {selectedApplicationGroup.name}
        </span>
        <div>
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            onClick={() => editApplicationGroup(selectedApplicationGroup)}
            icon="fas fa-pencil-alt"
            tooltip="GENERAL.EDIT_SMALL"
            visible={!isReadOnlyUser && !selectedApplicationGroup["read-only"] && !selectedApplicationGroup.lock}
          />
          <BeSafeButton
            variant="transparent"
            className="px-2"
            onClick={() => deleteApplicationGroup(selectedApplicationGroup)}
            icon="fas fa-trash"
            tooltip="GENERAL.DELETE_SMALL"
            visible={!isReadOnlyUser && !selectedApplicationGroup["read-only"] && !selectedApplicationGroup.lock}
          />
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            icon="fas fa-lock"
            tooltip="GENERAL.LOCKED"
            visible={selectedApplicationGroup.lock}
          />
        </div>
      </>
    )
  }

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        createFunction={openNewForm}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={applicationGroupsCount}
          items={applicationGroups}
          selectedItem={selectedApplicationGroup}
          selectItem={selectApplicationGroup}
          itemType="application-site-group"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          editFunction={editApplicationGroup}
          deleteFunction={deleteApplicationGroup}
          searchQueryChanged={searchQueryChanged}
          lastAdded={lastAdded}
        />
      </ObjectExplorerItemsPane>

      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={applicationGroupsCount}
        usedList={usedList}
        header={getHeader()}
      >
        <div className="d-flex justify-content-between font-weight-bold">
          <FormattedMessage id="APPLICATIONS.APPLICATIONS_LIST" />
          <BeSafeButton
            variant="transparent"
            className="py-0"
            onClick={() => editApplicationGroup(selectedApplicationGroup)}
            icon="fas fa-plus"
            visible={!isReadOnlyUser && !selectedApplicationGroup.lock}
          />
        </div>
        <ListGroup>
          {applicationGroupList.length <= 0 ? (
            <span>
              <FormattedMessage id="APPLICATIONS.NO_APPLICATIONS" />
            </span>
          ) : (
            applicationGroupList.map((ugl) => (
              <ListGroup.Item
                key={ugl.uid}
                className="d-flex justify-content-between"
              >
                <span>{ugl.name}</span>
                <BeSafeButton
                  variant="transparent"
                  className="p-0 mr-2"
                  onClick={() => editApplication(ugl)}
                  icon="fas fa-pencil-alt"
                  tooltip="GENERAL.EDIT_SMALL"
                  visible={!isReadOnlyUser && !selectedApplicationGroup.lock && ugl.type !== "application-site"}
                />
                <BeSafeButton
                  variant="transparent"
                  className="p-0 mr-2"
                  onClick={() => deleteGroupApplication(ugl.uid)}
                  icon="fas fa-trash"
                  tooltip="GENERAL.DELETE_SMALL"
                  visible={!isReadOnlyUser && !selectedApplicationGroup.lock}
                />
              </ListGroup.Item>
            ))
          )}
        </ListGroup>
      </ObjectExplorerDetails>
      <ApplicationGroupModal
        showModal={showGroupModal}
        onHide={() => setShowGroupModal(false)}
        applicationGroup={selectedApplicationGroup}
        applicationGroupList={groupEditMode ? applicationGroupList : []}
        editMode={groupEditMode}
        updateApplicationGroupsList={(applicationGroups) => setApplicationGroupList(applicationGroups)}
        submitHandler={submitHandler}
        loading={previewLoading}
      />

      <ApplicationFormModal
        showModal={showApplicationModal}
        onHide={() => setShowApplicationModal(false)}
        networkApplication={selectedGroupApplication}
        updateNetworkApplicationsList={(networkApplication) =>
          setApplicationGroupList([...applicationGroupList, networkApplication])
        }
        editMode={applicationEditMode}
        submitHandler={() => selectApplicationGroup(selectedApplicationGroup)}
      />
    </ObjectExplorerTab>
  );
};

export default ApplicationGroups;
