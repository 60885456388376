import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import axios from "axios";
import { useDispatch } from "react-redux";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { isSpacePreset, isStartingWithAlphabet } from "utils/utils";
import { successToast, errorToast } from "utils/ToastHelper";
import { CircularProgress } from "@material-ui/core";

const ServiceOtherFormModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const initialValues = {
    name: props.isEditMode ? props.service?.name : "",
    ipProtocol: props.isEditMode ? props.service?.["ip-protocol"] : "",
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name || !values.name.trim()) {
      errors.name = intl.formatMessage({ id: "GENERAL.REQUIRED_FIELD" });
    } else if (isSpacePreset(values.name)) {
      errors.name = intl.formatMessage({
        id: "SERVICES.HAS_WHITE_SPACE",
      });
    } else if (!isStartingWithAlphabet(values.name)) {
      errors.name = intl.formatMessage({
        id: "GENERAL.INVALID_OBJECT_NAME",
      });
    }

    if (!values.ipProtocol) {
      errors.ipProtocol = intl.formatMessage({
        id: "GENERAL.REQUIRED_FIELD",
      });
    } else if (values.ipProtocol <= 0 || values.ipProtocol > 255) {
      errors.ipProtocol = intl.formatMessage({
        id: "SERVICES.INVALID_PROTOCOL",
      });
    }

    return errors;
  };

  const onSubmit = (values, { setStatus, setSubmitting }) => {
    setSubmitting(true);
    if (!props.isEditMode) {
      axios
        .post("/services", {
          service: {
            name: values.name,
            "ip-protocol": values.ipProtocol,
            type: props.serviceType,
          },
        })
        .then((res) => {
          let response = res.data.service;
          dispatch(SetPolicyChanges(response.count_of_changes));
          props.updateServiceList(response);
          successToast({
            body: "GENERAL.SAVE_SUCCESS",
            intl: intl,
          });
          props.onHide();
          formik.resetForm();
        })
        .catch((err) => {
          errorToast({ body: err.response.data?.error, intl: intl });
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      axios
        .patch(`/services/${props.service.uid}`, {
          service: {
            uid: props.service.uid,
            "new-name": values.name,
            "ip-protocol": values.ipProtocol,
            type: props.serviceType,
          },
        })
        .then((res) => {
          let response = res.data.service;
          dispatch(SetPolicyChanges(response.count_of_changes));
          props.updateService(response);
          successToast({
            body: "GENERAL.UPDATE_SUCCESS",
            intl: intl,
          });
          props.onHide();
          formik.resetForm();
        })
        .catch((err) => {
          errorToast({ body: err.response.data?.error, intl: intl });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Modal show={props.showModal} onHide={props.onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalTitle}</Modal.Title>
      </Modal.Header>
      <Form noValidate={true} autoComplete="off" onSubmit={formik.handleSubmit}>
        <Modal.Body>
        { props.loading ? (
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <CircularProgress />
            </div>
          ) : (
        <>
          <Row>
            <Col sm={1} className="mt-2">
              <i className="fas fa-exchange-alt text-xlarge" />
            </Col>
            <Col sm={11}>
              <Form.Control
                type="text"
                name="name"
                className="border-secondary"
                placeholder={intl.formatMessage({
                  id: "NETWORK_GROUPS.ENTER_OBJECT_NAME",
                })}
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={formik.touched.name && formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Row>
          <hr />

          <span className="text-secondary text-big">
            <FormattedMessage id="SERVICES.MATCH_BY" />
          </span>
          <hr className="mt-0 border-secondary" />
          <Form.Group as={Row} controlId="ip-protocol">
            <Form.Label column sm={5} className="pl-4">
              <FormattedMessage id="SERVICES.IP_PROTOCOL" />
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                type="number"
                name="ipProtocol"
                className="border-secondary"
                value={formik.values.ipProtocol}
                onChange={formik.handleChange}
                isInvalid={formik.touched.ipProtocol && formik.errors.ipProtocol}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.ipProtocol}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
        )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
            <i className="fas fa-check mr-2" />
            <FormattedMessage
              id={props.isEditMode ? "GENERAL.UPDATE" : "GENERAL.SAVE"}
            />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ServiceOtherFormModal;
