import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useIntl } from "react-intl";
import {
  isUserReadOnly,
  whereUsed,
  objectDatas,
} from "utils/utils";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { successToast, errorToast } from "utils/ToastHelper";
import { WaitTime } from "utils/SearchWaitTime";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import NewUpdatableObjectsModal from "./NewUpdatableObjectsModal";
import BeSafeButton from "components/common/BeSafeButton";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";

const UpdatableObjects = () => {
  const [updatableObjects, setUpdatableObjects] = useState([]);
  const [updatableObjectsCount, setUpdatableObjectsCount] = useState(0);
  const [selectedUpdatableObject, setSelectedUpdatableObjects] = useState({ name: "" });
  const [usedList, setUsedList] = useState([]);

  const [openCreateObjectModal, setOpenCreateObjectModal] = useState(false);

  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState("ASC");

  const intl = useIntl();
  const dispatch = useDispatch();

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
    }),
    shallowEqual
  );

  const selectUpdatableObject = useCallback((updatableObject) => {
    setPreviewLoading(true);
    setSelectedUpdatableObjects(updatableObject);
    getUpdatableObjectUsedList(updatableObject.uid);
    setPreviewLoading(false);
  }, []);

  const getUpdatableObjectUsedList = async (uid) => {
    let val = await whereUsed(uid);
    setUsedList(val);
  };

  const fetchUpdatableObjects = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/updatable_objects", {
        params: { page_num: pageNumber, sort: sort, search_query: searchQuery },
      })
      .then((response) => {
        setUpdatableObjects(response.data.updatable_objects);
        setLoading(false);
        setUpdatableObjectsCount(response.data.total);
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        if (response.data.updatable_objects.length) {
          selectUpdatableObject(response.data.updatable_objects[0]);
        }
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "UPDATABLE_OBJECTS.ERROR", intl: intl });
      });
  }, [dispatch, intl, pageNumber, searchQuery, selectUpdatableObject, sort]);

  useEffect(() => {
    fetchUpdatableObjects();
  }, [fetchUpdatableObjects]);

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const deleteUpdatableObject = (selectedUpdatableObject) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        axios
          .delete(`/updatable_objects/${selectedUpdatableObject.uid}`)
          .then((response) => {
            successToast({ body: "GENERAL.DELETE_SUCCESS", intl: intl });
            fetchUpdatableObjects();
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          });
      },
    });
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const hideModal = () => {
    setOpenCreateObjectModal(false);
  };

  const onSubmit = () => {
    fetchUpdatableObjects();
  };
  
  const getHeader = () => {
    return (
      <>
        <span className="my-auto">
          <i
            className={`${objectDatas["updatable-object"].icon} mr-2`}
          ></i>
          {selectedUpdatableObject.name}
        </span>
        <BeSafeButton
          variant="transparent"
          className="px-2" 
          onClick={() => deleteUpdatableObject(selectedUpdatableObject)}
          icon="fas fa-trash"
          tooltip="GENERAL.DELETE_SMALL"
          visible={!selectedUpdatableObject["read-only"] && !isReadOnlyUser && !selectedUpdatableObject.lock}
        />
      </>
    )
  }

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
        createFunction={setOpenCreateObjectModal}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={updatableObjectsCount}
          items={updatableObjects}
          selectedItem={selectedUpdatableObject}
          selectItem={selectUpdatableObject}
          itemType="service-other"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          deleteFunction={deleteUpdatableObject}
        />
      </ObjectExplorerItemsPane>
      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={updatableObjectsCount}
        usedList={usedList}
        header={getHeader()}
      >
        <div>
          <p dangerouslySetInnerHTML={{__html: selectedUpdatableObject.description}}></p>
          <a rel="noreferrer" target="_blank" href={selectedUpdatableObject.url}>{selectedUpdatableObject.link}</a>
        </div>
      </ObjectExplorerDetails>
      <NewUpdatableObjectsModal 
        showModal={openCreateObjectModal}
        onHide={hideModal}
        onSubmit={onSubmit}
      />
    </ObjectExplorerTab>
  );
};

export default UpdatableObjects;
