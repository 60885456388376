import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import ServiceFormModal from "./ServiceFormModal";
import ServiceIcmpFormModal from "./ServiceIcmpFormModal";
import ServiceOtherFormModal from "./ServiceOtherFormModal";
import {
  isUserReadOnly,
  whereUsed,
  objectDatas,
} from "utils/utils";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { successToast, errorToast } from "utils/ToastHelper";
import { WaitTime } from "utils/SearchWaitTime";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import BeSafeButton from "components/common/BeSafeButton";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";

const ServiceObjects = (props) => {
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [services, setServices] = useState([]);
  const [usedList, setUsedList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [sort, setSort] = useState("ASC");
  const [serviceCount, setServiceCount] = useState(0);
  const [lastAdded, setLastAdded] = useState();
  const intl = useIntl();
  const dispatch = useDispatch();
  let serviceDatas = {
    port: "PORT",
    comments: "COMMENTS",
    "icmp-type": "ICMP_TYPE",
    "icmp-code": "ICMP_CODE",
    "ip-protocol": "IP_PROTOCOL",
  };

  const selectService = useCallback(
    (service) => {
      setPreviewLoading(true);
      axios
        .get(`/services/${service.uid}`, {
          params: {
            type: props.type,
          },
        })
        .then(async (response) => {
          dispatch(SetPolicyChanges(response.data.service.count_of_changes));
          setSelectedService(response.data.service);
          await getServiceUsedList(service.uid);
          setPreviewLoading(false);
        })
        .catch((err) => {
          if (err.response?.data?.error !== 'cancelled')
            errorToast({ body: "SERVICE_OBJECTS.SELECT_ERROR", intl: intl });
        });
    },
    [dispatch, intl, props.type]
  );

  const getServiceUsedList = async (uid) => {
    let val = await whereUsed(uid);
    setUsedList(val);
  };

  const fetchServices = useCallback(() => {
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/services", {
        params: {
          page_num: pageNumber,
          search_query: searchQuery,
          type: props.type,
          sort: sort,
        },
      })
      .then((response) => {
        setServices(response.data.services);
        setLoading(false);
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        if (response.data.services.length > 0) {
          selectService(response.data.services[0]);
          setServiceCount(response.data.services_count);
        } else {
          setPreviewLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: "SERVICE_OBJECTS.LOAD_ERROR", intl: intl });
      });
  }, [
    dispatch,
    intl,
    pageNumber,
    props.type,
    searchQuery,
    selectService,
    sort,
  ]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const submitHandler = (service) => {
    setLastAdded(service.uid);
    fetchServices();
  };

  const addService = () => {
    const title = "NEW_" + props.type.toUpperCase() + "_SERVICE";
    setModalTitle(intl.formatMessage({ id: `SERVICES.${title}` }));
    setServiceType(props.type);
    setEditMode(false);
    setShowModal(true);
  };

  const editService = (selectedService) => {
    const title = "EDIT_" + props.type.toUpperCase() + "_SERVICE";
    setModalTitle(intl.formatMessage({ id: `SERVICES.${title}` }));
    setServiceType(props.type);
    setShowModal(true);
    setEditMode(true);
  };

  const { isReadOnlyUser } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
    }),
    shallowEqual
  );

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const updateService = (s) => {
    fetchServices();
  };

  const deleteService = (selectedService) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        axios
          .delete(`/services/${selectedService.uid}`, {
            data: { type: props.type },
          })
          .then((response) => {
            successToast({
              body: "GENERAL.DELETE_SUCCESS",
              intl: intl,
            });
            fetchServices();
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          });
      },
    });
  };

  const getHeader = () => {
    return (
      <>
        <span className="my-auto">
          <i
            className={`${objectDatas["service-tcp"].icon}  mr-2`}
          ></i>
          {selectedService.name}
        </span>
        <div>
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            onClick={() => editService(selectedService)}
            icon="fas fa-pencil-alt"
            tooltip="GENERAL.EDIT_SMALL"
            visible={!selectedService?.["read-only"] && !isReadOnlyUser && !selectedService.lock && selectedService.creator !== "System"}
          />
          <BeSafeButton
            variant="transparent"
            className="px-2"
            onClick={() => deleteService(selectedService)}
            icon="fas fa-trash"
            tooltip="GENERAL.DELETE_SMALL"
            visible={!selectedService?.["read-only"] && !isReadOnlyUser && !selectedService.lock && selectedService.creator !== "System"}
          />
          <BeSafeButton
            variant="transparent"
            className="pr-1"
            icon="fas fa-lock"
            tooltip="GENERAL.LOCKED"
            visible={selectedService.lock}
          />
        </div>
      </>
    )
  }

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        createFunction={addService}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={serviceCount}
          items={services}
          selectedItem={selectedService}
          selectItem={selectService}
          itemType={`service-${props.type}`}
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          editFunction={editService}
          deleteFunction={deleteService}
          lastAdded={lastAdded}
        />
      </ObjectExplorerItemsPane>
      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={serviceCount}
        usedList={usedList}
        header={getHeader()}
      >
        {Object.keys(serviceDatas).map((item) => {
          return (
            selectedService[item] !== null &&
            selectedService[item] !== "" &&
            selectedService[item] !== undefined && (
              <div className="mb-3" key={item}>
                <span
                  className={
                    item === "comments"
                      ? "d-block font-weight-bold mr-1"
                      : "font-weight-bold mr-1"
                  }
                >
                  <FormattedMessage
                    id={"SERVICES." + serviceDatas[item]}
                  />
                  :
                </span>
                <span>{selectedService[item]}</span>
              </div>
            )
          );
        })}
      </ObjectExplorerDetails>
      {props.type === "icmp" && (
        <ServiceIcmpFormModal
          showModal={showModal}
          onHide={() => setShowModal(false)}
          isEditMode={editMode}
          modalTitle={modalTitle}
          serviceType={serviceType}
          service={selectedService}
          updateServiceList={submitHandler}
          updateService={(service) => updateService(service)}
          loading={previewLoading}
        />
      )}
      {props.type === "tcp" && (
        <ServiceFormModal
          showModal={showModal}
          onHide={() => setShowModal(false)}
          isEditMode={editMode}
          modalTitle={modalTitle}
          serviceType={serviceType}
          service={selectedService}
          updateServiceList={submitHandler}
          updateService={(service) => updateService(service)}
          loading={previewLoading}
        />
      )}
      {props.type === "udp" && (
        <ServiceFormModal
          showModal={showModal}
          onHide={() => setShowModal(false)}
          isEditMode={editMode}
          modalTitle={modalTitle}
          serviceType={serviceType}
          service={selectedService}
          updateServiceList={submitHandler}
          updateService={(service) => updateService(service)}
          loading={previewLoading}
        />
      )}
      {props.type === "other" && (
        <ServiceOtherFormModal
          showModal={showModal}
          onHide={() => setShowModal(false)}
          isEditMode={editMode}
          modalTitle={modalTitle}
          serviceType={serviceType}
          service={selectedService}
          updateServiceList={submitHandler}
          updateService={(service) => updateService(service)}
          loading={previewLoading}
        />
      )}
    </ObjectExplorerTab>
  );
};

ServiceObjects.defaultProps = {
  type: "tcp",
};

export default ServiceObjects;
