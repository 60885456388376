import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { ListGroup, FormControl, Form, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import NetworkGroupFormModal from "./NetworkGroupFormModal";
import {
  isUserReadOnly,
  whereUsed,
  isStartingWithAlphabet,
  objectDatas,
  getAccountType,
  accountTypes
} from "utils/utils";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ConfirmationPopup } from "components/common/widgets/ConfirmDialog";
import { successToast, errorToast } from "utils/ToastHelper";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";
import { Netmask } from "netmask";
import { WaitTime } from "utils/SearchWaitTime";
import ObjectExplorerItemList from "components/object_explorer/common/ObjectExplorerItemList";
import ObjectExplorerItemsPane from "components/object_explorer/common/ObjectExplorerItemsPane";
import ObjectExplorerDetails from "components/object_explorer/common/ObjectExplorerDetails";
import BeSafeButton from "components/common/BeSafeButton";
import BeSafeContainer from "components/common/BeSafeContainer";
import ObjectExplorerTab from "components/object_explorer/common/ObjectExplorerTab";
import { Row } from "react-bootstrap";

const NetworkGroups = () => {
  const [networks, setNetworks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [previewLoading, setPreviewLoading] = useState(true);

  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const [selectedNetworkList, setSelectedNetworkList] = useState({});
  const [networkList, setNetworkList] = useState([]);
  const [usedList, setUsedList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editGroupName, setEditGroupName] = useState(false);
  const [newGroupName, setGroupName] = useState("");
  const [newNetworkName, setNetworkName] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const groupNameFocus = useRef(null);
  const networkNameFocus = useRef(null);
  const [networkGroupCount, setNetworkGroupCount] = useState(0);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [tempSearchQuery, setTempSearchQuery] = useState("");
  const [sort, setSort] = useState("ASC");
  const [lastAdded, setLastAdded] = useState();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { isReadOnlyUser,accountType } = useSelector(
    ({ auth }) => ({
      isReadOnlyUser: isUserReadOnly(auth),
      accountType: getAccountType(auth),
    }),
    shallowEqual
  );

  const selectNetwork = useCallback(
    (network) => {
      setGroupName(network.name);
      setPreviewLoading(true);
      axios
        .get(`/network_groups/${network.uid}`)
        .then(async (response) => {
          dispatch(SetPolicyChanges(response.data.group.count_of_changes));
          setSelectedNetwork(response.data.group);
          setNetworkList(response.data.group.members);
          await getNetworkUsedList(network.uid);
          setPreviewLoading(false);
        })
        .catch((err) => {
          if (err.response?.data?.error !== 'cancelled')
            errorToast({ body: (accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) ? "NETWORK_GROUPS.CLIENT_SELECT_ERROR" : "NETWORK_GROUPS.SELECT_ERROR", intl: intl });
        });
    },
    [dispatch, intl, accountType]
  );

  const fetchNetworks = useCallback(() => {
    const filter = searchQuery ? `"${searchQuery}"` : searchQuery;
    setLoading(true);
    setPreviewLoading(true);
    axios
      .get("/network_groups", {
        params: { page_num: pageNumber, search_query: filter, sort: sort },
      })
      .then((response) => {
        dispatch(SetPolicyChanges(response.data.count_of_changes));
        setNetworks(response.data.networks);
        setLoading(false);
        if (response.data.networks.length) {
          selectNetwork(response.data.networks[0]);
          setNetworkGroupCount(response.data.networks_count);
        } else {
          setPreviewLoading(false);
        }
      })
      .catch((error) => {
        if (error.response?.data?.error !== 'cancelled')
          errorToast({ body: (accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) ? "NETWORK_GROUPS.CLIENT_LOAD_ERROR" : "NETWORK_GROUPS.LOAD_ERROR", intl: intl });
      });
  }, [dispatch, intl, pageNumber, searchQuery, selectNetwork, sort, accountType]);

  useEffect(() => {
    fetchNetworks();
  }, [fetchNetworks]);

  const editNetworkGroup = (selectedNetwork) => {
    setSelectedNetwork(selectedNetwork);
    setEditMode(true);
    setShowModal(true);
  };

  useEffect(() => {
    if (editGroupName && groupNameFocus.current) {
      groupNameFocus.current.focus();
    }
  }, [editGroupName]);

  useEffect(() => {
    if (selectedNetworkList?.uid && networkNameFocus.current) {
      networkNameFocus.current.focus();
    }
  }, [selectedNetworkList]);

  const sortData = (value) => {
    if (value === "ASC") {
      setSort("DESC");
    } else {
      setSort("ASC");
    }
  };

  const deleteNetworkGroup = (selectedNetwork) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "GENERAL.OK",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: () => {
        axios
          .delete(`/network_groups/${selectedNetwork.uid}`)
          .then((response) => {
            let vals = networks.filter((ng) => ng.uid !== selectedNetwork.uid);
            dispatch(SetPolicyChanges(response.data.count_of_changes));
            setNetworks(vals);
            if (vals.length) selectNetwork(vals[0]);
            successToast({
              body: (accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code) ? "NETWORK_GROUPS.CLIENT_DELETE_SUCCESS" : "NETWORK_GROUPS.GROUP_DELETE_SUCCESS",
              intl: intl,
            });
          })
          .catch((err) => {
            errorToast({ body: err.response.data?.error, intl: intl });
          });
      },
    });
  };

  const updateNetworkList = (network, index, params) => {
    setSubmitting(true);
    axios
      .patch(`/networks/${network.uid}`, {
        network: params,
      })
      .then((response) => {
        networkList[index] = response.data.network;
        dispatch(SetPolicyChanges(response.data.network.count_of_changes));
        setSelectedNetworkList({});
        setSubmitting(false);
        successToast({
          body: "NETWORK_GROUPS.NETWORK_UPDATE_SUCCESS",
          intl: intl,
        });
      })
      .catch((err) => {
        setSubmitting(false);
        errorToast({ body: err.response.data?.error, intl: intl });
      });
  };

  const getNetworkIpDetails = (network, index) => {
    try {
      var block = new Netmask(newNetworkName);
      let params = {
        uid: network.uid,
        "new-name": newNetworkName,
        subnet: block.base,
        "mask-length": block.bitmask,
      };
      updateNetworkList(network, index, params);
    } catch (error) {
      errorToast({ body: "NETWORK_GROUPS.INVALID_IP_MASK", intl: intl });
    }
  };

  const getNetworkUsedList = async (uid) => {
    let val = await whereUsed(uid);
    setUsedList(val);
  };

  const submitHandler = (networkGroup) => {
    setLastAdded(networkGroup.uid);
    fetchNetworks();
  };

  const hideModal = () => {
    setShowModal(false);
    setEditMode(false);
  };

  const searchData = (event) => {
    var searchText = event.target.value;
    setTempSearchQuery(searchText);
    if (searchTimeout) clearTimeout(searchTimeout);
    let timeout = setTimeout(() => {
      setLoading(true);
      setPageNumber(1);
      setSearchQuery(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const editGroup = async (params) => {
    setSubmitting(true);
    return axios
      .patch(`/network_groups/${selectedNetwork.uid}`, params)
      .then((response) => {
        setSubmitting(false);
        response.data.group["read-only"] = false;
        dispatch(SetPolicyChanges(response.data.group.count_of_changes));
        return response.data.group;
      })
      .catch((err) => {
        errorToast({ body: err.response.data?.error, intl: intl });
        setGroupName(selectedNetwork.name);
        setSubmitting(false);
        return null;
      });
  };

  const updateGroupName = async () => {
    if (isStartingWithAlphabet(newGroupName)) {
      var params = {
        network_group: {
          uid: selectedNetwork.uid,
          "new-name": newGroupName,
        },
      };
      editGroup(params).then((val) => {
        setEditGroupName(false);
        if (!!val) {
          successToast({
            body: "NETWORK_GROUPS.GROUP_UPDATE_SUCCESS",
            intl: intl,
          });
          fetchNetworks();
        }
      });
    } else {
      errorToast({ body: "GENERAL.INVALID_OBJECT_NAME", intl: intl });
    }
  };

  const deleteNetwork = (networkId, index) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "NETWORK_GROUPS.DETACH",
      }),
      okTitle: intl.formatMessage({
        id: "NETWORK_GROUPS.REMOVE_NETWORK_FROM_GROUP",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      confirmLabel: intl.formatMessage({
        id: "GENERAL.DELETE_SMALL",
      }),
      confirmTitle: intl.formatMessage({
        id: "GENERAL.DELETE_COMPLETELY",
      }),
      confirmAction: () => {
        let values = [...networkList];
        values.splice(index, 1);
        let params = {
          network_group: {
            uid: selectedNetwork.uid,
            members: values.map((vl) => vl.uid),
          },
        };
        editGroup(params).then((val) => {
          if (!!val) {
            setNetworkList(values);
            successToast({
              body: "NETWORK_GROUPS.NETWORK_DELETED_SUCCESS",
              intl: intl,
            });
            axios
              .delete(`/networks/${networkId}`)
              .then((response) => {
                dispatch(SetPolicyChanges(response.data.count_of_changes));
                successToast({
                  body: "GENERAL.DELETE_SUCCESS",
                  intl: intl,
                });
              })
              .catch((err) => {
                if (err.response.data.already_in_used) {
                  errorToast({
                    body: "NETWORK_GROUPS.ALREADY_IN_USE",
                    intl: intl,
                  });
                } else {
                  errorToast({
                    body: err.response.data?.error,
                    intl: intl,
                  });
                }
              });
          }
        });
      },
      okAction: async () => {
        let values = [...networkList];
        values.splice(index, 1);
        let params = {
          network_group: {
            uid: selectedNetwork.uid,
            members: values.map((vl) => vl.uid),
          },
        };
        editGroup(params).then((val) => {
          if (!!val) {
            setNetworkList(values);
            successToast({
              body: "NETWORK_GROUPS.NETWORK_DELETED_SUCCESS",
              intl: intl,
            });
          }
        });
      },
    });
  };

  const deleteGroup = (index) => {
    ConfirmationPopup({
      title: intl.formatMessage({
        id: "GENERAL.WARNING",
      }),
      description: intl.formatMessage({
        id: "GENERAL.CONFIRM_DELETE",
      }),
      okLabel: intl.formatMessage({
        id: "NETWORK_GROUPS.DETACH",
      }),
      okTitle: intl.formatMessage({
        id: "NETWORK_GROUPS.DETACH_GROUP",
      }),
      cancelLabel: intl.formatMessage({
        id: "GENERAL.CANCEL",
      }),
      okAction: async () => {
        let values = [...networkList];
        values.splice(index, 1);
        let params = {
          network_group: {
            uid: selectedNetwork.uid,
            members: values.map((vl) => vl.uid),
          },
        };
        editGroup(params).then((val) => {
          if (!!val) {
            setNetworkList(values);
            successToast({
              body: "NETWORK_GROUPS.GROUP_DETACHED_SUCCESS",
              intl: intl,
            });
          }
        });
      },
    });
  };

  const handlePageChange = (pageNum) => {
    setLoading(true);
    setPageNumber(pageNum);
  };

  const openNewForm = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const getHeader = () => {
    return (
      <>
        <BeSafeContainer visible={editGroupName}>
          <FormControl
            type="text"
            value={newGroupName}
            className="w-75 rounded-0 border-top-0 border-right-0 border-left-0 border-primary pl-0"
            onChange={(e) => setGroupName(e.target.value)}
            ref={groupNameFocus}
          />
          <div>
            <BeSafeButton
              variant="transparent"
              className="pr-1"
              onClick={updateGroupName}
              icon="fas fa-check"
              tooltip="GENERAL.SAVE_SMALL"
              disabled={isSubmitting}
            />
            <BeSafeButton
              variant="transparent"
              className="px-2"
              onClick={() => {
                setGroupName(selectedNetwork.name);
                setEditGroupName(false);
              }}
              icon="fas fa-times"
              tooltip="GENERAL.CANCEL"
              disabled={isSubmitting}
            />
          </div>
        </BeSafeContainer>
        <BeSafeContainer visible={!editGroupName}>
          <span className="my-auto">
            <i
              className={`${objectDatas["group"].icon} mr-2`}
            ></i>
            {selectedNetwork.name}
          </span>
          <div>
            <BeSafeButton
              variant="transparent"
              className="pr-1"
              onClick={() => setEditGroupName(true)}
              icon="fas fa-pencil-alt"
              tooltip="GENERAL.EDIT_SMALL"
              disabled={isSubmitting}
              visible={!selectedNetwork["read-only"] && !isReadOnlyUser && !selectedNetwork.lock}
            />
            <BeSafeButton
              variant="transparent"
              className="px-2"
              onClick={() => deleteNetworkGroup(selectedNetwork)}
              icon="fas fa-trash"
              tooltip="GENERAL.DELETE_SMALL"
              disabled={isSubmitting}
              visible={!selectedNetwork["read-only"] && !isReadOnlyUser && !selectedNetwork.lock}
            />
            <BeSafeButton
              variant="transparent"
              className="pr-1"
              icon="fas fa-lock"
              tooltip="GENERAL.LOCKED"
              disabled={isSubmitting}
              visible={selectedNetwork.lock}
            />
          </div>
        </BeSafeContainer>
      </>
    )
  }

  const getNetworkGroupType = (item) => {
    const groupType = item["comments"] ? item["comments"].split(";")[0] : item["comments"];
    if (groupType === "LanGroup") {
      return "LAN";
    }
    if (groupType === "NoLanGroup") {
      return "No-LAN";
    }
    return groupType;
  };

  return (
    <ObjectExplorerTab>
      <ObjectExplorerItemsPane
        searchData={searchData}
        createFunction={openNewForm}
        sortFunction={sortData}
        searchQuery={tempSearchQuery}
        sort={sort}
      >
        <ObjectExplorerItemList
          loading={loading}
          itemsCount={networkGroupCount}
          items={networks}
          selectedItem={selectedNetwork}
          selectItem={selectNetwork}
          itemType="group"
          handlePageChange={handlePageChange}
          pageNumber={pageNumber}
          buttonsDisable={isSubmitting}
          editFunction={editNetworkGroup}
          deleteFunction={deleteNetworkGroup}
          lastAdded={lastAdded}
        />
      </ObjectExplorerItemsPane>
      <ObjectExplorerDetails
        loading={previewLoading}
        itemsCount={networkGroupCount}
        usedList={usedList}
        header={getHeader()}
      >
        <div className="d-flex py-2">
          <span className="font-weight-bold">
            <FormattedMessage id="NETWORK_GROUPS.TYPE" />
          </span>
          <span>{`: ${getNetworkGroupType(selectedNetwork)}`}</span>
        </div>
        <div className="d-flex justify-content-between font-weight-bold">
          <FormattedMessage id="NETWORK_GROUPS.NETWORK_LIST" />
          <BeSafeButton
            variant="transparent"
            className="py-0"
            onClick={() => editNetworkGroup(selectedNetwork)}
            icon="fas fa-plus"
            tooltip="OBJECT_MODAL.ADD"
            visible={!selectedNetwork["read-only"] && !isReadOnlyUser && !selectedNetwork.lock}
          />
        </div>
        <ListGroup>
          {networkList.map((nl, index) => (
            <ListGroup.Item
              key={nl.uid}
              className="d-flex justify-content-between py-0"
            >
              <BeSafeContainer visible={nl.uid === selectedNetworkList?.uid}>
                <Form.Control
                  type="text"
                  value={newNetworkName}
                  className="w-75 rounded-0 border-top-0 border-right-0 border-left-0 border-primary pl-0"
                  onChange={(e) => setNetworkName(e.target.value)}
                  ref={networkNameFocus}
                />
                <div className="my-auto">
                  <BeSafeButton
                    variant="transparent"
                    className="p-0 mr-2"
                    onClick={() => getNetworkIpDetails(nl, index)}
                    icon="fas fa-check"
                    tooltip="GENERAL.SAVE_SMALL"
                    disabled={isSubmitting}
                  />
                  <BeSafeButton
                    variant="transparent"
                    className="p-0"
                    onClick={() => {
                      setSelectedNetworkList({});
                      setNetworkName(nl.name);
                    }}
                    icon="fas fa-times"
                    tooltip="GENERAL.CANCEL"
                    disabled={isSubmitting}
                  />
                </div>
              </BeSafeContainer>
              <BeSafeContainer visible={nl.uid !== selectedNetworkList?.uid}>
                <Row className="w-100">
                  {accountType !== accountTypes.carrier.code && (
                    <Col sm={10}>
                      <i className={`${objectDatas[nl.type].icon} mr-2 align-middle`}></i>
                      <span className="w-75 align-middle">{nl.name}</span>
                    </Col>
                  )}
                  {((accountType === accountTypes.carrier.code || accountType === accountTypes.carrier_cgnat.code)) && (
                      <>
                        { (nl.type === "network") && (
                          <>
                            <Col sm={1}>
                              <i className={`${objectDatas[nl.type].icon} mr-2 align-middle`}></i>
                            </Col>
                            <Col sm={4}>
                              <span className="align-middle"><b>{intl.formatMessage({id: "NETWORK_GROUPS.NATED_IPV4"})}</b>: {nl.name}</span>
                            </Col>
                            <Col sm={5}>
                              <span className="align-middle"><b>{intl.formatMessage({id: "NETWORK_GROUPS.ORIGINAL_IPV4"})}</b>: {nl.comments}</span>
                            </Col>
                          </>
                        )}
                        { (nl.type === "group") && (
                          <>
                            <Col sm={1}>
                              <i className={`${objectDatas[nl.type].icon} mr-2 align-middle`}></i>
                            </Col>
                            <Col sm={9}>
                              <span className="align-middle">{nl.name}</span>
                            </Col>
                          </>
                        )}
                      </>
                  )}
                  <Col sm={2} className="text-right">
                    <BeSafeButton
                      variant="transparent"
                      className="p-0 mr-2"
                      onClick={() => {
                        setSelectedNetworkList(nl);
                        setNetworkName(nl.name);
                      }}
                      icon="fas fa-pencil-alt"
                      tooltip="GENERAL.EDIT_SMALL"
                      disabled={isSubmitting}
                      visible={!selectedNetwork["read-only"] && !isReadOnlyUser && !selectedNetwork.lock && nl.type !== "group" 
                        && (accountType !== accountTypes.carrier.code && accountType !== accountTypes.carrier_cgnat.code)}
                    />
                    <BeSafeButton
                      variant="transparent"
                      className="p-0"
                      onClick={() => { nl.type !== "group" ? deleteNetwork(nl.uid, index) : deleteGroup(index)}}
                      icon="fas fa-trash"
                      tooltip="GENERAL.DELETE_SMALL"
                      disabled={isSubmitting}
                      visible={!selectedNetwork["read-only"] && !isReadOnlyUser && !selectedNetwork.lock}
                    />
                  </Col>
                </Row>
              </BeSafeContainer>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </ObjectExplorerDetails>
      <NetworkGroupFormModal
        showModal={showModal}
        onHide={hideModal}
        networkGroup={editMode ? selectedNetwork : {}}
        networkList={editMode ? networkList : []}
        editMode={editMode}
        submitHandler={submitHandler}
        loading={previewLoading}
      />
    </ObjectExplorerTab>
  );
};

export default NetworkGroups;
