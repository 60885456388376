import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { logout } from "../../app/crud/auth.crud";
import Loader from "../../shared/Loader";
import * as auth from "../../store/ducks/auth.duck";
import { SetPolicyChanges } from "store/ducks/policyChange.duck";

const Logout = (props) => {
  useEffect(() => {
    logout().then(() => {
      props.logout();
      props.SetPolicyChanges(0);
    });
  });
  const { hasAuthToken } = props;
  return hasAuthToken ? <Loader /> : <Redirect to="/login" />;
};

const mapDispatchToProps = { SetPolicyChanges, ...auth.actions };

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  mapDispatchToProps
)(Logout);
