import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "assets/scss/logs.scss";

const DomainHelpModal = (props) => {
  const onHide = () => {
    props.onHide();
  };

  return (
    <Modal
      show={props.showModal}
      onHide={onHide}
      centered
      size="lg"
      dialogClassName="modal-shadow-lg"
    >
      <Modal.Header closeButton className="bg-light-grey">
        <Modal.Title>
          <img
            height="30"
            width="30"
            src={`${process.env.PUBLIC_URL}/images/besafe_logo.png`}
            alt="besafe-corporate"
          />
          <span className="d-none d-md-inline-block ml-1">
            <FormattedMessage id="DOMAIN.HELP_TITLE" />
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="vh-75 overflow-auto">
        <div>
          <p>This object is intended to identify domains and subdomains for non web traffic (HTTP and HTTPS).</p>
          <p>If you need to configure a domain or subdomain for web traffic, please create a Custom Application.</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DomainHelpModal;
