const BeSafeContainer = (props) => {

  return (
    props.visible ?
    props.children
    : null
  );
};

BeSafeContainer.defaultProps = {
  visible: true
};
export default BeSafeContainer;
