import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetFirstTimeVisit: "[Change First Time Visit] Action",
};

const initialAuthState = {
  user: null,
  authToken: null,
  impersonated: false
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ['user', 'authToken'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { id } = action.payload.user;

        return { authToken: id, user: action.payload.user, impersonated: action.payload.impersonated };
      }
      case actionTypes.Logout: {
        return initialAuthState;
      }
      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        if(!Boolean(state.authToken)){
          state.authToken = user.remember_token
        }
        return { ...state, user };
      }
      case actionTypes.SetFirstTimeVisit: {
        return { ...state, user: { ...state.user, first_time_visited: action.payload.firstTimeVisited } };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (user,impersonated = false) => ({ type: actionTypes.Login, payload: { user: user, impersonated: impersonated } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setFirstTimeVisit: firstTimeVisited => ({ type: actionTypes.SetFirstTimeVisit, payload: { firstTimeVisited } }),
};
