import React from "react";
import { Button } from "react-bootstrap";
import { renderTooltip } from "utils/utils";
import { FormattedMessage } from "react-intl";

const BeSafeButton = (props) => {

  return (
    props.visible ?

    renderTooltip(
      <Button
        variant={ props.variant }
        className={ props.className }
        onClick={ props.onClick }
        disabled={ props.disabled }
      >
        <i className={ props.icon } />
      </Button>,
      props.tooltip ? <FormattedMessage id={ props.tooltip } /> : ""
    )
    : null
  );
};

BeSafeButton.defaultProps = {
  visible: true
};
export default BeSafeButton;
